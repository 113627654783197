import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="17"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.6854 17.0971C18.5721 15.3191 19.75 12.7971 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 12.7971 1.42785 15.3191 3.31463 17.0971C5.06012 18.7419 7.41234 19.75 10 19.75C12.5877 19.75 14.9399 18.7419 16.6854 17.0971ZM4.14512 15.8123C5.51961 14.0978 7.63161 13 10 13C12.3684 13 14.4804 14.0978 15.8549 15.8123C14.3603 17.3178 12.289 18.25 10 18.25C7.711 18.25 5.63973 17.3178 4.14512 15.8123ZM13.75 7C13.75 9.07107 12.0711 10.75 10 10.75C7.92893 10.75 6.25 9.07107 6.25 7C6.25 4.92893 7.92893 3.25 10 3.25C12.0711 3.25 13.75 4.92893 13.75 7Z"
      fill="#475467"
    />
  </svg>
);

const Icon_user_filled = (props) => <Icon component={icon} {...props} />;

export default Icon_user_filled;
