import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const UserService = {
    getMe: async function (params, cancel = false) {
      const response = await api.request({
        url: `/users/me`,
        method: "GET",
        params: params,
        signal: cancel ? cancelApiObject[this.getMe.name].handleRequestCancellation().signal : undefined,
      });

      return response.data;
    },

    get: async function (id, cancel = false) {
        const response = await api.request({
          url: `/Keycloak-users/${id}`,
          method: "GET",
          // retrieving the signal value by using the property name
          signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        });
    
        return response.data;
    },

    search: async function (requestObject, cancel = false) {
      let params = "?Keywords=" +
          requestObject.keywords +
          "&Pagination.Page=" +
          requestObject.page +
          "&Pagination.PageSize=" +
          requestObject.pageSize +
          "&Pagination.SortBy=" +
          requestObject.sortBy +
          "&Pagination.SortDestination=" +
          requestObject.sortDirection
          if(requestObject.enabled !== undefined){
              params += "&enabled=" +
               requestObject.enabled 
          }
          // requestObject.enabled !== null ? 
          // "&enabled=" +
          // requestObject.enabled  : ""+
        const response = await api.request({
          url: `/Keycloak-users`+
          params,
          method: "GET",
          // params: params,
          signal: cancel ? cancelApiObject[this.search.name].handleRequestCancellation().signal : undefined,
        });
    
        return response.data;
    },

    create: async function (data, cancel = false) {
      const response = await api.request({
          url: `/Keycloak-users`,
          method: "POST",
          data: data,
          signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
        return response.data;
    },

    update: async function (id, data, cancel = false) {
      const response = await api.request({
          url: `/Keycloak-users/${id}`,
          method: "PUT",
          data, data,
          signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
        });
        return response.data;
    },

    delete: async function (id, cancel = false) {
      const response = await api.request({
          url: `/Keycloak-users/${id}`,
          method: "DELETE",
          signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
        });
        return response.data;
    },
}

const cancelApiObject = defineCancelApiObject(UserService);