import React, { useState, useEffect } from "react";
import { Layout, Menu, Flex, Col, Row, Avatar } from "antd";
import "./MyProfile.css";
import { UserOutlined } from "@ant-design/icons";

import { UserService } from "../../../services/UserService";

const { Content, Sider } = Layout;

const MyProfile = ({}) => {
  const [data, setData] = useState();

  useEffect(() => {
    if (!data) {
      fetchData();
    }
  }, [data]);

  const fetchData = async () => {
    try {
      const response = await UserService.getMe();
      setData(response);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="profile-body">
        <Row style={{ width: "100%", marginTop: "20px" }}>
          <Col span={4}>
            <div className="profile-sider">
              <Flex justify="center">
                <Avatar size={64} icon={<UserOutlined />} />
              </Flex>
              <Flex justify="center">
                <h3>{data?.firstName}</h3>
              </Flex>
              <Menu mode="inline">
                <Menu.Item>
                  <span>Menu 1</span>
                </Menu.Item>
                <Menu.Item>
                  <span>Menu 2</span>
                </Menu.Item>
                <Menu.Item>
                  <span>Menu 3</span>
                </Menu.Item>
              </Menu>
            </div>
          </Col>
          <Col span={20}>
            <div className="profile-content">
              <h1>My Profile</h1>
              <h3>firstName</h3>
              <p style={{ marginLeft: "20px" }}>{data?.firstName}</p>
              <h3>LastName</h3>
              <p style={{ marginLeft: "20px" }}>{data?.lastName}</p>
              <h3>Email</h3>
              <p style={{ marginLeft: "20px" }}>{data?.email}</p>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default MyProfile;
