import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Layout, Space } from "antd";
import "./Nav.css";
import CustomButton from "../Button/CustomButton";
import DiscardDialog from "../../components/Dialog/DiscardDialog";

const NavOcelot = ({ width }) => {
  const location = useLocation();
  const selectedKey = location.pathname;
  const { pathname } = location;
  const [isModalDiscardOpen, setIsModalDiscardOpen] = useState(false);
  const [targetId, setTargetId] = useState();

  useEffect(() => {
    console.log("- Changed Without key : " + pathname);
  }, [location]);

  function handleClick(e, menu) {
    // This will set e.defaultPrevented to true
    // (for all synthetic events firing after this one)
    let isChangeed = sessionStorage.getItem("isChanged");
    if (isChangeed !== null) {
      setIsModalDiscardOpen(true);
      setTargetId(menu);
      e.preventDefault();
      // e.dispatchEvent(e);
    }
  }

  return (
    <>
      <DiscardDialog
        isModalOpen={isModalDiscardOpen}
        closable={false}
        title="Discard changes"
        cancelText="Cancel"
        confirmText="Confirm"
        onCancel={() => {
          setIsModalDiscardOpen(false);
        }}
        onConfirm={() => {
          sessionStorage.removeItem("isChanged");
          let element = document.getElementById(targetId);
          element.click();
          setIsModalDiscardOpen(false);
          // alert("OK");
        }}
        description="Changes will not be saved. Do you want to proceed ?"
      />

      <div className="ocelot-header" style={{ width }}>
        <Space size="large">
          <Link
            to="/api-management/global-configuration"
            id="global"
            onClick={(e) => {
              handleClick(e, "global");
            }}
          >
            <CustomButton
              text="Global Configuration"
              type={
                selectedKey === "/api-management/global-configuration"
                  ? "primary"
                  : "text"
              }
              ghost={true}
              borderRadius={8}
            />
          </Link>
          <Link
            to="/api-management/authentication-providers"
            id="authen"
            onClick={(e) => {
              handleClick(e, "authen");
            }}
          >
            <CustomButton
              text="Authentication Providers"
              type={
                selectedKey === "/api-management/authentication-providers"
                  ? "primary"
                  : "text"
              }
              ghost={true}
              borderRadius={8}
            />
          </Link>
          <Link
            to="/api-management/routes"
            id="routes"
            onClick={(e) => {
              handleClick(e, "routes");
            }}
          >
            <CustomButton
              text="Routes"
              type={
                selectedKey === "/api-management/routes" ? "primary" : "text"
              }
              ghost={true}
              borderRadius={8}
            />
          </Link>
          <Link
            to="/api-management/swagger-docs"
            id="swagger"
            onClick={(e) => {
              handleClick(e, "swagger");
            }}
          >
            <CustomButton
              text="Swagger Docs"
              type={
                selectedKey === "/api-management/swagger-docs" ? "primary" : "text"
              }
              ghost={true}
              borderRadius={8}
            />
          </Link>
        </Space>
      </div>
    </>
  );
};

export default NavOcelot;
