import React from "react";
// import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { Row, Col, Pagination, Select } from "antd";
import "./CustomPagination.css";

const { Option } = Select;

const CustomPagination = ({
  current,
  pageSize,
  total,
  loading,
  onPageChange,
  onPageSizeChange,
  pageSizeOptions = [10, 25, 50, 100],
}) => {
  // const itemRender = (_, type, originalElement) => {
  //   // const { current, total, pageSize } = tableParams.pagination;
  //   const totalPages = Math.ceil(total / pageSize);

  //   if (type === "prev") {
  //     if (current === 1) {
  //       return (
  //         <a style={{ pointerEvents: "none" }}>
  //           <LeftOutlined style={{ color: "#d9d9d9" }} />
  //         </a>
  //       );
  //     }
  //     return (
  //       <a>
  //         <LeftOutlined style={{ color: "#FF7F07" }} />
  //       </a>
  //     );
  //   }
  //   if (type === "next") {
  //     if (current === totalPages) {
  //       return (
  //         <a style={{ pointerEvents: "none" }}>
  //           <RightOutlined style={{ color: "#d9d9d9" }} />
  //         </a>
  //       );
  //     }
  //     return (
  //       <a>
  //         <RightOutlined style={{ color: "#FF7F07" }} />
  //       </a>
  //     );
  //   }
  //   return originalElement;
  // };

  return (
    <Row justify="space-between" align="middle" style={{ marginTop: 16 }}>
      <Col>
        <span style={{ color: "#667085" }}>Total : {total} items</span>
      </Col>
      <Col>
        <Pagination
          style={{ pointerEvents: loading ? "none" : "auto" }}
          current={current}
          pageSize={pageSize}
          total={total}
          onChange={onPageChange}
          showSizeChanger={false}
          // itemRender={itemRender}
        />
      </Col>
      <Col>
        <Select
          value={pageSize}
          onChange={(newPageSize) => onPageSizeChange(current, newPageSize)}
          style={{ width: "110px" }}
        >
          {pageSizeOptions.map((size) => (
            <Option key={size} value={size}>
              {size} / page
            </Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
};

export default CustomPagination;
