import { api2 } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const MockService = {
    get: async function (id, cancel = false) {
        const response = await api2.request({
          url: `/roles`,
          method: "GET",
          // retrieving the signal value by using the property name
          signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        });
    
        return response.data;
    },

}

const cancelApiObject = defineCancelApiObject(MockService);