import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import {
  Table,
  Dropdown,
  Space,
  Button,
  Form,
  Input,
  Flex,
  Drawer,
  Row,
  Col,
  Select,
  message,
  InputNumber,
  Modal,
  Switch,
  notification,
  Tooltip,
} from "antd";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
const RoleDetail = () => {
    const navigate = useNavigate();
    const { Option } = Select;
    const history = useLocation();
    let permissionId = history.pathname.replace("/permission-detail/", "");
    const [form] = Form.useForm();
    const columns = [
        {
          title: "Menu",
          dataIndex: "pageName",
        },
        {
          title: "Visibilities",
          dataIndex: "isActivated",
          render: (e, record) => (
            <>
             <Tooltip placement="bottom" title={e ? "Active" : "Inactive"}>
              <Switch
                style={{ backgroundColor: e === true ? "#1890FF" : "#d9d9d9" }}
                // checkedChildren="Open"
                // unCheckedChildren="Close"
                // checked={e === "0" ? true : false}
                checked={record.isActivated}
                onChange={(value) => {
                //   handleMarkComplete(record.id);
                }}
              />
              </Tooltip>
            </>
          ),
        },
      ];

      const testData = [
        { pageName: "Survey Form", isActivated:true },
        { pageName: "Survey Form Transaction" , isActivated:true},
        { pageName: "IVR Transaction" , isActivated:true},
      ]

      return (
        <>
          <div
            className="mt-5 mb-3"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link to="/role-list" style={{ textDecoration: "none" }}>
              <label
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  marginBottom: "20px",
                  display: "block",
                  color: "#000000",
                }}
                onMouseEnter={(e) => (e.target.style.color = "#000000")}
                onMouseLeave={(e) => (e.target.style.color = "#000000")}
              >
                <ArrowLeftOutlined style={{ marginRight: "8px" }} /> RoleDetail
              </label>
            </Link>
            <Flex justify="flex-end" style={{ marginTop: 0 }}>
              <Button
                // className="mt-2 mr-3 flex items-center px-12 border-0"
                //loading={loadingExcel}
                // icon={<FileExcelOutlined />}
                style={{ marginRight:4}}
                size="large"
                className="mr-4"
                type="default"
                shape="default"
                onClick={() => {
                  navigate("/role-list");
                }}
              >
                Cancel
              </Button>

              <Button
                size="large"
                type="primary"
                shape="default"
                // onClick={save}
              >
                Save
              </Button>
            </Flex>
          </div>
          <div className="bg-white rounded-lg p-5 mb-3 mt-5 pt-5">

            <div className="w-full justify-between items-center">
              <Row>
                <Col sm={14} md={14} lg={14}>
                  <div
                    style={{
                      marginBottom: 16,
                      fontSize: 12,
                      color: "#0C0C0C",
                    }}
                  >
                    <label style={{ fontSize: 20, fontWeight: 600 }}>
                      {/* Permission - {permissionDetail && permissionDetail.name} */}
                    </label>
                  </div>
                  <div>
                    {/* {testV1.map((d) => (
                <div >
                  {d.task}000000{d.id}
                 
                </div>
              ))} */}
                  </div>
                </Col>
              </Row>
            </div>

            <Form form={form} layout="vertical">
              <hr />
              <div className="contact ml-3">
                <Row align="middle" gutter={24}>
                  <Table
                    style={{
                      margin: "8px 0",
                    }}
                    columns={columns}
                    //rowKey={(record) => record.id}
                    dataSource={testData}
                    pagination={false}
                    //loading={loading}
                    // onChange={handleTableChange}
                    scroll={{
                      x: 500,
                    }}
                  />
                </Row>
              </div>
            </Form>
          </div>
        </>
      );
}

export default RoleDetail;