import Icon from "@ant-design/icons";

const icon = () => (
  

<svg width="727" height="552" viewBox="0 0 727 552" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect y="0.589844" width="726.377" height="550.735" rx="30" fill="#F2F1FA"/>
<path d="M363.872 329.991C345.854 329.991 327.836 329.991 309.818 329.991C308.938 329.991 308.046 329.991 307.162 329.9C302.651 329.481 300.838 326.453 302.761 322.358C304.431 318.824 306.252 315.38 308.02 311.883C316.366 295.417 324.712 278.955 333.059 262.494C333.317 261.991 333.586 261.489 333.886 261.009C335.988 257.651 339.402 257.179 342.092 260.075C343.989 262.101 345.593 264.377 347.286 266.573C355.885 277.771 364.484 288.974 373.084 300.182C376.839 305.059 381.57 305.324 385.982 300.938C388.172 298.761 390.311 296.526 392.527 294.368C396.522 290.474 401.055 290.708 404.318 295.248C410.866 304.359 417.251 313.591 423.666 322.793C424.168 323.515 424.552 324.311 424.805 325.152C425.427 327.265 424.547 328.967 422.396 329.53C421.047 329.852 419.662 330 418.275 329.972C400.151 330.003 382.017 330.009 363.872 329.991Z" fill="#D4D2E3"/>
<path d="M404.28 239.77C404.275 243.284 403.224 246.718 401.258 249.636C399.293 252.554 396.502 254.825 393.24 256.161C389.978 257.496 386.391 257.837 382.935 257.139C379.478 256.441 376.307 254.736 373.825 252.24C371.342 249.744 369.658 246.569 368.988 243.119C368.318 239.669 368.691 236.098 370.06 232.859C371.429 229.62 373.732 226.859 376.677 224.926C379.623 222.993 383.078 221.975 386.605 222.001C391.301 222.061 395.785 223.957 399.092 227.281C402.398 230.604 404.261 235.09 404.28 239.77Z" fill="#D4D2E3"/>
</svg>



);

const Icon_Big_Preview = (props) => <Icon component={icon} {...props} />;

export default Icon_Big_Preview;

