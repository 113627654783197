import React, { useEffect, useState } from "react";
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import "./Layout.css";
import { Layout, theme } from "antd";
import Nav from "../../../components/Nav/Nav";
import Footer from "../../../components/Footer/Footer";

const { Content } = Layout;

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = location.pathname;

  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const CheckPath = (currentPath) => {
    const pathsToHideFooter = [
      "/api-management/global-configuration",
      "/api-management/authentication-providers",
      "/api-management/routes",
      "/api-management/swagger-docs"
    ];

    return pathsToHideFooter.includes(currentPath);
  };

  return (
    <>
      <Layout className="layout">
        <Nav />
        <Content className="content">
          <div>
            <Outlet />
          </div>
        </Content>
        {!CheckPath(currentPath) && <Footer />}
      </Layout>
    </>
  );
};
export default App;
