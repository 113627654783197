import React, { useEffect, useState } from "react";
import "./Home.css";
// import { UserService } from "../../services/UserService";
import { LANGUAGES } from "../../../constants/index.ts";
import "../../../i18n/i18n.ts"
import { useTranslation } from "react-i18next";
import { DatePicker, Flex, Space, Button, Card, List  } from "antd";
// import thTh from "antd/locale/th_TH";
// import dayjs from "dayjs";
import "../../../components/Card/CustomCard.css";
import IconBigPreview from "../../../assets/icon/icon_big_preview.js";
import CustomButton from "../../../components/Button/CustomButton";
import CustomCard from "../../../components/Card/CustomCard";
import { useNavigate } from "react-router-dom";

import "dayjs/locale/th";

const Home = () => {
  const { i18n, t } = useTranslation();
  const navigate = useNavigate();
  const data = [
    {
      title: 'Title 1',
    },
    {
      title: 'Title 2',
    },
    {
      title: 'Title 3',
    },
    {
      title: 'Title 4',
    },
    {
      title: 'Title 5',
    },
    {
      title: 'Title 6',
    },
    {
      title: 'Title 7',
    },
    {
      title: 'Title 8',
    },
  ];
  // const [data, setData] = useState();
  // const [tableParams, setTableParams] = useState({
  //   pagination: {
  //     current: 1,
  //     pageSize: 10,
  //   },
  // });

  // useEffect(() => {
  //   // fetchData();
  // }, []);

  // const getParams = (params) => ({
  //   // Keywords: '',
  // });

  // const fetchData = async () => {
  //   try {
  //     let params = getParams(tableParams);
  //     let response = await UserService.search(params);
  //     setData(response);

  //     console.log("response: ", response);
  //   } catch (error) {
  //     console.error("error", error);
  //   }
  // };

  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
    localStorage.setItem("language", lang_code);
    // dayjs.locale(i18n.language);
    // console.log("Change language :"+dayjs.locale())
    window.location.reload();
  };

  return (
    <>
      <div className="home-body">
        <div className="home-container">
          <Flex vertical={true} align="center">
            <h1
              className="text-h1"
              style={{ marginTop: 100, color: "#5d5a88" }}
            >
              Lorem ipsum dolor sit amet consectetur{" "}
            </h1>
            <p
              style={{
                color: "#5d5a88",
                fontSize: 18,
                marginLeft: "20%",
                marginRight: "20%",
              }}
            >
              Lorem ipsum dolor sit amet consectetur adipiscing eli mattis sit
              phasellus mollis sit aliquam sit nullam neque ultrices.
            </p>
            <Button
              type="primary"
              onClick={() => {
                // onCancel();
                // onConfirm();
                navigate("/dashboard");
              }}
              style={{
                width: 270,
                height: 50,
                marginTop: 20,
                // marginRight: 10,
                backgroundColor: "#5d5a88",
                // color: "#ffffff",
                border: "2px solid #5d5a88",
                borderRadius: 30,
              }}
              // type="primary"
            >
              Dashboard
            </Button>
            <IconBigPreview style={{ marginTop: 70, marginLeft: 300 }} />
            <IconBigPreview style={{ marginTop: -200, marginRight: 300 }} />

            {/* <div
              id="scrollableDiv"
              style={{
                height: 400,
                overflowX: "scroll",
                overflowY: "hidden",
                padding: "0 16px",
                border: "1px solid rgba(140, 140, 140, 0.35)",
              }}
            >
              <List
                itemLayout="horizontal"
                grid={{ gutter: 16, column: 3 }}
                dataSource={data}
                renderItem={(item) => (
                  <List.Item>
                    <CustomCard
                      title={item.title}
                      description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
                      onClickButton={() => console.log("Dashboard")}
                    />
                  </List.Item>
                )}
              />
            </div> */}
          </Flex>
          <Flex vertical={true} align="start" style={{marginLeft:40, marginRight:40}} >
          <h1
            align="start"
              className="text-h1"
              style={{ marginTop: 100, marginLeft:40, marginRight:0, color: "#5d5a88" }}
            >
              Dashboard
            </h1>
 
            <div className="row__posters" >
              {data.map((item) => (
                <CustomCard
                  className="row__poster row__posterLarge"
                  title={item.title}
                  description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
                  onClickButton={() => console.log("Dashboard")}
                />
              ))}
            </div>
          </Flex>
        </div>

        {/* <h1> {t("home")}</h1>
        <select defaultValue={i18n.language} onChange={onChangeLang}>
          {LANGUAGES.map(({ code, label }) => (
            <option key={code} value={code}>
              {label}
            </option>
          ))}
        </select>
        <DatePicker /> */}
      </div>
    </>
  );
};

export default Home;
