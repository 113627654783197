import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Card,
  Button,
  Table,
  Space,
  Modal,
  Form,
  Input,
  Row,
  Col,
  Select,
  Switch,
  Tooltip,
  message,
  InputNumber,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import "./RoutesOcelot.css";
import { Flex } from "antd";
import SuccessDialog from "../../../components/Dialog/SuccessDialog";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import FailedDialog from "../../../components/Dialog/FailedDialog";
import NavOcelot from "../../../components/Nav/NavOcelot";
import FooterOcelot from "../../../components/Footer/FooterOcelot";
import { ReRouteService } from "../../../services/ReRouteService";

const { Option } = Select;

const RoutesOcelot = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalVisibleEdit, setIsModalVisibleEdit] = useState(false);
  const [selectedHttpMethods, setSelectedHttpMethods] = useState([]);
  const [form] = Form.useForm();
  // const [form2] = Form.useForm();
  const [rateLimitingEnabled, setRateLimitingEnabled] = useState("");
  const [routes, setRoutes] = useStateCallback([]);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [formData, setFormData] = useState([]);
  const [formDataRolelimit, setFormDataRolelimit] = useState([]);
  const [formDataRole, setFormDataRole] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [roles, setRoles] = useStateCallback([]); // เริ่มต้นเป็นอาร์เรย์ว่าง
  const [mode, setMode] = useState("add");
  const [OptionsAuthentication, setOptionsAuthentication] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [options, setOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedAuthority, setSelectedAuthority] = useState("");
  const [updatedRecord, setUpdatedRecord] = useStateCallback({ RoleValues: [] });
  const [authenOptionEnabled, setAuthenOptionEnabled] = useState(false);
  const [allRoleLimit, setAllRoleLimit] = useState();
  const [updateRoleLimit, setUpdateRoleLimit] = useState(0);
  const [prevUpstreamPath, setprevUpstreamPath] = useState();
  const [flowEnabled, setFlowEnable] = useState(false);
  const [isChangeInput, setIsChangeInput] = useState(false);
  const [rateLimitCurrentPerieodUnit, setRateLimitCurrentPeriodUnit] = useState("s");
  // const swRateLimitingEnabledRef = useRef();
  const rulePeriod = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (value !== "" && !isNaN(value)) {
        let element = document.getElementById('periodUnit');
        // let test = getFieldValue("periodUnit")
        let rawValue = getFieldValue("period")
        let number = parseInt(value);
        return handleDisplayValidatorMessage(number, rateLimitCurrentPerieodUnit);
      }

      return Promise.resolve();
    }
  });

  const rulePeriodRoleAddMode = ({ getFieldValue, index }) => ({
    validator(rule, value) {
      if (!isNaN(value)) {
        let element = document.getElementById('periodUnit');
        // let test = getFieldValue("periodUnit")
        let rawValue = getFieldValue("period")
        let number = parseInt(value);
        handleDisplayValidatorMessage(number, rateLimitCurrentPerieodUnit);
      }

      return Promise.resolve();
    }
  });

  const handleDisplayValidatorMessage = (number, currentPeriodUnit) => {
    if(currentPeriodUnit === "s" || currentPeriodUnit === "m"){
      if(number > 59){
        return Promise.reject(
          "Value should be less than 59"
        );
      }
    }else{
      if(number > 24){
        return Promise.reject(
          "Value should be less than 24"
        );
      }
    }
    return Promise.resolve();
  }

  const rulePeriodTimeSpan = ({ getFieldValue }) => ({
    validator(rule, value) {
      if (!isNaN(value)) {
        if(parseInt(value) > 59){
          return Promise.reject(
            "Value should be less than 59 character"
          );
        }
       
      }

      return Promise.resolve();
    }
  });

  const columns = [
    {
      title: "Route name",
      dataIndex: "routeName",
      key: "routeName",
    },
    {
      title: "Upstream Path",
      dataIndex: "upstreamPathTemplate",
      key: "upstreamPathTemplate",
    },
    {
      title: "Upstream Http Method",
      key: "upstreamHttpMethod",
      render: (text, record) => (
        <>
          {Array.isArray(record.upstreamHttpMethod) &&
            record.upstreamHttpMethod.map((method, index) => (
              <Button
                key={index}
                type="default"
                style={{
                  backgroundColor: "#F8F8F9",
                  border: "1px solid #D0D5DD",
                  color: "#000",
                  fontWeight: "bold",
                  marginRight: "8px",
                }}
              >
                {method}
              </Button>
            ))}
        </>
      ),
    },
    {
      title: "Downstream Path",
      dataIndex: "downstreamPathTemplate",
      key: "downstreamPathTemplate",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        if (status === "new") {
          return (
            <Button
              type="default"
              style={{
                backgroundColor: "#F6FFED",
                border: "1px solid #B7EB8F",
                color: "#B7EB8F",
                padding: "0 8px",
                borderRadius: "4px",
              }}
            >
              {status}
            </Button>
          );
        } else if (status === "Changed") {
          return (
            <Button
              type="default"
              style={{
                backgroundColor: "#E6F7FF",
                border: "1px solid #91D5FF",
                color: "#1890FF",
                padding: "0 8px",
                borderRadius: "4px",
              }}
            >
              {status}
            </Button>
          );
        } else {
          return <span>-</span>; // Display a dash if status is neither "new" nor "Changed"
        }
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button
            icon={<EditOutlined />}
            onClick={() => handleEdit(record, formDataRole)}
            style={{
              border: "1px solid #0063A7",
              color: "#0063A7",
            }}
          />
          <Button
            icon={<DeleteOutlined />}
            style={{
              border: "1px solid #0063A7",
              color: "#0063A7",
            }}
            onClick={() => handleOpenModal(record, formDataRolelimit)}
          />
        </Space>
      ),
    },
  ];

  const handleChangePeriodUnit = (value, mode, index) => {
    console.log(`selected ${value}`);
    if(mode === "rate"){
      setRateLimitCurrentPeriodUnit(value);
    }else if(mode === "addRole") {
      roles[index].periodUnit = value;
    }else{
      updatedRecord.RoleValues[index].periodUnit = value;
    }
    
  };

  function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null);

    const setStateCallback = useCallback((state, cb) => {
      cbRef.current = cb;
      setState(state);
    }, []);

    useEffect(() => {
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null;
      }
    }, [state]);

    return [state, setStateCallback];
  }

  const getRoleLimit = async (page) => {
    try {
      // setLoading(true);
      const apiRoleData = await fetchRoleRateLimitData();
      console.log("apiRoleData:", apiRoleData);
      let updatedList = apiRoleData.roles.map((item) => {
        if (item.id === undefined) {
          return {
            ...item,
            id: uuidv4(),
          }; //gets everything that was already in item, and updates "done"
        }
        return item; // else return unmodified item
      });
      setAllRoleLimit(updatedList);
    
    
    } catch (error) {
      console.error();
    }finally{
      // setLoading(false);
    }
  };

  useEffect(() => {
    getRoleLimit();
  }, [updateRoleLimit]);

  const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) =>
      (
        +c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
      ).toString(16)
    );
  };

  useEffect(() => {
    if (selectedRecord === null) {
      setRoutes([{ host: "", port: "" }]); // รีเซ็ต routes ให้เป็นค่าเริ่มต้น (host และ port ว่างเปล่า)
    } else if (selectedRecord && selectedRecord.downstreamHostAndPorts) {
      const data = selectedRecord.downstreamHostAndPorts;
      setRoutes(data); // อัปเดต routes ด้วยข้อมูลจาก selectedRecord
    }
  }, [selectedRecord]);
  const handleSwitchChangeTrueFlase = (checked) => {
    setRateLimitingEnabled(checked);
    console.log("checked", checked);
  };

  const handleSwitchChangeFLowTrueFlase = (checked) => {
    setFlowEnable(checked);
    console.log("checked", checked);
  };

  const handleSwitchChangeAuthenOPtionTrueFlase = (checked) => {
    setAuthenOptionEnabled(checked);
    console.log("checked", checked);
  };

  useEffect(() => {
    // ดึงข้อมูลจาก localStorage
    const storedFormDataRolelimit = JSON.parse(
      localStorage.getItem("formDataRolelimit")
    );

    // ตรวจสอบว่ามีข้อมูลใน localStorage หรือไม่
    if (!storedFormDataRolelimit || storedFormDataRolelimit.length === 0) {
      // ใช้ข้อมูลจาก formData หาก localStorage ไม่มีข้อมูล
      const roleValues = formData.flatMap((route) => route.RoleValues || []);

      localStorage.setItem("formDataRolelimit", JSON.stringify(roleValues));
    } else {
      // ถ้ามีข้อมูลใน formDataRolelimit ให้เขียนทับข้อมูลเดิม
      localStorage.setItem(
        "formDataRolelimit",
        JSON.stringify(formDataRolelimit)
      );
    }

    localStorage.setItem("formDataRole", JSON.stringify(formDataRole));
    // บันทึกข้อมูลใน localStorage ทุกครั้งที่ formData เปลี่ยนแปลง
    localStorage.setItem("formData", JSON.stringify(formData));
  }, [formData, formDataRolelimit]);
  const handleHttpMethodChange = (value) => {
    setSelectedHttpMethods(value);
  };
  const handleAddNewRoutes2 = () => {
    const formValues = form.getFieldsValue(); // ดึงค่าจากฟอร์ม
    setRoles([...roles, { id: uuidv4(), key: roles.length + 1, isAdvanced: false, periodUnit:"s"  ,role: undefined,
      upstreamPath: formValues.upstreamPathTemplate || "" }], function (data){
      data.map((route, index) => {
        // let element = document.getElementById('host-0');
      // console.log(element);
        if(data.length-1 === index ){
          //periodUnit_role_
          setActiveRoles({index:false})
          form.setFieldsValue({
            // [`Role-${index}`]: "",
            // [`period_role_-${index}`]: "",
            // [`periodTimespan_role_${index}`]: "",
            // [`limit_role_${index}`]: "",
            [`Role-${index}`]: "",
            [`period-${index}`]: "",
            [`period-unit-${index}`]: "s",
            [`period-time-${index}`]: "",
            [`period-limit-${index}`]: "",
          })
        }
        
      })
    });
  };
  const handleAddNewRoutesEdit = () => {
    const formValues = form.getFieldsValue(); // ดึงค่าจากฟอร์ม
    const newRole = {
      id: uuidv4(),
      role: undefined,
      upstreamPath: formValues.upstreamPathTemplate || "", // ใช้ค่า upstreamPathTemplate จากฟอร์ม
      limit: 0,
      period: "0s",
      periodTimespan: 0,
      periodUnit: "s", // ตั้งค่าเริ่มต้น
      advance: false,
    };

    setUpdatedRecord((prevRecord) => ({
      ...prevRecord,
      RoleValues: [...prevRecord.RoleValues, newRole],
    }), function(data) {
      data.RoleValues.map((route, index) => {
        // let element = document.getElementById('host-0');
      // console.log(element);
        if(data.RoleValues.length-1 === index ){
          form.setFieldsValue({
            [`Role-${index}`]: "",
            [`period-${index}`]: "",
            [`period-unit-${index}`]: "s",
            [`period-time-${index}`]: "",
            [`period-limit-${index}`]: "",
          })
        }
        
      })
      
    });
  }

   
  const handleRemoveRole2 = (index) => {
    const newRoleValues = updatedRecord.RoleValues.filter(
      (_, i) => i !== index
    );
    setUpdatedRecord((prevRecord) => ({
      ...prevRecord,
      RoleValues: newRoleValues,
    }));
    // setAllRoleLimit(allRoleLimit.filter((role) => role.key !== index));
  };
  const handleRemoveRole = (key) => {
    setRoles(roles.filter((role) => role.key !== key));
    // setAllRoleLimit(allRoleLimit.filter((role) => role.key !== key));
  };
  const [activeRoles, setActiveRoles] = useState(
    updatedRecord.RoleValues.reduce((acc, _, index) => {
      acc[index] = true; // ค่าเริ่มต้นเป็น true
      return acc;
    }, {})
  );
  const handleSwitchChange = (index) => {
    setActiveRoles((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // สลับค่าเป็น true/false
    }));
    roles.map((data, roleIndex) => {
      // let element = document.getElementById('host-0');
    // console.log(element);
      if(index === roleIndex){
        form.setFieldsValue({
          [`period-${index}`]: "",
          [`period-unit-${index}`]: "s",
          [`period-time-${index}`]: "",
          [`period-limit-${index}`]: "",
        })
      }
      
      
    })
  };
  const handleDelete = (record, formDataRolelimit, formDataRole) => {
    console.log("ค่าฟิวนั้นๆ:", record);
    // ลบค่า record ออกจาก formData
    const updatedFormData = formData.filter(
      (item) => JSON.stringify(item) !== JSON.stringify(record)
    );
    setFormData(updatedFormData);

    // ลบค่า record ออกจาก formDataRole โดยใช้ upstreamPath
    const updatedFormDataRole = formDataRole.filter(
      (item) => item.UpstreamPath !== formDataRolelimit.upstreamPath
    );
    console.log("ok", updatedFormDataRole);
    setFormDataRole(updatedFormDataRole);
    const updatedFormAllDataRole = allRoleLimit.filter(
      (item) => item.UpstreamPath !== formDataRolelimit.upstreamPath
    );
    setAllRoleLimit(updatedFormAllDataRole);

    // setTimeout(() => {
    //   setIsModalSuccessOpen(true);
    // }, 300);
    // setTimeout(() => {
    //   setIsModalSuccessOpen(false);
    // }, 1300);
  };
  const handleOpenModal = (record, formDataRolelimit) => {
    console.log(record);
    console.log(formDataRolelimit);
    setCurrentRecord(record, formDataRolelimit);
    setIsModalDeleteOpen(true);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const apiData = await ReRouteService.getRoute();
        console.log("Data received from API:", apiData);
        if (apiData && Array.isArray(apiData.routes)) {
          setFormData(apiData.routes);
        } else {
          console.error('Expected an array in "routes" key from API');
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();

    const fetchOptions = async () => {
      try {
        const data = await ReRouteService.getAuthenProvider();
        if (Array.isArray(data.authenticationProviders)) {
          setOptionsAuthentication(data.authenticationProviders);
        } else {
          throw new Error("Data is not in the expected format");
        }
      } catch (error) {
        message.error("Failed to fetch options");
        console.error("Error fetching options:", error);
      }
    };

    fetchOptions();
  }, []);
  useEffect(() => {
    console.log("Form Data Role has been updated:", formDataRole);
  }, [formDataRole]);

  const fetchData = async (selectedKey) => {
    try {
      const data = await ReRouteService.getRealmRole({ realm: selectedKey });
      setOptions(data); // สมมติว่า data เป็น array ของ options
      form.setFieldsValue({
        realmAccess: undefined
      })
    } catch (error) {
      console.error("Error fetching data:", error);
      setOptions([]);
      form.setFieldsValue({
        realmAccess: ""
      })
    }
  };

  const handleAuthenticationProviderChange = async (selectedKey) => {
    // หาค่า authority ตาม selectedKey
    const selectedOption = OptionsAuthentication.find(
      (option) => option.key === selectedKey
    );
    if (selectedOption) {
      setSelectedAuthority(selectedOption.key); // เก็บค่า authority ไว้ใน state
      // ดึงข้อมูลใหม่ด้วยค่า authority
      const baseIndex = selectedOption.authority.indexOf("/realms/");
      const realm =
        baseIndex !== -1
          ? selectedOption.authority.substring(baseIndex + "/realms/".length)
          : "";
      await fetchData(realm);
    }
  };

  const handleAddNewRoutes = () => {
    setAuthenOptionEnabled(false);
    setRateLimitCurrentPeriodUnit("s");
    form.resetFields();
    setSelectedRecord(null); // ตั้งค่า selectedRecord เป็น null เพื่อล้างข้อมูลเก่า
    setMode("add"); // ตั้งค่าโหมดเป็น 'add'
    setRoles([]); // ล้างค่า roles ให้เป็น array ว่างเปล่า
    setIsModalVisible(true); // เปิด Modal สำหรับเพิ่มรายการใหม่
    setRoutes([{ host: "", port: "" }]);
    setRateLimitingEnabled(false);

    const formValues = {
      routeName: "",
      upstreamPathTemplate:  "",
      upstreamHttpMethod: [],
      downstreamScheme: "",
      downstreamHostAndPorts: [{ Host: "", Port: "" }],
      downstreamPathTemplate:  "",
      period: "",
      periodTimespan:  "",
      limit:  "",
      authenticationProviderKey: undefined,
      realmAccess: "",
      roles: "",
      authenOptionEnabled: false,
    };
    form.resetFields();
    form.setFieldsValue(formValues);
    setTimeout(() => {
      formValues.downstreamHostAndPorts.map((route, index) => {
        // let element = document.getElementById('host-0');
      // console.log(element);
        form.setFieldsValue({
          [`host-${index}`]: "",
          [`port-${index}`]: ""
        })
      })
      
      // element.value = "Test";
      // element.disabled = false;
          }, 400);

  };
  useEffect(() => {
    console.log("Form Data Role has been updated:", formDataRole);
  }, [formDataRole]);
  const handleChange = (index, field, value) => {
    setIsChangeInput(true);
    setRoutes((prevRoutes, routeIndex) => {
      const newRoutes = [...prevRoutes];
      if (newRoutes[index]) {
        newRoutes[index] = {
          ...newRoutes[index],
          [field]: value,
        };
      }
      if(field === "host" && routeIndex === index ){
        newRoutes[index] = {
          ...newRoutes[index],
          [`host-${index}`]: false,
        };
      }
      if(field === "period" && routeIndex === index ){
        newRoutes[index] = {
          ...newRoutes[index],
          [`period-${index}`]: false,
        };
      }
      return newRoutes;
    });
  };
  const handleChangeRoute = (index, key, value) => {
    console.log("index:", index, "key:", key, "value:", value); // ดูค่าที่ส่งเข้ามา
    setIsChangeInput(true);

    if (key === "perioddd") {///ignore period
      const pattern = /^[0-9]+$/;
      if (!pattern.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [`period-${index}`]: "Please enter a valid period!",
        }));
        return; // ไม่อัพเดตค่าใน updatedRecord ถ้าไม่ตรง pattern
      } else {
        // ลบ error ถ้า pattern ถูกต้อง
        setErrors((prevErrors) => {
          const { [`period-${index}`]: removedError, ...remainingErrors } =
            prevErrors;
          return remainingErrors;
        });
      }
    }
    setUpdatedRecord((prevState) => {
      const newRoleValues = [...prevState.RoleValues];
      newRoleValues[index] = { ...newRoleValues[index], [key]: value };
      

    if(key === "advance"){
      newRoleValues[index] = { ...newRoleValues[index], [key]: value, limit: 0, 
        period: "s", periodTimespan: 0, periodUnit: "s"};
        form.setFieldsValue({
          [`period-${index}`]: "",
          [`period-unit-${index}`]: "s",
          [`period-time-${index}`]: "",
          [`period-limit-${index}`]: "",
        })
    }
      return { ...prevState, RoleValues: newRoleValues };
    });
  };

  const handleAddNewHostandPath = () => {
    setRoutes((prevRoutes) => [...prevRoutes, { host: "", port: "", isFirstHost:true, isFirstPort:true }], function(data){
      data.map((route, index) => {
        if(route.host === "" && route.port === ""){
          form.setFieldsValue({
            [`host-${index}`]: "",
            [`port-${index}`]: ""
          })
        }
        
      });
    });
  };
  const handleRemoveRoute = (index) => {
    console.log("Index to remove:", index); // ตรวจสอบค่าของ index ที่จะลบ
    const updatedRoutes = routes.filter((_, i) => i !== index);
    console.log("Updated Routes:", updatedRoutes); // ตรวจสอบผลลัพธ์หลังจากลบ
    setRoutes(updatedRoutes);
  };
  const handleEdit = async (record) => {
    console.log("Record:", record);
    form.resetFields();
    // setRoutes((prevRoutes) => [...record.downstreamHostAndPorts, {isFirstHost:true, isFirstPort:true }]);
    setRoutes((prevRoutes) => record.downstreamHostAndPorts)
    setprevUpstreamPath(record.upstreamPathTemplate);

    // ดึงข้อมูลที่เก็บไว้ใน localStorage
    const storedData = JSON.parse(
      localStorage.getItem("downstreamHostAndPorts") || "[]"
    );

    // ตรวจสอบและกำหนดค่า downstreamHostAndPorts
    let downstreamHostAndPorts = record.downstreamHostAndPorts || [];
    if (downstreamHostAndPorts.length === 0) {
      downstreamHostAndPorts = storedData;
    }
    //setRoutes(downstreamHostAndPorts);

    // อัปเดต localStorage
    localStorage.setItem(
      "downstreamHostAndPorts",
      JSON.stringify(downstreamHostAndPorts)
    );

    // ดึงข้อมูลจาก API
    // const apiRoleData = await fetchRoleRateLimitData();
    // console.log("apiRoleData:", apiRoleData);

    if (Array.isArray(allRoleLimit)) {
      // กรองและจัดรูปแบบข้อมูล roles
      const filteredRoles = allRoleLimit.filter(
        (role) => role.upstreamPath === record.upstreamPathTemplate
      );

      let formattedRoles = filteredRoles.map((role) => ({
      id: role.id,
      role: role.role || "",
      upstreamPath: role.upstreamPath || "",
      limit: role.limit || 0,
      period: role.period || "0s",
      periodTimespan: role.periodTimespan || 0,
      advance: role.limit ? true: false,
      
    }));
    formattedRoles = formattedRoles.filter(x => {
      return x.role != "";
    })

    formattedRoles = formattedRoles.filter(x => {
      return x.role != '';
    })


      console.log("Formatted Roles:", formattedRoles);

      // กำหนดค่าให้กับ updatedRecord
      const updatedRecord = {
        ...record,
        RoleValues: formattedRoles,
      };

      // กรองค่า realmAccess ที่เป็นค่าว่าง
      const realmAccessArray = (
        record.routeClaimsRequirement?.realm_access || ""
      )
        .split(",")
        .map((item) => item.trim())
        .filter((value) => value !== "");

      // กำหนดค่า period และ periodTimespan
      const period =
        record.rateLimitOptions?.period.replace(/[a-zA-Z]/g, "") || 0;

      // กรองค่า authenticationProviderKey ที่เป็นค่าว่าง
      const authenticationProviderKey =
        record.authenticationOptions?.authenticationProviderKey || "";
      const displayAuthenticationProviderKey =
        authenticationProviderKey !== ""
          ? authenticationProviderKey
          : undefined;

      // กำหนดค่าเริ่มต้นให้กับ formValues
      let formValues = {};
      let flow = false
      if(record.routeType === "Internal"){
        flow = true;
      }else{
        flow = false;
      }
      setFlowEnable(flow);
      if(record.authenticationOptions === null || record.authenticationOptions === undefined){

        formValues = {
          routeName: record.routeName || "",
          flow: flow,
          upstreamPathTemplate: record.upstreamPathTemplate || "",
          upstreamHttpMethod: record.upstreamHttpMethod || [],
          downstreamScheme: record.downstreamScheme || "",
          downstreamHostAndPorts:
            downstreamHostAndPorts.length > 0
              ? downstreamHostAndPorts
              : [{ Host: "", Port: "" }],
          downstreamPathTemplate: record.downstreamPathTemplate || "",
          // period: period,
          // rateLimitingEnabled: record.rateLimitOptions.enableRateLimiting, 
          // periodTimespan: record.rateLimitOptions?.periodTimespan || 0,
          // limit: record.rateLimitOptions?.limit || 0,
          // authenticationProviderKey: displayAuthenticationProviderKey,
          // realmAccess: realmAccessArray,
          roles: formattedRoles,
        };
        setAuthenOptionEnabled(false);
        form.setFieldsValue({
          authenOptionEnabled: false
        })
      }else{
        formValues = {
          routeName: record.routeName || "",
          flow: flow,
          upstreamPathTemplate: record.upstreamPathTemplate || "",
          upstreamHttpMethod: record.upstreamHttpMethod || [],
          downstreamScheme: record.downstreamScheme || "",
          downstreamHostAndPorts:
            downstreamHostAndPorts.length > 0
              ? downstreamHostAndPorts
              : [{ Host: "", Port: "" }],
          downstreamPathTemplate: record.downstreamPathTemplate || "",
          // period: period,
          // rateLimitingEnabled: record.rateLimitOptions.enableRateLimiting, 
          // periodTimespan: record.rateLimitOptions?.periodTimespan || 0,
          // limit: record.rateLimitOptions?.limit || 0,
          authenticationProviderKey: displayAuthenticationProviderKey,
          realmAccess: realmAccessArray,
          roles: formattedRoles,
        };
        setAuthenOptionEnabled(true);
        form.setFieldsValue({
          authenOptionEnabled: true
        })
      }

      const roleRateLimitList = filteredRoles.filter(
        (role) => role.role === ""
      );
      const roleRateLimit = roleRateLimitList[0];
      if(roleRateLimit !== undefined && roleRateLimit.role === ""){
        formValues.period = roleRateLimit.period.substring(0, roleRateLimit.period.length - 1);;
        formValues.limit = roleRateLimit.limit;
        formValues.periodTimespan = roleRateLimit.periodTimespan;
        formValues.periodUnit = roleRateLimit.period.slice(-1);
        formValues.rateLimitingEnabled = true
        form.setFieldsValue({
          rateLimitingEnabled: true,
          periodUnit: roleRateLimit.period.slice(-1)
        })
        setRateLimitCurrentPeriodUnit(roleRateLimit.period.slice(-1));
        setRateLimitingEnabled(true);
      }else{
        setRateLimitingEnabled(false);
      }


      // ตั้งค่าให้กับฟอร์ม
      setUpdatedRecord(updatedRecord);
      form.setFieldsValue({
        ...formValues,
        roles: formattedRoles.reduce(
          (acc, role, index) => ({
            ...acc,
            [`roles[${index}].role`]: role.role,
            [`roles[${index}].period`]: role.period,
            [`roles[${index}].periodTimespan`]: role.periodTimespan,
            [`roles[${index}].limit`]: role.limit,
          }),
          {}
        ),
      });

      // ตั้งค่า state และเปิด Modal
      // element.disabled = hasErrors
      setSelectedRecord(updatedRecord);
      setIsModalVisibleEdit(true);
      setTimeout(() => {
        record.downstreamHostAndPorts.map((route, index) => {
          // let element = document.getElementById('host-0');
        // console.log(element);
          form.setFieldsValue({
            [`host-${index}`]: route.host,
            [`port-${index}`]: route.port
          })
        })

        formattedRoles.map((data, index) => {
          // let element = document.getElementById('host-0');
        // console.log(element);
          form.setFieldsValue({
            [`Role-${index}`]: data.role,
            [`period-${index}`]: data.period.replace(/[a-zA-Z]/g, ""),
            [`period-unit-${index}`]: data.period.slice(-1),
            [`period-time-${index}`]: data.periodTimespan,
            [`period-limit-${index}`]: data.limit,
          })
        })
        
        // element.value = "Test";
        // element.disabled = false;
            }, 400);
      setMode("edit");
    } else {
      console.error(
        "apiRoleData does not contain an array of roles:"
      );
    }
  };
  const handleCancel = () => {
    form.resetFields(); // รีเซ็ตฟอร์ม
    setSelectedRecord(null);
    setRoutes([{ host: "", port:"" }]); // รีเซ็ต routes ให้เป็นค่าหลัก
    setRoles([]);
    setIsModalVisible(false);
    setIsModalVisibleEdit(false);
  };

  const handleOnfinish = (values) => {
    sessionStorage.setItem("isChanged", true);
    console.log(values);

    const rateLimitingEnabled = values.rateLimitingEnabled === true;
    const periodValue = values.period || "0";
    const periodUnit = values.periodUnit || "s";
    const period = rateLimitingEnabled ? `${periodValue}${periodUnit}` : "0s";
    const periodTimespan = rateLimitingEnabled
      ? parseInt(values.periodTimespan || "0", 10)
      : 0;
    const limit = rateLimitingEnabled ? parseInt(values.limit || "0", 10) : 0;

    // รวบรวมค่า host และ port ทั้งหมด
    const downstreamHostAndPorts = [];
    Object.keys(values).forEach((key) => {
      if (key.startsWith("host-")) {
        const index = key.split("-")[1];
        const host = values[`host-${index}`];
        const port = values[`port-${index}`];
        if (host && port) {
          downstreamHostAndPorts.push({ host, port });
        }
      }
    });
    // roles.map
    // const RoleValues = roles.map((role, index) => ({
    //   id: uuidv4(),
    //   role: values[`Role-${index}`] || "",
    //   period: values[`period-${index}`] || "",
    //   periodUnitRole: values[`period-unit-${index}`] || "s",
    //   periodTimespan:
    //     parseInt(values[`period-time-${index}`], 10) || 0,
    //   limit: parseInt(values[`period-limit-${index}`], 10) || 0,
    //   upstreamPath: values.upstreamPathTemplate,
    // }));

    let RoleValues = [];
    RoleValues = roles.map((role, index) => ({
            id: role.id,
            role: values[['Role-'+index]],
            period: values[['period-'+index]]+values[['period-unit-'+index]],
            periodTimespan: values[['period-time-'+index]],
            limit: values[['period-limit-'+index]],
            upstreamPath: values.upstreamPathTemplate , // ตรวจสอบค่าที่นี่
      }));
      console.log("New Role Values:", RoleValues);

    let flow = "External"
      if(flowEnabled){
        flow = "Internal";
      }else{
        flow = "External";
      }

    const formattedValues = {
      ...values,
      routeType: flow,
      downstreamHostAndPorts: downstreamHostAndPorts,
      rateLimitOptions: {
        enableRateLimiting: rateLimitingEnabled,
        period: period,
        periodTimespan: periodTimespan,
        limit: limit,
      },
      authenticationOptions: {
        authenticationProviderKey: selectedAuthority || "", // ใช้ค่า selectedAuthority
      },
      routeClaimsRequirement: {
        realm_access: values.realmAccess ? values.realmAccess.join(",") : "",
      },
      RoleValues: RoleValues,
      status: mode === "edit" && selectedRecord ? "existing" : "new", // Add status
    };

    console.log(formattedValues);

    // ลบคีย์ที่ไม่ต้องการออกจาก formattedValues
    if(!authenOptionEnabled){
      delete formattedValues.authenticationOptions;
      delete formattedValues.routeClaimsRequirement;
    }
    // delete formattedValues.host;
    // delete formattedValues.port;
    // delete formattedValues.period;
    // delete formattedValues.limit;
    // delete formattedValues.periodTimespan;
    // delete formattedValues.periodUnit;

    console.log("RoleValues:", RoleValues);

    if (mode === "edit" && selectedRecord) {
      const updatedData = formData.map((item) =>
        item.routeName === selectedRecord.routeName ? formattedValues : item
      );
      setFormData(updatedData);
    } else {
      setFormData([...formData, formattedValues]);
    }

    // ตั้งค่า RoleValues แยกต่างหาก
    setFormDataRole([...formDataRole, ...RoleValues]);
    // setAllRoleLimit(...allRoleLimit, RoleValues);
    let resultRoleList = addOrupdateData(RoleValues, values.upstreamPathTemplate, false);
    if(rateLimitingEnabled){
      // rateLimitOptions: {
       //   enableRateLimiting:
       //     route.rateLimitOptions?.enableRateLimiting,
       //   period: route.rateLimitOptions?.period || "0s",
       //   periodTimespan: parseInt(
       //     route.rateLimitOptions?.periodTimespan || 0
       //   ),
       //   limit: parseInt(route.rateLimitOptions?.limit || 0),
       // },
     const rawPeriod = period || period || "0";
     const cleanPeriod = rawPeriod.replace(/[smh]/g, "");
    //  let roleLateLimit = {}
    //  const filteredRoles = allRoleLimit.filter(
    //   (role) => role.upstreamPath === values.upstreamPathTemplate && role.role === ""
    // );
    // if(filteredRoles.length > 0){
    //   roleLateLimit = filteredRoles[0];
    // }else{
    //   roleLateLimit = {
    //     id: uuidv4(),
    //     role: "",
    //     upstreamPath: values.upstreamPathTemplate,
    //     limit: parseInt(limit || 0),
    //     period: period,
    //     periodTimespan: periodTimespan,
    //   };
    // }
    let roleLateLimit = {}
       const filteredRoles = allRoleLimit.filter(
        (role) => role.upstreamPath === values.upstreamPathTemplate && role.role === ""
      );
      if(filteredRoles.length > 0){
        roleLateLimit = filteredRoles[0];
        roleLateLimit.upstreamPath = values.upstreamPathTemplate;
        roleLateLimit.limit = parseInt(limit || 0);
        roleLateLimit.period = period;
        roleLateLimit.periodTimespan = periodTimespan;
      }else{
        roleLateLimit = {
          id: uuidv4(),
          role: "",
          upstreamPath: values.upstreamPathTemplate,
          limit: parseInt(limit || 0),
          period: period,
          periodTimespan: periodTimespan,
        };
      }
    //  resultRoleList = [roleLateLimit].concat(resultRoleList)
    const updatedRoleValues = [roleLateLimit];
    resultRoleList = addOrupdateData(updatedRoleValues, values.upstreamPathTemplate, "addRoleLimit", resultRoleList);
   }

   if(!authenOptionEnabled){
    addOrupdateData([], values.upstreamPathTemplate, "delete");
  }
    // addOrupdateData(RoleValues, values.upstreamPathTemplate, false);

    console.log("Formatted Values:", formattedValues);
    setIsModalVisible(false);
  }

  const onFinish = (values) => {
    form
      .validateFields()
      .then((values) => {
        handleOnfinish(values)
      })
      .catch((errorInfo) => {
        console.log('errorInfo ...', errorInfo);
        let values = errorInfo.values;
        console.log('values ...', values);
        // handleOnFinishEdit(values)
      });
    
  };

  const onFinish2 = (values) => {
    form
      .validateFields()
      .then((values) => {
        handleOnFinishEdit(values)
      })
      .catch((errorInfo) => {
        console.log('errorInfo ...', errorInfo);
        let values = errorInfo.values;
        console.log('values ...', values);
        // handleOnFinishEdit(values)
      });
    
  };

  function handleOnFinishEdit(values){
    console.log('values ...', values);
    sessionStorage.setItem("isChanged", true);
    const newErrors = {};
    routes.forEach((route, index) => {
      if (!route.host) newErrors[`host-${index}`] = "Please enter host!";
      if (!route.port) newErrors[`port-${index}`] = "Please enter port!";
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      console.log("Values:", values);
      const UpstreamPath = values.upstreamPathTemplate || "";
      console.log("UpstreamPath:", UpstreamPath);

      //const newRoleValues = [];
      // for (let i = 0; ; i++) {
      //   const role = values[`roles[${i}].role`];
      //   if (role === undefined) break; // ออกเมื่อไม่มีข้อมูล

      //   // รวบรวมข้อมูล RoleValues ใหม่จาก values
      //   newRoleValues.push({
      //     id: uuidv4(),
      //     role: role || "",
      //     period: values[`roles[${i}].period`] || "",
      //     periodUnitRole: values[`roles[${i}].periodUnit`] || "s",
      //     periodTimespan: parseInt(
      //       values[`roles[${i}].periodTimespan`] || "0",
      //       10
      //     ),
      //     limit: parseInt(values[`roles[${i}].limit`] || "0", 10),
      //     upstreamPath: UpstreamPath || "test", // ตรวจสอบค่าที่นี่
      //   });
      // }

      let updatedRoleValues = [...updatedRecord.RoleValues];
      updatedRoleValues = updatedRoleValues.map((role, index) => ({
            id: role.id,
            role: role.role,
            period: values[['period-'+index]]+values[['period-unit-'+index]],
            periodTimespan: values[['period-time-'+index]],
            limit: values[['period-limit-'+index]],
            upstreamPath: UpstreamPath , // ตรวจสอบค่าที่นี่
      }));
      console.log("New Role Values:", updatedRoleValues);

      const downstreamHostAndPortsData = routes.map((route) => ({
        host: route.host,
        port: route.port,
      }));

      const storedData = JSON.parse(
        localStorage.getItem("downstreamHostAndPorts") || "[]"
      );

      // ตรวจสอบการเปิดใช้งานการจำกัดอัตราการใช้งาน
      const rateLimitingEnabled = values.rateLimitingEnabled === true;
      const periodValue = values.period || "0";
      const periodUnit = values.periodUnit || "s";
      const period = rateLimitingEnabled ? `${periodValue}${periodUnit}` : "0s";
      const periodTimespan = rateLimitingEnabled
        ? parseInt(values.periodTimespan || "0", 10)
        : 0;
      const limit = rateLimitingEnabled ? parseInt(values.limit || "0", 10) : 0;

      const downstreamHostAndPorts = [];
      Object.keys(values).forEach((key) => {
        if (key.startsWith("host-")) {
          const index = key.split("-")[1];
          const host = values[`host-${index}`];
          const port = values[`port-${index}`];
          if (host && port) {
            downstreamHostAndPorts.push({ host, port });
          }
        }
      });
      console.log(downstreamHostAndPorts);
      let flow = "External"
      if(flowEnabled){
        flow = "Internal";
      }else{
        flow = "External";
      }

      const formattedValues = {
        ...values,
        routeType: flow,
        downstreamHostAndPorts: downstreamHostAndPortsData,
        rateLimitOptions: {
          enableRateLimiting: rateLimitingEnabled,
          period: period,
          periodTimespan: periodTimespan,
          limit: limit,
        },
        authenticationOptions: {
          authenticationProviderKey: values.authenticationProviderKey || "",
        },
        routeClaimsRequirement: {
          realm_access: values.realmAccess ? values.realmAccess.join(",") : "",
        },
        RoleValues: updatedRoleValues,
      };

      if(!authenOptionEnabled){
        delete formattedValues.authenticationOptions;
        delete formattedValues.routeClaimsRequirement;
      }

      console.log("formattedValues", formattedValues);

      // ลบคีย์ที่ไม่ต้องการออกจาก formattedValues
      // delete formattedValues.host;
      // delete formattedValues.port;
      // delete formattedValues.period;
      // delete formattedValues.limit;
      // delete formattedValues.periodTimespan;
      // delete formattedValues.periodUnit;

      if (mode === "edit" && selectedRecord) {
        const updatedData = formData.map((item) =>
          item.routeName === selectedRecord.routeName
            ? { ...formattedValues, status: "Changed" } // เพิ่ม status: "Changed"
            : item
            // { ...item, ...formattedValues, status: "Changed" } 
        );
        // if(!authenOptionEnabled){
        //   delete updatedData.authenticationOptions;
        //   delete updatedData.routeClaimsRequirement;
        // }
        setFormData(updatedData);
        console.log("Formatted Values:", updatedData);
      } else {
        setFormData([...formData, { ...formattedValues, status: "Changed" }]); // เพิ่ม status: "Changed"
      }
      

      let resultRoleList = addOrupdateData(updatedRoleValues, values.upstreamPathTemplate, false);
      if(rateLimitingEnabled){
        // rateLimitOptions: {
         //   enableRateLimiting:
         //     route.rateLimitOptions?.enableRateLimiting,
         //   period: route.rateLimitOptions?.period || "0s",
         //   periodTimespan: parseInt(
         //     route.rateLimitOptions?.periodTimespan || 0
         //   ),
         //   limit: parseInt(route.rateLimitOptions?.limit || 0),
         // },
       const rawPeriod = period || period || "0";
       const cleanPeriod = rawPeriod.replace(/[smh]/g, "");
       let roleLateLimit = {}
       const filteredRoles = allRoleLimit.filter(
        (role) => role.upstreamPath === UpstreamPath && role.role === ""
      );
      if(filteredRoles.length > 0){
        roleLateLimit = filteredRoles[0];
        roleLateLimit.upstreamPath = UpstreamPath;
        roleLateLimit.limit = parseInt(limit || 0);
        roleLateLimit.period = period;
        roleLateLimit.periodTimespan = periodTimespan;
      }else{
        roleLateLimit = {
          id: uuidv4(),
          role: "",
          upstreamPath: UpstreamPath,
          limit: parseInt(limit || 0),
          period: period,
          periodTimespan: periodTimespan,
        };
      }
      //  resultRoleList = [roleLateLimit].concat(resultRoleList)
      const updatedRoleValues = [roleLateLimit];
      resultRoleList = addOrupdateData(updatedRoleValues, values.upstreamPathTemplate, "addRoleLimit", resultRoleList);
     }

      if (resultRoleList.length > 0) {
        setFormDataRole([...formDataRole, ...resultRoleList]);

        // const filteredRoles = allRoleLimit.filter(
        //   (role) => role.upstreamPath === values.upstreamPathTemplate
        // );
        // let updatedList = updatedRoleValues.map((item) => {
        //   filteredRoles.map((roleItem) => {
        //     if (item.role === roleItem.role) {
        //       return {
        //         ...item,
        //         role: item.role,
        //         period: item.period,
        //         periodUnitRole: item.periodUnitRole,
        //         periodTimespan: item.periodTimespan,
        //         limit: item.limit,
        //         upstreamPath: item.upstreamPath
        //       };
  
        //         // Role: role || "",
        //         // Period: values[`roles[${i}].period`] || "",
        //         // periodUnitRole: values[`roles[${i}].periodUnit`] || "s",
        //         // PeriodTimespan: parseInt(
        //         //   values[`roles[${i}].periodTimespan`] || "0",
        //         //   10
        //         // ),
        //         // Limit: parseInt(values[`roles[${i}].limit`] || "0", 10),
        //         // UpstreamPath: UpstreamPath || "test", // ตรวจสอบค่าที่นี่
        //        //gets everything that was already in item, and updates "done"
        //     }
        //     return item; // else return unmodified item
        //   });
        //   return item;
        // });
        // if(isMoreUpstreamPath(updatedList)){
        //   setAllRoleLimit([...allRoleLimit, ...updatedList]);
        // }else{
        //   setAllRoleLimit([...updatedList]);
        // }
      }
      if(!authenOptionEnabled){
        addOrupdateData([], values.upstreamPathTemplate, "delete");
      }

      console.log("Formatted Values:", formattedValues);
      // ล้างค่า error
      setErrors({});
      setIsModalVisibleEdit(false);
      console.log("Form submitted:", routes);
    }
  }

  const addOrupdateData = (updatedList, newUpstreamPath, mode, paramsPrevList) => {
    let prevlist = allRoleLimit;
    if(paramsPrevList !== undefined){
      prevlist = paramsPrevList;
    }
    let upstreamPath = newUpstreamPath;
    if(prevUpstreamPath !== upstreamPath){
      allRoleLimit.map((item, index) => {
        if(item.upstreamPath === prevUpstreamPath){
          item.upstreamPath = upstreamPath;
          allRoleLimit[index] = item;
        }
      })

      updatedList.map((item, index) => {
        if(item.upstreamPath === prevUpstreamPath){
          item.upstreamPath = upstreamPath;
          updatedList[index] = item;
        }
      })
    }
    let tempList = [...prevlist];
    let filteredRoles = []
    if(mode === "addRoleLimit"){
      filteredRoles = tempList.filter(
        (role) => role.upstreamPath === upstreamPath && role.role === ""
      );
    }else if(mode === "delete"){
      filteredRoles = tempList.filter(
        (role) => role.upstreamPath === upstreamPath
      );
    }else{
      filteredRoles = tempList.filter(
        (role) => role.upstreamPath === upstreamPath && role.role !== ""
      );
    }
    
    let resultList = [...prevlist];
    if(filteredRoles.length === 0){
      updatedList.map((updateItem) => {
        resultList.push(updateItem);
      });
    }else if(updatedList.length === filteredRoles.length){
      // setAllRoleLimit(updatedList);
      resultList.map((item, index) => {
        updatedList.map((updateItem) => {
         if(item.id === updateItem.id){
            resultList[index] = updateItem;
          }
        });
      });
    }else if(updatedList.length > filteredRoles.length){

      resultList.map((item) => {
        updatedList.map((updateItem, index) => {
          if(!isExist(updateItem, resultList)){
            resultList.push(updateItem);
          }
        });
      });

    }else if(updatedList.length < filteredRoles.length){

      filteredRoles.map((item, fIndex) => {
        if(updatedList.length === 0){
          resultList = resultList.filter(x => {
            return x.id != item.id;
          })
        }else{
          updatedList.map((updateItem, index) => {
            if(item.id !== updateItem.id){
              var index = resultList.map(x => {
                return x.id;
              }).indexOf(item.id);
              
              resultList.splice(index, 1);
            }
          });
        }
      });

      //old version
      // filteredRoles.map((item, fIndex) => {
      //   if(updatedList.length === 0){
      //     resultList.pop(fIndex);
      //   }else{
      //     updatedList.map((updateItem, index) => {
      //       if(item.role !== updateItem.role){
      //         resultList.pop(fIndex);
      //       }
      //     });
      //   }
      // });

    }
   
    setAllRoleLimit(resultList);
    return resultList;
  }

  const isExist = (updateItem, resultList) => {
    let result = false;
    resultList.map((item) => {
      if(item.id === updateItem.id){
        result = true;
        return result;
      }
    });
    return result;
  }

  const isMoreUpstreamPath = (updatedList) => {
    let result = false;
    let prev = "";
    let temp = [...allRoleLimit, ...updatedList]
    temp.map((item) => {
      if(prev !== "" && prev !== item.upstreamPath){
        result = true;
      }
      prev = item.upstreamPath;
    });
    return result;
  };

  const handleDisplayHost = (route, index) => {
    let displayHost = "";
    let valueDisplay = "";
    if(mode !== "add"){
      displayHost = (
        <Form.Item
          name={[`host-${index}`]}
          label={
            <span>
              Host&nbsp; <span style={{ color: "red" }}>*</span>
              <Tooltip title="This is a required field">
                <InfoCircleOutlined
                  style={{ color: "#0063A7", marginLeft: 8 }}
                />
              </Tooltip>
            </span>
          }
          rules={[{ required: true, message: "Please input Host" }]}
          initialValue=""
        >
          <Input
            placeholder="Host"
            // value={route.host}
            onChange={(e) =>
              handleChange(index, "host", e.target.value)
            }
          />
        </Form.Item>
      );
    }
     else if (route.host !== "" || isChangeInput) {
      displayHost = (
        <Form.Item
          name={[`host-${index}`]}
          label={
            <span>
              Host&nbsp; <span style={{ color: "red" }}>*</span>
              <Tooltip title="This is a required field">
                <InfoCircleOutlined
                  style={{ color: "#0063A7", marginLeft: 8 }}
                />
              </Tooltip>
            </span>
          }
          rules={[{ required: true, message: "Please input Host" }]}
          initialValue={route.host}
        >
          <Input
            placeholder="Host"
            // value={route.host}
            onChange={(e) =>
              handleChange(index, "host", e.target.value)
            }
          />
        </Form.Item>
      );
    }
    return displayHost;
  }

  const handleSubmit = async (formData) => {
    try {
      // Clone and update the status from 'new' to '-'
      const updatedDataFromLog = formData.map((route) => ({
        ...route,
        status: route.status === "new" || route.status === "Changed" ? "-" : route.status,
      }));

      console.log(updatedDataFromLog);

      // Proceed with existing logic
      // const apiRoleData = await fetchRoleRateLimitData();

      // // Remove data that is no longer in apiRoleData.roles
      // const removedRoutes = routes.filter((_, index) => index === -1);
      // const updatedApiRoleData = {
      //   ...apiRoleData,
      //   roles: apiRoleData.roles.filter(
      //     (apiRole) =>
      //       !removedRoutes.some(
      //         (route) => route.upstreamPathTemplate === apiRole.upstreamPath
      //       )
      //   ),
      // };

      // Create values_role from updatedDataFromLog
      let values_role = {
        Roles: allRoleLimit
        // Roles: updatedDataFromLog
          .flatMap((role) => {
            // if (route.RoleValues && route.RoleValues.length > 0) {
              // return route.RoleValues.map((role) => {
                // return route.RoleValues.map((role) => {
                // Clean period string
                let rawPeriod = role.period || role.Period || "0s";
                const cleanPeriod = rawPeriod.replace(/[smh]/g, "");
                // if(isNaN(cleanPeriod)){
                //   rawPeriod = undefined;
                // }

                return {
                  Role: role.role || role.Role || "",
                  UpstreamPath:
                    role.upstreamPath || role.UpstreamPath || "",
                  Limit: parseInt(role.limit || role.Limit, 10) || 0,
                  Period:
                  cleanPeriod +
                    (rawPeriod.slice(-1) || "s"),
                  PeriodTimespan:
                    parseInt(role.periodTimespan || role.PeriodTimespan, 10) ||
                    0,
                };
              // });
            // }
            return [];
          })
          .filter((role) => role !== null && role !== undefined),
      };

      // const isAllRolesRemoved =
      //   values_role.Roles.length === 0 &&
      //   updatedDataFromLog.some(
      //     (route) => route.RoleValues && route.RoleValues.length === 0
      //   );

      // if (
      //   !isAllRolesRemoved &&
      //   values_role.Roles.length === 0 &&
      //   apiRoleData.roles.length > 0
      // ) {
      //   console.log("No changes detected, using existing apiRoleData");
      //   values_role = {
      //     Roles: updatedApiRoleData.roles,
      //   };
      // }

      // if (isAllRolesRemoved) {
      //   values_role = { Roles: [] };
      //   console.log("All roles removed, sending empty Roles array");
      // }

      // updatedDataFromLog.map((item, index) => {
      //   if(item.rateLimitOptions?.enableRateLimiting){
      //      // rateLimitOptions: {
      //       //   enableRateLimiting:
      //       //     route.rateLimitOptions?.enableRateLimiting,
      //       //   period: route.rateLimitOptions?.period || "0s",
      //       //   periodTimespan: parseInt(
      //       //     route.rateLimitOptions?.periodTimespan || 0
      //       //   ),
      //       //   limit: parseInt(route.rateLimitOptions?.limit || 0),
      //       // },
      //     const rawPeriod = item.period || item.Period || "0";
      //     const cleanPeriod = rawPeriod.replace(/[smh]/g, "");
      //     let roleLateLimit = {
      //       Role: "",
      //       UpstreamPath:
      //               item.upstreamPathTemplate,
      //       Limit: parseInt(item.rateLimitOptions?.limit || 0),
      //       Period: cleanPeriod +
      //       (item.periodUnit || item.periodUnitRole || "s"),
      //       PeriodTimespan:
      //         parseInt(item.rateLimitOptions?.periodTimespan || 0),
      //     };
      //     values_role.Roles = [roleLateLimit].concat(values_role.Roles)
      //   }
      // })

      // if (values_role.Roles.length > 0 || isAllRolesRemoved) {
        if (values_role.Roles.length > 0) {
        const responseRole = await ReRouteService.setroleRetaLimit(values_role);
        console.log("Role Rate Limit Success:", responseRole);
      } else {
        console.log("No data to submit for role rate limit");
      }

      // Send route data
      let values = {};
      if(authenOptionEnabled){
        values = {
          routes: updatedDataFromLog.map((route) => ({
            routeName: route.routeName || "string",
            routeType: flowEnabled ? 'Internal' : 'External',
            upstreamPathTemplate: route.upstreamPathTemplate || "string",
            upstreamHttpMethod: route.upstreamHttpMethod || ["string"],
            downstreamScheme: route.downstreamScheme || "string",
            downstreamHostAndPorts:
              route.downstreamHostAndPorts &&
              route.downstreamHostAndPorts.length > 0
                ? route.downstreamHostAndPorts.map((downstream, index) => {
                    const storedDownstreamHostAndPorts =
                      JSON.parse(
                        localStorage.getItem("downstreamHostAndPorts")
                      ) || [];
                    const storedHostAndPort =
                      storedDownstreamHostAndPorts[index] || {};
                    return {
                      host:
                        downstream.host !== undefined
                          ? downstream.host
                          : storedHostAndPort.host || "string",
                      port:
                        downstream.port !== undefined
                          ? parseInt(downstream.port)
                          : parseInt(storedHostAndPort.port) || 0,
                    };
                  })
                : JSON.parse(localStorage.getItem("downstreamHostAndPorts")) || [
                    { host: "string", port: 0 },
                  ],
            downstreamPathTemplate: route.downstreamPathTemplate || "string",
            // rateLimitOptions: {
            //   enableRateLimiting:
            //     route.rateLimitOptions?.enableRateLimiting,
            //   period: route.rateLimitOptions?.period || "0s",
            //   periodTimespan: parseInt(
            //     route.rateLimitOptions?.periodTimespan || 0
            //   ),
            //   limit: parseInt(route.rateLimitOptions?.limit || 0),
            // },
            authenticationOptions: {
              authenticationProviderKey:
                route.authenticationOptions?.authenticationProviderKey || "",
            },
            routeClaimsRequirement: {
              realm_access: route.routeClaimsRequirement?.realm_access || "",
            },
          })),
        };
      }else{
        values = {
          routes: updatedDataFromLog.map((route) => ({
            routeName: route.routeName || "string",
            routeType: flowEnabled ? 'Internal' : 'External',
            upstreamPathTemplate: route.upstreamPathTemplate || "string",
            upstreamHttpMethod: route.upstreamHttpMethod || ["string"],
            downstreamScheme: route.downstreamScheme || "string",
            downstreamHostAndPorts:
              route.downstreamHostAndPorts &&
              route.downstreamHostAndPorts.length > 0
                ? route.downstreamHostAndPorts.map((downstream, index) => {
                    const storedDownstreamHostAndPorts =
                      JSON.parse(
                        localStorage.getItem("downstreamHostAndPorts")
                      ) || [];
                    const storedHostAndPort =
                      storedDownstreamHostAndPorts[index] || {};
                    return {
                      host:
                        downstream.host !== undefined
                          ? downstream.host
                          : storedHostAndPort.host || "string",
                      port:
                        downstream.port !== undefined
                          ? parseInt(downstream.port)
                          : parseInt(storedHostAndPort.port) || 0,
                    };
                  })
                : JSON.parse(localStorage.getItem("downstreamHostAndPorts")) || [
                    { host: "string", port: 0 },
                  ],
            downstreamPathTemplate: route.downstreamPathTemplate || "string",
            // rateLimitOptions: {
            //   enableRateLimiting:
            //     route.rateLimitOptions?.enableRateLimiting,
            //   period: route.rateLimitOptions?.period || "0s",
            //   periodTimespan: parseInt(
            //     route.rateLimitOptions?.periodTimespan || 0
            //   ),
            //   limit: parseInt(route.rateLimitOptions?.limit || 0),
            // },
            // authenticationOptions: {
            //   authenticationProviderKey:
            //     route.authenticationOptions?.authenticationProviderKey || "",
            // },
            // routeClaimsRequirement: {
            //   realm_access: route.routeClaimsRequirement?.realm_access || "",
            // },
          })),
        };
      }

      const response = await ReRouteService.setRoute(values);
      console.log("Success:", response);
      sessionStorage.removeItem("isChanged")
      setUpdateRoleLimit(updateRoleLimit+1);

      // Update the state or local storage if needed
      setFormData(updatedDataFromLog);

      setTimeout(() => setIsModalSuccessOpen(true), 300);
      setTimeout(() => setIsModalSuccessOpen(false), 1300);
    } catch (error) {
      setIsModalFailedOpen(true);
      console.error("Error:", error);
    }
  };

  const fetchRoleRateLimitData = async () => {
    try {
      // เรียกใช้ getroleRetaLimit เพื่อดึงข้อมูล
      const data = await ReRouteService.getroleRetaLimit();

      // ตรวจสอบและส่งคืนข้อมูลที่ได้รับมา
      return data;
    } catch (error) {
      console.error("Error fetching role rate limit data:", error);
      return []; // ส่งคืน array ว่างในกรณีเกิดข้อผิดพลาด
    }
  };

  return (
    <>
      <div className="body-template">
        <Col>
          <Row>
            <NavOcelot width={1144} />
          </Row>
          <Row>
            <Card style={{ width: "1144px" }}>
              <h1>Routes</h1>
              <div className="button-group">
                <div className="text-container">
                  <h4>
                    Lorem ipsum dolor sit amet consectetur adipiscing elit.
                    Mattis sit <br /> Phasellus mollis sit aliquam sit nullam
                    neque ultrices.
                  </h4>
                </div>
                <Button
                  className="add-route-button"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={handleAddNewRoutes}
                >
                  Add new routes
                </Button>
              </div>
              <br />
              <Table columns={columns} dataSource={formData} />
            </Card>
          </Row>
        </Col>

        <Modal
          title={
            <span
              style={{ color: "#0063A7", fontSize: "18px", fontWeight: "bold" }}
            >
              Add new route
            </span>
          }
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          style={{ top: 20 }} // ปรับตำแหน่ง Modal
          bodyStyle={{ padding: "3px" }} // ปรับพื้นที่ภายในของ Modal
          width={1000} // ปรับขนาดความกว้างของ Modal
        >
          <hr />
          <br />
          <Form
            form={form} // เชื่อมต่อกับฟอร์ม
            onFinish={onFinish} // จัดการการส่งฟอร์ม
            layout="vertical" // การจัดเลย์เอาต์ของฟอร์ม
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  className="custom-form-item"
                  label={
                    <span>
                      Route Name&nbsp; <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="routeName"
                  rules={[
                    { required: true, message: "Please input route name!" },
                  ]}
                  required={false}
                >
                  <Input placeholder="Route name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Upstream Path&nbsp;{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip title="This is a required field">
                        <InfoCircleOutlined
                          style={{ color: "#0063A7", marginLeft: 8 }}
                        />
                      </Tooltip>
                    </span>
                  }
                  name="upstreamPathTemplate"
                  rules={[
                    { required: true, message: "Please input upstream path!" },
                    {
                      pattern: /^\/[a-zA-Z0-9/_-]+$/,
                      message: "Please enter a valid path!",
                    },
                  ]}
                  required={false}
                >
                  <Input placeholder="/xxxx/xxx/xxxxxx" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Upstream Http Method&nbsp;{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip title="This is a required field">
                        <InfoCircleOutlined
                          style={{ color: "#0063A7", marginLeft: 8 }}
                        />
                      </Tooltip>
                    </span>
                  }
                  name="upstreamHttpMethod"
                  rules={[
                    { required: true, message: "Please select HTTP method!" },
                  ]}
                  required={false}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select HTTP Method"
                    value={selectedHttpMethods}
                    onChange={handleHttpMethodChange}
                    allowClear
                  >
                    <Option value="GET">GET</Option>
                    <Option value="POST">POST</Option>
                    <Option value="PUT">PUT</Option>
                    <Option value="DELETE">DELETE</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Downstream Scheme&nbsp;{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip title="This is a required field">
                        <InfoCircleOutlined
                          style={{ color: "#0063A7", marginLeft: 8 }}
                        />
                      </Tooltip>
                    </span>
                  }
                  name="downstreamScheme"
                  rules={[{ required: true, message: "Please select scheme!" }]}
                  required={false}
                >
                  <Select placeholder="Select Scheme" className="custom-select">
                    <Option value="http">HTTP</Option>
                    <Option value="https">HTTPS</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <div className="Downstream" style={{ padding: "10px" }}>
              <div className="button-group">
                <span
                  style={{
                    color: "#0063A7",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  Downstream Host and Path
                </span>
                <Button
                  className="add-route-button"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={handleAddNewHostandPath}
                >
                  Add
                </Button>
              </div>
              {routes.map((route, index) => {
                return (
                  <Row key={index} gutter={16} style={{ marginTop: "10px" }}>
                    <Col span={11} style={{ paddingBottom: "10px" }}>
                      <Form.Item
                        label={
                          <span>
                            Host&nbsp; <span style={{ color: "red" }}>*</span>
                            <Tooltip title="This is a required field">
                              <InfoCircleOutlined
                                style={{ color: "#0063A7", marginLeft: 8 }}
                              />
                            </Tooltip>
                          </span>
                        }
                        name={`host-${index}`}
                        rules={[
                          { required: true, message: "Please select host!" },
                        ]}
                        required={false}
                        initialValue=""
                      >
                        <Input
                          placeholder="Host"
                          className="custom-placeholder"
                          onChange={(e) =>
                            handleChange(index, "host", e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col span={11} style={{ paddingBottom: "10px" }}>
                      <Form.Item
                        label={
                          <span>
                            Port&nbsp; <span style={{ color: "red" }}>*</span>
                            <Tooltip title="This is a required field">
                              <InfoCircleOutlined
                                style={{ color: "#0063A7", marginLeft: 8 }}
                              />
                            </Tooltip>
                          </span>
                        }
                        name={`port-${index}`}
                        rules={[
                          { required: true, message: "Please select port!" },
                          {
                            pattern: /^[0-9]+$/,
                            message: "Please enter a valid port number!",
                          },
                        ]}
                        required={false}
                        initialValue={route.port}
                      >
                        <Input
                          placeholder="Port"
                          className="custom-placeholder"
                          value=""
                          onChange={(e) =>
                            handleChange(index, "port", e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>
                    {routes.length > 1 && (
                      <Col
                        span={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingBottom: "10px",
                        }}
                      >
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => handleRemoveRoute(index)}
                          style={{
                            border: "1px solid #0063A7",
                            color: "#0063A7",
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                );
              })}
            </div>

            <br />
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Downstream Path&nbsp;{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip title="This is a required field">
                        <InfoCircleOutlined
                          style={{ color: "#0063A7", marginLeft: 8 }}
                        />
                      </Tooltip>
                    </span>
                  }
                  name="downstreamPathTemplate"
                  rules={[
                    { required: true, message: "Please input Downstream Path" },
                    {
                      pattern: /^\/[a-zA-Z0-9/_-]+$/,
                      message: "Please enter a valid path!",
                    },
                  ]}
                  required={false}
                >
                  <Input placeholder="Downstream Path" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Flow" name="flow" valuePropName="checked">
                  <Switch
                    checked={flowEnabled}
                    onChange={handleSwitchChangeFLowTrueFlase}
                    checkedChildren="Internal"
                    unCheckedChildren="External"
                  />
                </Form.Item>
              </Col>
            </Row>
            <hr />
            <br />
            <span
              style={{
                color: "#0063A7",
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Rate Limit Option
            </span>
            <Row gutter={16}>
              <Col span={5}>
                <Form.Item
                  label="Enable Rate Limiting"
                  name="rateLimitingEnabled"
                  valuePropName="checked"
                >
                  <Switch
                    checked={rateLimitingEnabled}
                    onChange={handleSwitchChangeTrueFlase}
                    checkedChildren="true"
                    unCheckedChildren="false"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={
                    <span>
                      Period&nbsp; <span style={{ color: "red" }}>*</span>
                      <Tooltip title="This is a required field">
                        <InfoCircleOutlined
                          style={{ color: "#0063A7", marginLeft: 8 }}
                        />
                      </Tooltip>
                    </span>
                  }
                  name="period"
                  rules={[
                    {
                      required: rateLimitingEnabled,
                      message: "Please input period",
                    },
                    // {
                    //   pattern: /^[0-9]+$/,
                    //   message: "Please enter a valid port number!",
                    // },
                    rulePeriod,
                  ]}
                  required={false}
                >
                  <Input
                    placeholder="0"
                    addonAfter={
                      <Form.Item name="periodUnit" noStyle>
                        <Select
                          id="periodUnit"
                          defaultValue="s"
                          style={{ width: 110 }}
                          onChange={(value) => {
                            handleChangePeriodUnit(value, "rate");
                            form.setFieldsValue({
                              [`period`]: "",
                            });
                          }}
                        >
                          <Option value="s">S (Second)</Option>
                          <Option value="m">M (Minute)</Option>
                          <Option value="h">H (Hour)</Option>
                        </Select>
                      </Form.Item>
                    }
                    disabled={!rateLimitingEnabled}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <span>
                      Period Timespan&nbsp;{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip title="This is a required field">
                        <InfoCircleOutlined
                          style={{ color: "#0063A7", marginLeft: 8 }}
                        />
                      </Tooltip>
                    </span>
                  }
                  name="periodTimespan"
                  required={false}
                  rules={[
                    {
                      required: rateLimitingEnabled,
                      message: "Please input periodTimespan",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Please enter a valid port number!",
                    },
                    rulePeriodTimeSpan,
                  ]}
                >
                  <Input
                    addonAfter={
                      <div style={{ width: 70, textAlign: "center" }}>S</div>
                    }
                    placeholder="0"
                    disabled={!rateLimitingEnabled}
                    onChange={(value) => {
                      //setValue(value);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label={
                    <span>
                      Limit&nbsp; <span style={{ color: "red" }}>*</span>
                      <Tooltip title="This is a required field">
                        <InfoCircleOutlined
                          style={{ color: "#0063A7", marginLeft: 8 }}
                        />
                      </Tooltip>
                    </span>
                  }
                  name="limit"
                  required={false}
                  rules={[
                    {
                      required: rateLimitingEnabled,
                      message: "Please input limit",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Please enter a valid port number!",
                    },
                  ]}
                >
                  <Input placeholder="0" disabled={!rateLimitingEnabled} />
                </Form.Item>
              </Col>
            </Row>

            <hr />
            <br />
            <span
              style={{
                color: "#000000",
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Authentication Option&nbsp;{" "}
              <span style={{ color: "red" }}>*</span>
            </span>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label="Enable Authen Option"
                  name="authenOptionEnabled"
                  valuePropName="checked"
                >
                  <Switch
                    checked={authenOptionEnabled}
                    onChange={handleSwitchChangeAuthenOPtionTrueFlase}
                    checkedChildren="true"
                    unCheckedChildren="false"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="authenticationProviderKey">
                  <Select
                    placeholder="Select"
                    className="custom-select"
                    onChange={(value) =>
                      handleAuthenticationProviderChange(value)
                    }
                    disabled={!authenOptionEnabled}
                  >
                    {OptionsAuthentication.map((option) => (
                      <Option key={option.key} value={option.key}>
                        {option.key}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item name="realmAccess">
                  <Select
                    mode="multiple"
                    placeholder="Select"
                    className="custom-select"
                    disabled={!authenOptionEnabled}
                  >
                    {options.length > 0 ? (
                      options.map((option, index) => (
                        <Option key={index} value={option.name}>
                          {option.name}
                        </Option>
                      ))
                    ) : (
                      <Option value="" disabled>
                        No options available
                      </Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {authenOptionEnabled ? (
              <div
                className="Downstream2"
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="button-group" style={{ marginBottom: "10px" }}>
                  <span
                    style={{
                      color: "#000000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Role
                    <Tooltip title="This is a required field">
                      <InfoCircleOutlined
                        style={{ color: "#0063A7", marginLeft: 8 }}
                      />
                    </Tooltip>
                  </span>
                  <Button
                    className="add-route-button"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleAddNewRoutes2}
                  >
                    Add role
                  </Button>
                </div>
                {roles.map((role, index) => (
                  <>
                    <div className="Downstream">
                      <div className="positionbutton">
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => handleRemoveRole(index + 1)}
                          style={{
                            border: "1px solid #0063A7",
                            color: "#0063A7",
                            marginTop: 16,
                          }}
                        />
                      </div>
                      <Row gutter={16} style={{ flex: 1, marginLeft: 16 }}>
                        <Col
                          span={12}
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: 16,
                            }}
                          >
                            <Form.Item
                              className="custom-form-item"
                              label={<span>Role</span>}
                              name={`Role-${index}`}
                              style={{ flex: 1, marginRight: 16 }}
                              required={true}
                              initialValue={role.roleName}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input Role",
                                },
                              ]}
                            >
                              <Input placeholder="Role" />
                            </Form.Item>
                            <Form.Item
                              label="Advance"
                              style={{ flex: "none", marginBottom: "16px" }}
                            >
                              <Switch
                                checked={activeRoles[index] || false} // กำหนดค่า checked จาก activeRoles
                                onChange={() => handleSwitchChange(index)}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                      </Row>
                      {activeRoles[index] && (
                        <Row gutter={16} style={{ marginLeft: 16 }}>
                          <Col span={6}>
                            <Form.Item
                              label={
                                <span>
                                  Period&nbsp;{" "}
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip title="This is a required field">
                                    <InfoCircleOutlined
                                      style={{
                                        color: "#0063A7",
                                        marginLeft: 8,
                                      }}
                                    />
                                  </Tooltip>
                                </span>
                              }
                              required={false}
                              name={`period-${index}`}
                              // initialValue={role.period}
                              // rules={[{ required: true, message: 'Please select Period' }]}
                            >
                              <Input.Group compact>
                                <Form.Item
                                  name={`period-${index}`}
                                  // initialValue="0"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input period",
                                    },
                                    {
                                      pattern: /^[0-9]+$/,
                                      message:
                                        "Please enter a valid port number!",
                                    },
                                    ({ getFieldValue }) => ({
                                      validator(rule, value) {
                                        if (!isNaN(value)) {
                                          let element =
                                            document.getElementById(
                                              "periodUnit"
                                            );
                                          // let test = getFieldValue("periodUnit")
                                          let rawValue =
                                            getFieldValue("period");
                                          let number = parseInt(value);
                                          let currentPeriodUnit =
                                            roles[index].periodUnit;
                                          return handleDisplayValidatorMessage(
                                            number,
                                            currentPeriodUnit
                                          );
                                        }

                                        return Promise.resolve();
                                      },
                                    }),
                                  ]}
                                  noStyle
                                >
                                  <Input
                                    // style={{ width: "calc(100% - 110px)" }}
                                    placeholder="0"
                                    addonAfter={
                                      <Form.Item
                                        name={`period-unit-${index}`}
                                        initialValue={role.periodUnit}
                                        noStyle
                                      >
                                        <Select
                                          defaultValue="s"
                                          style={{ width: 110 }}
                                          onChange={(value) => {
                                            handleChangePeriodUnit(
                                              value,
                                              "addRole",
                                              index
                                            );
                                            form.setFieldsValue({
                                              [`period-${index}`]: "",
                                            });
                                          }}
                                        >
                                          <Option value="s">S (Second)</Option>
                                          <Option value="m">M (Minute)</Option>
                                          <Option value="h">H (Hour)</Option>
                                        </Select>
                                      </Form.Item>
                                    }
                                  />
                                </Form.Item>
                              </Input.Group>
                            </Form.Item>
                          </Col>
                          <Col span={8}>
                            <Form.Item
                              label={
                                <span>
                                  Period Timespan&nbsp;{" "}
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip title="This is a required field">
                                    <InfoCircleOutlined
                                      style={{
                                        color: "#0063A7",
                                        marginLeft: 8,
                                      }}
                                    />
                                  </Tooltip>
                                </span>
                              }
                              name={`period-time-${index}`}
                              required={false}
                              initialValue={role.periodTimespan}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select periodTimespan",
                                },
                                {
                                  pattern: /^[0-9]+$/,
                                  message: "Please enter a valid port number!",
                                },
                                rulePeriodTimeSpan,
                              ]}
                            >
                              <Input
                                addonAfter={
                                  <div
                                    style={{ width: 70, textAlign: "center" }}
                                  >
                                    S
                                  </div>
                                }
                                placeholder="0"
                              />
                            </Form.Item>
                          </Col>
                          <Col span={5}>
                            <Form.Item
                              label={
                                <span>
                                  Limit&nbsp;{" "}
                                  <span style={{ color: "red" }}>*</span>
                                  <Tooltip title="This is a required field">
                                    <InfoCircleOutlined
                                      style={{
                                        color: "#0063A7",
                                        marginLeft: 8,
                                      }}
                                    />
                                  </Tooltip>
                                </span>
                              }
                              name={`period-limit-${index}`}
                              required={false}
                              initialValue={role.limit}
                              rules={[
                                {
                                  required: true,
                                  message: "Please select Limit",
                                },
                                {
                                  pattern: /^[0-9]+$/,
                                  message: "Please enter a valid port number!",
                                },
                              ]}
                            >
                              <Input placeholder="0" />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                    </div>

                    {index < roles.length - 1 && <br />}
                  </>
                ))}
              </div>
            ) : (
              ""
            )}

            <br />
            <div style={{ textAlign: "right" }}>
              <Button onClick={handleCancel} style={{ marginRight: "8px" }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" onClick={onFinish}>
                Submit
              </Button>
            </div>
          </Form>
        </Modal>

        <Modal
          title={
            <span
              style={{ color: "#0063A7", fontSize: "18px", fontWeight: "bold" }}
            >
              Edit route
            </span>
          }
          open={isModalVisibleEdit}
          onCancel={handleCancel}
          footer={null}
          style={{ top: 20 }} // ปรับตำแหน่ง Modal
          bodyStyle={{ padding: "3px" }} // ปรับพื้นที่ภายในของ Modal
          width={1000} // ปรับขนาดความกว้างของ Modal
        >
          <Form
            form={form} // เชื่อมต่อกับฟอร์ม
            onFinish={onFinish2} // จัดการการส่งฟอร์ม
            layout="vertical" // การจัดเลย์เอาต์ของฟอร์ม
          >
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  className="custom-form-item"
                  label={
                    <span>
                      Route Name&nbsp; <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="routeName"
                  rules={[
                    { required: true, message: "Please input route name!" },
                  ]}
                  required={false}
                >
                  <Input placeholder="Route name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Upstream Path&nbsp;{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip title="This is a required field">
                        <InfoCircleOutlined
                          style={{ color: "#0063A7", marginLeft: 8 }}
                        />
                      </Tooltip>
                    </span>
                  }
                  name="upstreamPathTemplate"
                  rules={[
                    { required: true, message: "Please input upstream path!" },
                    {
                      pattern: /^\/[a-zA-Z0-9/_-]+$/,
                      message: "Please enter a valid path!",
                    },
                  ]}
                  required={false}
                >
                  <Input placeholder="/xxxx/xxx/xxxxxx" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Upstream Http Method&nbsp;{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip title="This is a required field">
                        <InfoCircleOutlined
                          style={{ color: "#0063A7", marginLeft: 8 }}
                        />
                      </Tooltip>
                    </span>
                  }
                  name="upstreamHttpMethod"
                  rules={[
                    { required: true, message: "Please select HTTP method!" },
                  ]}
                  required={false}
                >
                  <Select
                    mode="multiple"
                    placeholder="Select HTTP Method"
                    value={selectedHttpMethods}
                    onChange={handleHttpMethodChange}
                    allowClear
                  >
                    <Option value="GET">GET</Option>
                    <Option value="POST">POST</Option>
                    <Option value="PUT">PUT</Option>
                    <Option value="DELETE">DELETE</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Downstream Scheme&nbsp;{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip title="This is a required field">
                        <InfoCircleOutlined
                          style={{ color: "#0063A7", marginLeft: 8 }}
                        />
                      </Tooltip>
                    </span>
                  }
                  name="downstreamScheme"
                  rules={[{ required: true, message: "Please select scheme!" }]}
                  required={false}
                >
                  <Select placeholder="Select Scheme" className="custom-select">
                    <Option value="http">HTTP</Option>
                    <Option value="https">HTTPS</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <div className="Downstream" style={{ padding: "10px" }}>
              <div className="button-group">
                <span
                  style={{
                    color: "#0063A7",
                    fontSize: "18px",
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  Downstream Host and Path
                </span>
                <Button
                  className="add-route-button"
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={handleAddNewHostandPath}
                >
                  Add
                </Button>
              </div>

              {routes.map((route, index) => {
                let displayHost = "";
                let isFirstHost = route.isFirstHost;
                if (route.isFirstHost === undefined) {
                  isFirstHost = false;
                }
                if (route.host !== "" || isChangeInput || isFirstHost) {
                  displayHost = (
                    <Form.Item
                      name={[`host-${index}`]}
                      label={
                        <span>
                          Host&nbsp;
                          {/* <span style={{ color: "red" }}>*</span> */}
                          <Tooltip title="This is a required field">
                            <InfoCircleOutlined
                              style={{ color: "#0063A7", marginLeft: 8 }}
                            />
                          </Tooltip>
                        </span>
                      }
                      rules={[{ required: true, message: "Please input Host" }]}
                      initialValue={route.host}
                    >
                      <Input
                        placeholder="Host"
                        // value={route.host}
                        onChange={(e) =>
                          handleChange(index, "host", e.target.value)
                        }
                      />
                    </Form.Item>
                  );
                }

                let displayPort = "";
                let isFirstPort = route.isFirstPort;
                if (route.isFirstPort === undefined) {
                  isFirstPort = false;
                }
                if (route.port !== "" || isChangeInput || isFirstPort) {
                  displayPort = (
                    <Form.Item
                      name={[`port-${index}`]}
                      label={
                        <span>
                          Port&nbsp;
                          {/* <span style={{ color: "red" }}>*</span> */}
                          <Tooltip title="This is a required field">
                            <InfoCircleOutlined
                              style={{ color: "#0063A7", marginLeft: 8 }}
                            />
                          </Tooltip>
                        </span>
                      }
                      rules={[{ required: true, message: "Please input Port" }]}
                      initialValue={route.port}
                    >
                      <Input
                        placeholder="Port"
                        // value={route.host}
                        onChange={(e) =>
                          handleChange(index, "port", e.target.value)
                        }
                      />
                    </Form.Item>
                  );
                }
                return (
                  <Row key={index} gutter={16} style={{ marginTop: "10px" }}>
                    <Col span={11} style={{ paddingBottom: "10px" }}>
                      {displayHost}
                      {/* <div className="form-item-wrapper">
                      <label
                        rules={[
                          { required: true, message: "Please select host!" },
                        ]}
                      >
                        <span>
                          Host&nbsp; <span style={{ color: "red" }}>*</span>
                          <Tooltip title="This is a required field">
                            <InfoCircleOutlined
                              style={{ color: "#0063A7", marginLeft: 8 }}
                            />
                          </Tooltip>
                        </span>
                      </label>
                      <Input
                        placeholder="Host"
                        value={route.host || ""}
                        className={errors[`host-${index}`] ? "input-error" : ""}
                        onChange={(e) =>
                          handleChange(index, "host", e.target.value)
                        }
                      />
                      {errors[`host-${index}`] && (
                        <div style={{ color: "red" }}>
                          {errors[`host-${index}`]}
                        </div>
                      )}
                    </div> */}
                    </Col>
                    <Col span={11} style={{ paddingBottom: "10px" }}>
                      {displayPort}
                      {/* <div className="form-item-wrapper">
                      <label>
                        <span>
                          Port&nbsp; <span style={{ color: "red" }}>*</span>
                          <Tooltip title="This is a required field">
                            <InfoCircleOutlined
                              style={{ color: "#0063A7", marginLeft: 8 }}
                            />
                          </Tooltip>
                        </span>
                      </label>
                      <Input
                        placeholder="Ex: 888"
                        value={route.port}
                        onChange={(e) =>
                          handleChange(index, "port", e.target.value)
                        }
                        className={errors[`port-${index}`] ? "input-error" : ""}
                      />
                      {errors[`port-${index}`] && (
                        <div style={{ color: "red" }}>
                          {errors[`port-${index}`]}
                        </div>
                      )}
                    </div> */}
                    </Col>
                    {routes.length > 1 && (
                      <Col
                        span={2}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingBottom: "10px",
                        }}
                      >
                        <Button
                          icon={<DeleteOutlined />}
                          onClick={() => handleRemoveRoute(index)}
                          style={{
                            border: "1px solid #0063A7",
                            color: "#0063A7",
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                );
              })}
            </div>

            <br />
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Downstream Path&nbsp;{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip title="This is a required field">
                        <InfoCircleOutlined
                          style={{ color: "#0063A7", marginLeft: 8 }}
                        />
                      </Tooltip>
                    </span>
                  }
                  name="downstreamPathTemplate"
                  rules={[
                    { required: true, message: "Please input Downstream Path" },
                    {
                      pattern: /^\/[a-zA-Z0-9/_-]+$/,
                      message: "Please enter a valid path!",
                    },
                  ]}
                  required={false}
                >
                  <Input placeholder="Downstream Path" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Flow" name="flow" valuePropName="checked">
                  <Switch
                    checked={flowEnabled}
                    onChange={handleSwitchChangeFLowTrueFlase}
                    checkedChildren="Internal"
                    unCheckedChildren="External"
                  />
                </Form.Item>
              </Col>
            </Row>
            <hr />
            <br />

            <span
              style={{
                color: "#0063A7",
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Rate Limit Option
            </span>
            <Row gutter={16}>
              <Col span={5}>
                <Form.Item
                  label="Enable Rate Limiting"
                  name="rateLimitingEnabled"
                  valuePropName="checked"
                >
                  <Switch
                    checked={rateLimitingEnabled}
                    onChange={handleSwitchChangeTrueFlase}
                    checkedChildren="true"
                    unCheckedChildren="false"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  label={
                    <span>
                      Period&nbsp; <span style={{ color: "red" }}>*</span>
                      <Tooltip title="This is a required field">
                        <InfoCircleOutlined
                          style={{ color: "#0063A7", marginLeft: 8 }}
                        />
                      </Tooltip>
                    </span>
                  }
                  name="period"
                  rules={[
                    {
                      required: rateLimitingEnabled,
                      message: "Please input period",
                    },
                    rulePeriod,
                  ]}
                  required={false}
                >
                  <Input
                    placeholder="0"
                    addonAfter={
                      <Form.Item name="periodUnit" noStyle initialValue="s">
                        <Select
                          defaultValue="s"
                          style={{ width: 110 }}
                          onChange={(value) => {
                            handleChangePeriodUnit(value, "rate");
                            form.setFieldsValue({
                              [`period`]: "",
                            });
                          }}
                        >
                          <Option value="s">S (Second)</Option>
                          <Option value="m">M (Minute)</Option>
                          <Option value="h">H (Hour)</Option>
                        </Select>
                      </Form.Item>
                    }
                    disabled={!rateLimitingEnabled}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={
                    <span>
                      Period Timespan&nbsp;{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip title="This is a required field">
                        <InfoCircleOutlined
                          style={{ color: "#0063A7", marginLeft: 8 }}
                        />
                      </Tooltip>
                    </span>
                  }
                  name="periodTimespan"
                  required={false}
                  rules={[
                    {
                      required: rateLimitingEnabled,
                      message: "Please input periodTimespan",
                    },
                    {
                      pattern: /^[0-9]+$/,
                      message: "Please enter a valid port number!",
                    },
                    rulePeriodTimeSpan,
                  ]}
                >
                  <Input
                    addonAfter={
                      <div style={{ width: 70, textAlign: "center" }}>S</div>
                    }
                    placeholder="0"
                    disabled={!rateLimitingEnabled}
                  />
                </Form.Item>
              </Col>
              <Col span={5}>
                <Form.Item
                  label={
                    <span>
                      Limit&nbsp; <span style={{ color: "red" }}>*</span>
                      <Tooltip title="This is a required field">
                        <InfoCircleOutlined
                          style={{ color: "#0063A7", marginLeft: 8 }}
                        />
                      </Tooltip>
                    </span>
                  }
                  name="limit"
                  required={false}
                >
                  <Input placeholder="0" disabled={!rateLimitingEnabled} />
                </Form.Item>
              </Col>
            </Row>

            <hr />
            <br />
            <span
              style={{
                color: "#000000",
                fontSize: "18px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Authentication Option&nbsp;{" "}
              <span style={{ color: "red" }}>*</span>
            </span>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label="Enable Authen Option"
                  name="authenOptionEnabled"
                  valuePropName="checked"
                >
                  <Switch
                    checked={authenOptionEnabled}
                    onChange={handleSwitchChangeAuthenOPtionTrueFlase}
                    checkedChildren="true"
                    unCheckedChildren="false"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="authenticationProviderKey">
                  <Select
                    placeholder="Select"
                    className="custom-select"
                    onChange={(value) =>
                      handleAuthenticationProviderChange(value)
                    }
                    disabled={!authenOptionEnabled}
                  >
                    {OptionsAuthentication.map((option) => (
                      <Option key={option.key} value={option.key}>
                        {option.key}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item name="realmAccess">
                  <Select
                    mode="multiple"
                    placeholder="Select"
                    className="custom-select"
                    disabled={!authenOptionEnabled}
                  >
                    {options.length > 0 ? (
                      options.map((option, index) => (
                        <Option key={index} value={option.name}>
                          {option.name}
                        </Option>
                      ))
                    ) : (
                      <Option value="" disabled>
                        No options available
                      </Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {authenOptionEnabled ? (
              <div
                className="Downstream2"
                style={{
                  padding: "10px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="button-group" style={{ marginBottom: "20px" }}>
                  <span
                    style={{
                      color: "#000000",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    Role
                    <Tooltip title="This is a required field">
                      <InfoCircleOutlined
                        style={{ color: "#0063A7", marginLeft: 8 }}
                      />
                    </Tooltip>
                  </span>
                  <Button
                    className="add-route-button"
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={handleAddNewRoutesEdit}
                  >
                    Add role
                  </Button>
                </div>

                  {updatedRecord.RoleValues.map((roleObj, index) => {
                    let checkAdvance = false;
                    if (roleObj.advance !== undefined) {
                      checkAdvance = roleObj.advance;
                    } else if (roleObj.limit !== 0) {
                      checkAdvance = true;
                    }
                    let displayPeriod = "";
                    if (
                      (roleObj.period !== "" || isChangeInput) &&
                      checkAdvance
                    ) {
                      displayPeriod = (
                        <div className="input-group">
                          <Form.Item
                            label={
                              <span>
                                Period&nbsp;{" "}
                                <span style={{ color: "red" }}>*</span>
                                <Tooltip title="This is a required field">
                                  <InfoCircleOutlined
                                    style={{
                                      color: "#0063A7",
                                      marginLeft: 8,
                                    }}
                                  />
                                </Tooltip>
                              </span>
                            }
                            required={false}
                            name={[`period-${index}`]}
                            style={{ marginLeft: "0px", marginBottom: "0" }}
                            rules={[
                              {
                                required: true,
                                message: "Please input Period",
                              },
                              {
                                pattern: /^[0-9]+$/,
                                message: "Please enter a valid period number!",
                              },
                              ({ getFieldValue }) => ({
                                validator(rule, value) {
                                  if (!isNaN(value)) {
                                    let element =
                                      document.getElementById("periodUnit");
                                    // let test = getFieldValue("periodUnit")
                                    let rawValue = getFieldValue("period");
                                    let number = parseInt(value);
                                    let currentPeriodUnit =
                                      updatedRecord.RoleValues[index]
                                        .periodUnit;
                                    return handleDisplayValidatorMessage(
                                      number,
                                      currentPeriodUnit
                                    );
                                  }

                                  return Promise.resolve();
                                },
                              }),
                            ]}
                            initialValue={
                              roleObj.period
                                ? roleObj.period.replace(/[a-zA-Z]/g, "")
                                : ""
                            }
                          >
                            <Input
                              value={roleObj.period.replace(/[a-zA-Z]/g, "")}
                              placeholder="0"
                              className="custom-placeholder"
                              onChange={(e) =>
                                handleChangeRoute(
                                  index,
                                  "period",
                                  e.target.value
                                )
                              }
                              // style={{
                              //   width: "calc(100% - 120px)",
                              // }}
                              addonAfter={
                                <Form.Item
                                  name={[`period-unit-${index}`]}
                                  initialValue={roleObj.periodUnit}
                                  noStyle
                                >
                                  <Select
                                    defaultValue="s"
                                    style={{ width: 110 }}
                                    onChange={(value) => {
                                      handleChangePeriodUnit(
                                        value,
                                        "editRole",
                                        index
                                      );
                                      form.setFieldsValue({
                                        [`period-${index}`]: "",
                                      });
                                    }}
                                  >
                                    <Option value="s">S (Second)</Option>
                                    <Option value="m">M (Minute)</Option>
                                    <Option value="h">H (Hour)</Option>
                                  </Select>
                                </Form.Item>
                              }
                            />
                            {/* <Select
                        value={roleObj.periodUnit || "s"}
                        onChange={(value) =>
                          handleChangeRoute(index, "periodUnit", value)
                        }
                        style={{ width: 110 }}
                      >
                        <Option value="s">S (Second)</Option>
                        <Option value="m">M (Minute)</Option>
                        <Option value="h">H (Hour)</Option>
                      </Select> */}
                          </Form.Item>
                        </div>
                      );
                    }
                    let displayPeriodTimespan = "";
                    if (
                      (roleObj.periodTimespan !== "" || isChangeInput) &&
                      checkAdvance
                    ) {
                      displayPeriodTimespan = (
                        <Form.Item
                          label={
                            <span>
                              Period Timespan&nbsp;{" "}
                              <span style={{ color: "red" }}>*</span>
                              <Tooltip title="This is a required field">
                                <InfoCircleOutlined
                                  style={{ color: "#0063A7", marginLeft: 8 }}
                                />
                              </Tooltip>
                            </span>
                          }
                          required={false}
                          name={[`period-time-${index}`]}
                          style={{ marginLeft: "0px", marginBottom: "0" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Period Timespan",
                            },
                            {
                              pattern: /^[0-9]+$/,
                              message:
                                "Please enter a valid period timespan number!",
                            },
                            rulePeriodTimeSpan,
                          ]}
                          initialValue={
                            roleObj.period ? roleObj.periodTimespan : ""
                          }
                        >
                          <div className="input-group">
                            <Input
                              addonAfter={
                                <div style={{ width: 70, textAlign: "center" }}>
                                  S
                                </div>
                              }
                              placeholder="0"
                              value={roleObj.periodTimespan || ""}
                              onChange={(e) =>
                                handleChangeRoute(
                                  index,
                                  "periodTimespan",
                                  e.target.value
                                )
                              }
                              style={{
                                borderColor: errors[`periodTimespan-${index}`]
                                  ? "red"
                                  : "",
                              }}
                            />
                          </div>
                        </Form.Item>
                      );
                    }
                    let displayPeriodLimit = "";
                    if (
                      (roleObj.limit !== "" || isChangeInput) &&
                      checkAdvance
                    ) {
                      displayPeriodLimit = (
                        <Form.Item
                          label={
                            <span>
                              Limit&nbsp;{" "}
                              <span style={{ color: "red" }}>*</span>
                              <Tooltip title="This is a required field">
                                <InfoCircleOutlined
                                  style={{ color: "#0063A7", marginLeft: 8 }}
                                />
                              </Tooltip>
                            </span>
                          }
                          required={false}
                          name={[`period-limit-${index}`]}
                          style={{ marginLeft: "0px", marginBottom: "0" }}
                          rules={[
                            {
                              required: true,
                              message: "Please input Limit",
                            },
                            {
                              pattern: /^[0-9]+$/,
                              message: "Please enter a valid limit number!",
                            },
                          ]}
                          initialValue={roleObj.period ? roleObj.limit : ""}
                        >
                          <div className="input-group">
                            <Input
                              placeholder="0"
                              value={roleObj.limit || ""}
                              onChange={(e) =>
                                handleChangeRoute(
                                  index,
                                  "limit",
                                  e.target.value
                                )
                              }
                              style={{
                                borderColor: errors[`limit-${index}`]
                                  ? "red"
                                  : "",
                              }}
                            />
                          </div>
                        </Form.Item>
                      );
                    }
                    return (
                      <div className="DownstreamEdit">
                        <div
                          key={index}
                          className="role-container"
                          style={{ marginBottom: "30px" }}
                        >
                          <div
                            className="positionbutton"
                            style={{ marginBottom: "10px" }}
                          >
                            <Button
                              icon={<DeleteOutlined />}
                              onClick={() => handleRemoveRole2(index)}
                              style={{
                                border: "1px solid #0063A7",
                                color: "#0063A7",
                                marginTop: 16,
                              }}
                            />
                          </div>

                          <Row gutter={16} style={{ marginBottom: "20px" }}>
                            <Col
                              span={12}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: "20px",
                                }}
                              >
                                {/* <label style={{ marginBottom: "8px" }}>
                  <span>
                    Role&nbsp; <span style={{ color: "red" }}>*</span>
                    <Tooltip title="This is a required field">
                      <InfoCircleOutlined
                        style={{ color: "#0063A7", marginLeft: 8 }}
                      />
                    </Tooltip>
                  </span>
                </label> */}
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {/* <Input
                    placeholder="Role"
                    value={roleObj.role || ""}
                    className="custom-placeholder"
                    onChange={(e) =>
                      handleChangeRoute(index, "role", e.target.value)
                    }
                    style={{
                      borderColor: errors[`role-${index}`]
                        ? "red"
                        : "",
                      flex: 1,
                    }}
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: "Please input Role",
                      },
                    ]}
                  /> */}

                                  <Form.Item
                                    label="Role"
                                    // name="Role"
                                    name={[`Role-${index}`]}
                                    style={{
                                      marginLeft: "10px",
                                      marginBottom: "0",
                                    }}
                                    required={true}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Please input Role",
                                      },
                                    ]}
                                    initialValue={roleObj.role || ""}
                                  >
                                    <Input
                                      placeholder="Role"
                                      className="custom-placeholder"
                                      onChange={(e) =>
                                        handleChangeRoute(
                                          index,
                                          "role",
                                          e.target.value
                                        )
                                      }
                                      style={{
                                        borderColor: errors[`role-${index}`]
                                          ? "red"
                                          : "",
                                        flex: 1,
                                      }}
                                      required={true}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please input Role",
                                        },
                                      ]}
                                    />
                                  </Form.Item>

                                  <Form.Item
                                    label="Advance"
                                    style={{
                                      marginLeft: "10px",
                                      marginBottom: "0",
                                    }}
                                  >
                                    <Switch
                                      checked={checkAdvance}
                                      // checked={roleObj.period || false}
                                      onChange={(checked) =>
                                        handleChangeRoute(
                                          index,
                                          "advance",
                                          checked
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row
                            gutter={16}
                            style={{ marginBottom: "20px", marginLeft: "0px" }}
                          >
                            <Col span={6} style={{ marginBottom: "16px" }}>
                              {displayPeriod}

                              {/* <div className="form-item-wrapper">
                <label>
                  <span>
                    Period&nbsp;{" "}
                    <span style={{ color: "red" }}>*</span>
                    <Tooltip title="This is a required field">
                      <InfoCircleOutlined
                        style={{ color: "#0063A7", marginLeft: 8 }}
                      />
                    </Tooltip>
                  </span>
                </label>
                <div className="input-group">
                  <Input
                    placeholder="0"
                    value={
                      roleObj.period
                        ? roleObj.period.replace(/[a-zA-Z]/g, "")
                        : ""
                    }
                    onChange={(e) =>
                      handleChangeRoute(
                        index,
                        "period",
                        e.target.value
                      )
                    }
                    style={{
                      width: "calc(100% - 120px)",
                      borderColor: errors[`period-${index}`]
                        ? "red"
                        : "",
                    }} // Adjusted width for better alignment
                  />
                  <Select
                    value={roleObj.periodUnit || "s"}
                    onChange={(value) =>
                      handleChangeRoute(index, "periodUnit", value)
                    }
                    style={{ width: 110 }}
                  >
                    <Option value="s">S (Second)</Option>
                    <Option value="m">M (Minute)</Option>
                    <Option value="h">H (Hour)</Option>
                  </Select>
                  {errors[`period-${index}`] && (
                    <div style={{ color: "red", marginTop: "5px" }}>
                      {errors[`period-${index}`]}
                    </div>
                  )}
                </div>
              </div> */}
                            </Col>
                            <Col span={8} style={{ marginBottom: "16px" }}>
                              {displayPeriodTimespan}
                              {/* <div className="form-item-wrapper">
                <label>
                  <span>
                    Period Timespan&nbsp;{" "}
                    <span style={{ color: "red" }}>*</span>
                    <Tooltip title="This is a required field">
                      <InfoCircleOutlined
                        style={{ color: "#0063A7", marginLeft: 8 }}
                      />
                    </Tooltip>
                  </span>
                </label>
                <Input
                  addonAfter={
                    <div style={{ width: 70, textAlign: "center" }}>
                      S
                    </div>
                  }
                  placeholder="0"
                  value={roleObj.periodTimespan || ""}
                  onChange={(e) =>
                    handleChangeRoute(
                      index,
                      "periodTimespan",
                      e.target.value
                    )
                  }
                  style={{
                    borderColor: errors[`periodTimespan-${index}`]
                      ? "red"
                      : "",
                  }}
                />
              </div> */}
                            </Col>
                            <Col span={5} style={{ marginBottom: "16px" }}>
                              {displayPeriodLimit}
                              {/* <div className="form-item-wrapper">
                <label>
                  <span>
                    Limit&nbsp;{" "}
                    <span style={{ color: "red" }}>*</span>
                    <Tooltip title="This is a required field">
                      <InfoCircleOutlined
                        style={{ color: "#0063A7", marginLeft: 8 }}
                      />
                    </Tooltip>
                  </span>
                </label>
                <Input
                  placeholder="0"
                  value={roleObj.limit || ""}
                  onChange={(e) =>
                    handleChangeRoute(index, "limit", e.target.value)
                  }
                  style={{
                    borderColor: errors[`limit-${index}`]
                      ? "red"
                      : "",
                  }}
                />
              </div> */}
                            </Col>
                          </Row>

                          {/* เพิ่ม <br /> หากไม่ใช่รายการสุดท้าย */}
                          {index < updatedRecord.RoleValues.length - 1 && (
                            <br />
                          )}
                        </div>
                      </div>
                    );
                  })}
              </div>
            ) : (
              ""
            )}

            <br />
            <div style={{ textAlign: "right" }}>
              <Button onClick={handleCancel} style={{ marginRight: "8px" }}>
                Cancel
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                id="submitBt"
                onClick={onFinish2}
              >
                Submit
              </Button>
            </div>
          </Form>
        </Modal>

        <Flex gap="small">
          <SuccessDialog
            isModalOpen={isModalSuccessOpen}
            closable={false}
            title="Success"
            detail="Successfully saved data."
            onCancel={() => {
              setIsModalSuccessOpen(false);
            }}
            onAfterClose={() => {}}
          />

          <ConfirmDialog
            isModalOpen={isModalOpen}
            closable={false}
            title="Confirm Save"
            cancelText="Cancel"
            confirmText="Confirm"
            onCancel={() => {
              setIsModalOpen(false);
            }}
            onConfirm={() => {
              handleSubmit(formData, formDataRole);
            }}
            description="Do you want to Save data ?"
          />

          <DeleteDialog
            isModalOpen={isModalDeleteOpen}
            closable={false}
            title="Confirm Delete"
            cancelText="Cancel"
            confirmText="Delete"
            onCancel={() => {
              setIsModalDeleteOpen(false);
            }}
            onConfirm={() => {
              sessionStorage.setItem("isChanged", true);
              if (currentRecord) {
                handleDelete(currentRecord, formDataRolelimit, formDataRole);
              }
              setIsModalDeleteOpen(false); // Close the modal after confirming
            }}
            description="Are you sure to delete?"
          />

          <FailedDialog
            isModalOpen={isModalFailedOpen}
            closable={false}
            title="Failed"
            detail="Something went wrong. Please try again."
            onCancel={() => {
              setIsModalFailedOpen(false);
            }}
            onAfterClose={() => {
              // alert("Dialog dissmiss");
            }}
            onConfirm={() => {
              setIsModalFailedOpen(false);
            }}
          />
        </Flex>
      </div>
      <FooterOcelot
        // disabled={!isChanged}
        onSave={() => {
          setIsModalOpen(true);
        }}
        onCancel={() => {
          // handleCancel();
        }}
      />
    </>
  );
};

export default RoutesOcelot;
