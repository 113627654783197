import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NoData from "../../../assets/img/NoData.png";
import reset from "../../../assets/img/reset.png";
import { Link } from "react-router-dom";
import "./User.css";
import { UserService } from "../../../services/UserService";

import {
    Table,
    Button,
    Form,
    Input,
    Flex,
    Row,
    Col,
    Select,
    message,
    Switch,
    Tooltip,
    ConfigProvider,
    notification,
    Tag,
    Modal,
    Pagination,
    Space
  } from "antd";

  import { PlusOutlined, SearchOutlined, SyncOutlined,LeftOutlined,RightOutlined, DeleteOutlined } from "@ant-design/icons";
import IconEdit from "../../../assets/icon/icon_edit";
import { useTranslation } from "react-i18next";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import SuccessDialog from "../../../components/Dialog/SuccessDialog";
import FailedDialog from "../../../components/Dialog/FailedDialog";

const UserList = () => {
    const { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const history = useNavigate();
    const [dataList, setDataList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
    const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [pageValue, setPageValue] = useState({
      page: 1,
      pageSize: 10,
    });
    const [searchValue, setSearchValue] = useState({
      keywords: "",
      enabled: "",
    });
    let currentUser = localStorage.getItem("userId");
    const pagination = {
        //size: "small",
        current: parseInt(pageValue.page),
        itemSize: "small",
        position: ["bottomCenter"],
        total: parseInt(pageValue.total),
        showQuickJumper: false,
        showSizeChanger: true,
      };
  
    const onChangeSwitch = (id, status) => {
        // setIsModalDiscardChangeOpen(true);
        // setConfirmData({ id, status }); 
      };

    const columns = [
      {
        title: "No.",
        width: "5%",
        //render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
        render: (_, __, index) =>
          (pageValue.page - 1) * pageValue.pageSize + index + 1,
      },
      {
        title: "First Name",
        dataIndex: "firstName",
        width: "15%",
        sorter: true,
        // sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        width: "15%",
        sorter: true,
        // sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      },
      // {
      //   title: "Mobile no.",
      //   width: 150,
      //   dataIndex: "tel",
      // },
      {
        title: "Email",
        width: "20%",
        dataIndex: "email",
        // sorter: true,
        // sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        title: "Role",
        width: "10%",
        dataIndex: "roleName",
        // sorter: (a, b) => a.roleName.localeCompare(b.roleName),
        // sorter: true,
        tag: <span style={{ color: "green" }}>Another Tag</span>,
        render: (text, record) => {
          let colorStr = "";
          let roleNameStr = "";
          // if (record.roleName == "Editer") {
          //   colorStr = "warning";
          //   roleNameStr = "Editor";
          // } else if (record.roleName == "Observer") {
          //   colorStr = "default";
          //   roleNameStr = record.roleName;
          // } else if (record.roleName == "Moderator") {
          //   colorStr = "green";
          //   roleNameStr = record.roleName;
          // } else if (record.roleName == "Administrator") {
          //   colorStr = "blue";
          //   roleNameStr = record.roleName;
          // }
          
          return (
            // <Tag color={colorStr}>
            record.roles.map((item, index) => 
              {
                return <Tag >
                  <b>{item.name}</b>
                </Tag>;
                
              })
          );
        },
      },
      // {
      //   title: "Lock",
      //   width: '10%',
      //   dataIndex: "isLocked",
      //   render: (text, record) =>
      //     record.enabled ? (
      //       <Tag color="#ff4d4f" ><b>Lock</b></Tag>
      //     ) : (
      //       <div>-</div>
      //     )
      // },
      {
        title: "Status",
        width: "10%",
        dataIndex: "enabled",
        render: (e, record) => (
          <Tooltip placement="bottom" title={e ? "Active" : "Inactive"}>
            <Switch
              style={{ backgroundColor: e === true ? "#1890FF" : "#d9d9d9" }}
              checked={e}
              onChange={() => {
                onChangeSwitch(record.id, e);
              }}
              disabled={record.id === currentUser}
            />
          </Tooltip>
        ),
      },
      {
        title: "Action",
        width: "10%",
        render: (text, record) => (
          <Space size="middle">
            {/* Reset Password Button */}
            {/* <Tooltip placement="bottom" title="Reset Password">
              <button
                style={{
                  // marginRight: 4,
                  opacity:
                    record.isActivated && record.id !== currentUser ? 1 : 0.5,
                  cursor:
                    record.isActivated && record.id !== currentUser
                      ? "pointer"
                      : "not-allowed",
                  border: "none", // Remove button border
                  background: "none", // Remove button background
                  top: 10,
                  pointerEvents:
                    record.isActivated && record.id !== currentUser
                      ? "auto"
                      : "none",
                }}
                onClick={() => {
                  if (record.isActivated && record.id !== currentUser) {
                    // setIsModalChangePasswordOpen(true);
                    // setConfirmData({ id: record.id });
                  } else {
                    message.error(
                      "User is inactive. Activate the user to reset password."
                    );
                  }
                }}
                disabled={record.id === currentUser || !record.isActivated}
              >
                <img
                  src={reset}
                  alt="Reset Icon"
                  style={{
                    width: "40.5px",
                    height: "auto",
                    marginBottom: "-3px",
                    filter:
                      record.isActivated && record.id !== currentUser
                        ? "none"
                        : "grayscale(100%)",
                  }}
                />
              </button>
            </Tooltip> */}
            <Tooltip placement="bottom" title="View / Edit">
              <IconEdit
                onClick={() => {
                  history({
                    pathname: `/user-detail/${record.id}`,
                  });
                }}
              />
            </Tooltip>
            <Tooltip placement="bottom" title="Delete">
              <Button
                icon={<DeleteOutlined />}
                style={{
                  // border: "1px solid #0063A7",
                  // color: "#0063A7",
                  // marginLeft: 18,
                  // marginBottom: 18,
                  marginTop: -18,
                }}
                onClick={() => {
                  setCurrentItem(record);
                  setIsModalDeleteOpen(true);
                }}
              />
            </Tooltip>
          
          </Space>
        ),
      },
    ];
      const { Option } = Select;
      const [form] = Form.useForm();
      const [messageApi, contextHolder] = message.useMessage();
      const [Keywords, setKeywords] = useState("");
      const [confirmData, setConfirmData] = useState(null); 

      const onSearch = (e) => {
        setSearchValue({
          keywords: e.keywords,
          enabled: e.isActivated,
        });
        setPageValue({ ...pageValue, page: 1 }); 
        getUserList(e, { ...pageValue, page: 1 });
      };
      
      function capitalize(str){
        if(str == undefined){
          return;
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
      }
    
      const handleSortBy = async (pagination, filters, sorter) => {
        let sortBy = capitalize(sorter.field);
        if("createdDateDisplay" === sorter.field){
          sortBy = "CreateDate";
        }
        const pageValues = {
          keywords: Keywords,
          page: pageValue.page,
          pageSize: pageValue.pageSize,
          sortBy: sortBy,
          sortDirection:
            sorter && sorter.order
              ? sorter.order.substring(0, 3).toUpperCase() === "ASC"
                ? "asc"
                : "desc"
              : "asc",
          total: pageValue.total,
        };
        setPageValue(pageValues);
        getUserList(searchValue, pageValues);
        //fetchData(pageValues);
      }
    
      const handlePageChange = async (page, pageSize) => {
    
        console.log("handlePageChange >>> page",page)
        console.log("handlePageChange >>> pageSize",pageSize)
        const pageValues = {
          keywords: Keywords,
          page: page,
          pageSize: pageSize,
          sortBy: pageValue.sortBy,
          sortDirection:pageValue.sortDirection,
          total: pageValue.total,
        };
        setPageValue(pageValues);
        console.log("handlePageChange >>> pageValue",pageValues)
        getUserList(searchValue, pageValues);
        //fetchData(pageValues);
      };
    
      const itemRender = (_, type, originalElement) => {
        const { page, total, pageSize } = pageValue;
        const totalPages = Math.ceil(pageValue?.total / pageSize);
        if (type === "prev") {
          if (page === 1) {
            return (
              <a style={{ pointerEvents: "none" }}>
                <LeftOutlined
                  style={{
                    color: "#d9d9d9",
                  }}
                />
              </a>
            );
          }
          return (
            <a>
              <LeftOutlined
                style={{
                  color: "#FF7F07",
                }}
              />
            </a>
          );
        }
        if (type === "next") {
          if (page === totalPages) {
            return (
              <a style={{ pointerEvents: "none" }}>
                <RightOutlined
                  style={{
                    // fontSize: "",
                    color: "#d9d9d9",
                  }}
                />
              </a>
            );
          }
          return (
            <a>
              <RightOutlined
                style={{
                  // fontSize: "",
                  color: "#FF7F07",
                }}
              />
            </a>
          );
        }
        return originalElement;
      };
    
      const handlePageSizeChange = (current, pageSize) => {
        const pageValues = {
          keywords: Keywords,
          page: 1,
          pageSize: pageSize,
          sortBy: pageValue.sortBy,
          sortDirection:pageValue.sortDirection,
          total: pageValue.total,
        };
        setPageValue(pageValues);
        //fetchData(pageValues);
        getUserList(searchValue, pageValues);
      };

      let testData = [
        {
            id: "06d678fe-60aa-49ac-98a6-3887bffb5dc4",
            firstName: "Test7may",
            lastName: "Test7may",
            fullName: "Test7may Test7may",
            email: "Test7may@yopmail.com",
            tel: "",
            isLocked: false,
            isActivated: true,
            roleId: "3",
            roleName: "Editer"
        },
        {
            id: "0ad466b4-1277-4abe-9404-432bec4a43f8",
            firstName: "JAY",
            lastName: "PARK",
            fullName: "JAY PARK",
            email: "Jpark@yopmail.com",
            tel: "0994456658",
            isLocked: false,
            isActivated: true,
            roleId: "1",
            roleName: "Administrator"
        },
       
    ];

    useEffect(() => {
      getUserList(searchValue, pageValue);
  }, []);

    const getUserList = async (value, page) => {
    try {
      setLoading(true);
      let requestObject = {
        keywords: value.keywords || "", 
        enabled:value.isActivated,
        page: page.page,
        pageSize: page.pageSize,
        sortBy: page.sortBy,
        sortDirection: page.sortDirection,
      };
      let response = await UserService.search(requestObject);
      setDataList(response.items);
      setPageValue({
        page: response.pagination.page,
        pageSize: response.pagination.pageSize,
        total: response.pagination.totalCount,
      });

      console.log("response: ", response);
    } catch (error) {
      console.error("error", error);
    }finally{
      setLoading(false);
    }
  };

  const onDelete = async () => {

    try {
        // console.log("isNewUser", isNewUser);

        const res = await UserService.delete(currentItem.id);
            if (res) {
              console.log("true")
              setIsModalSuccessOpen(true); 
            } else {
              setIsModalFailedOpen(true);
            }
    } catch (err) {
      setIsModalFailedOpen(true);
    }
};

      return (
        <>
          <div className="main-panel">
            <DeleteDialog
              isModalOpen={isModalDeleteOpen}
              closable={false}
              title="Confirm Delete"
              cancelText="Cancel"
              confirmText="Delete"
              onCancel={() => {
                setIsModalDeleteOpen(false);
              }}
              onConfirm={() => {
                // getUserList(searchValue, pageValue);
                onDelete();
              }}
              description="Are you sure to delete ?"
            />

            <SuccessDialog
              isModalOpen={isModalSuccessOpen}
              closable={false}
              title="Successfully"
              detail="Success save data"
              onCancel={() => {
                setIsModalSuccessOpen(false);
              }}
              onAfterClose={() => {
                // alert("Dialog dissmiss");
                getUserList(searchValue, pageValue);
              }}
            />

            <FailedDialog
              isModalOpen={isModalFailedOpen}
              closable={false}
              title="Failed"
              detail="Something went wrong. Please try again."
              onCancel={() => {
                setIsModalFailedOpen(false);
              }}
              onAfterClose={() => {
                // alert("Dialog dissmiss");
              }}
              onConfirm={() => {
                setIsModalFailedOpen(false);
                onDelete();
              }}
            />

            <label style={{ fontSize: 24, fontWeight: 700 }}>
              {t("user-management")}
            </label>

            <div className="body-panel">
              <Form form={form} onFinish={onSearch} layout="vertical">
                <div className="flex">
                  <div className="flex-item" style={{ marginRight: 16 }}>
                    <Form.Item label="Search" name="keywords" colon={false}>
                      <Input
                        className="input-custom rounded"
                        placeholder="Search Name, Surname, Mobile No., Email..."
                        allowClear
                      />
                    </Form.Item>
                  </div>
                  <div className="flex-item">
                    <Form.Item label="Status" name="isActivated" colon={false}>
                      <Select
                        defaultValue="All"
                        allowClear
                        placeholder="Status"
                        showSearch
                        maxTagCount="responsive"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        className="my-select-container"
                        style={{ width: "100%" }}
                      >
                        <Option key={""} value={""}>
                          All
                        </Option>
                        <Option key={true} value={true}>
                          Active
                        </Option>
                        <Option key={false} value={false}>
                          Inactive
                        </Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div style={{ marginLeft: 16, marginTop: 30 }}>
                    <Button
                      shape="default"
                      className=""
                      icon={<SyncOutlined />}
                      onClick={() => {
                        form.resetFields();
                        const searchValues = { keywords: "", isActivated: "" };
                        const pageValues = {
                          page: 1,
                          pageSize: 10,
                          sortBy: "serviceDate",
                          sortDirection: "desc",
                        };
                        getUserList(searchValues, pageValues);
                      }}
                    >
                      Clear
                    </Button>
                  </div>
                  <div style={{ marginLeft: 16, marginTop: 30 }}>
                    <Button
                      className=""
                      shape="default"
                      htmlType="submit"
                      type="primary"
                      icon={<SearchOutlined />}
                    >
                      Search
                    </Button>
                  </div>
                </div>
              </Form>
            </div>
            <div className="title-panel">
              <div>
                <Row style={{ marginBottom: 24, marginTop: 8 }}>
                  <Col span={20} style={{ marginRight: 0 }}>
                    <label style={{ fontSize: 20, fontWeight: 700 }}>
                      User list
                    </label>
                  </Col>
                  <Col span={4} style={{ marginRight: 0 }}>
                    <Flex justify="flex-end" style={{ marginRight: 0 }}>
                      {/* <Button
                        onClick={() => {
                          history({
                            pathname: `/user-detail`,
                          });
                        }}
                        type="primary"
                        shape="default"
                        htmlType="submit"
                        icon={<PlusOutlined />}
                      >
                        Add User
                      </Button> */}
                    </Flex>
                  </Col>
                </Row>
              </div>

              {/* <div>
                <Table
                  style={{
                    margin: "8px 0",
                  }}
                  columns={columns}
                  rowKey={(record) => record.id}
                  dataSource={testData}
                  //pagination={pagination}
                  pagination={false}
                  loading={loading}
                  onChange={(pagination, filters, sorter) =>
                    handleSortBy(
                      pagination,
                      filters,
                      sorter,
                      pagination.current
                    )
                  }
                  scroll={{
                    x: 1024,
                  }}
                />
                <Row
                  justify="space-between"
                  align="middle"
                  style={{ marginTop: 16 }}
                >
                  <Col>
                    <span
                      style={{
                        color: "#667085",
                      }}
                    >
                      <b>Total : {pagination?.total} items</b>
                    </span>
                  </Col>
                  <Col>
                    <Pagination
                      style={{ pointerEvents: loading ? "none" : "auto" }}
                      current={pageValue?.page}
                      pageSize={pageValue?.pageSize}
                      total={pagination?.total}
                      onChange={handlePageChange}
                      itemRender={itemRender}
                    />
                  </Col>
                  <Col>
                    <Select
                      size="middle"
                      value={pageValue.pageSize}
                      onChange={(pageSize) =>
                        handlePageSizeChange(pagination?.current, pageSize)
                      }
                      style={{
                        width: "130px",
                      }}
                    >
                      <Option value={10}>10 / page</Option>
                      <Option value={25}>25 / page</Option>
                      <Option value={50}>50 / page</Option>
                      <Option value={100}>100 / page</Option>
                    </Select>
                  </Col>
                </Row>
              </div> */}


              
              
                <div>
                  <Table
                    style={{
                      margin: "8px 0",
                    }}
                    columns={columns}
                    rowKey={(record) => record.id}
                    dataSource={dataList}
                    //pagination={pagination}
                    pagination={false}
                    loading={loading}
                    onChange={(pagination, filters, sorter) =>
                      handleSortBy(
                        pagination,
                        filters,
                        sorter,
                        pagination.current
                      )
                    }
                    scroll={{
                      x: 1024,
                    }}
                  />
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginTop: 16 }}
                  >
                    <Col>
                      <span
                        style={{
                          color: "#667085",
                        }}
                      >
                        <b>Total : {pagination?.total} items</b>
                      </span>
                    </Col>
                    <Col>
                      <Pagination
                        style={{ pointerEvents: loading ? "none" : "auto" }}
                        current={pageValue?.page}
                        pageSize={pageValue?.pageSize}
                        total={pagination?.total}
                        onChange={handlePageChange}
                        itemRender={itemRender}
                      />
                    </Col>
                    <Col>
                      <Select
                        size="middle"
                        value={pageValue.pageSize}
                        onChange={(pageSize) =>
                          handlePageSizeChange(pagination?.current, pageSize)
                        }
                        style={{
                          width: "130px",
                        }}
                      >
                        <Option value={10}>10 / page</Option>
                        <Option value={25}>25 / page</Option>
                        <Option value={50}>50 / page</Option>
                        <Option value={100}>100 / page</Option>
                      </Select>
                    </Col>
                  </Row>
                </div>
              
              
            </div>
          </div>
        </>
      );

}

export default UserList;