import Icon from "@ant-design/icons";

const icon = () => (
  
<svg width="78" height="78" viewBox="0 0 78 78" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="78" height="78" rx="20" fill="#FFE2E2"/>
<g clip-path="url(#clip0_7084_13934)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.6742 22.6742C23.4064 21.9419 24.5936 21.9419 25.3258 22.6742L39 36.3483L52.6742 22.6742C53.4064 21.9419 54.5936 21.9419 55.3258 22.6742C56.0581 23.4064 56.0581 24.5936 55.3258 25.3258L41.6517 39L55.3258 52.6742C56.0581 53.4064 56.0581 54.5936 55.3258 55.3258C54.5936 56.0581 53.4064 56.0581 52.6742 55.3258L39 41.6517L25.3258 55.3258C24.5936 56.0581 23.4064 56.0581 22.6742 55.3258C21.9419 54.5936 21.9419 53.4064 22.6742 52.6742L36.3483 39L22.6742 25.3258C21.9419 24.5936 21.9419 23.4064 22.6742 22.6742Z" fill="#FF4D4F"/>
</g>
<defs>
<clipPath id="clip0_7084_13934">
<rect width="60" height="60" fill="white" transform="translate(9 9)"/>
</clipPath>
</defs>
</svg>




);

const Icon_Failed_Dialog = (props) => <Icon component={icon} {...props} />;

export default Icon_Failed_Dialog;

