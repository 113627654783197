import React from "react";
import { Button, Modal, Flex } from "antd";
import IconFailedDialog from "../../assets/icon/icon_failed_dialog";
import CustomButton from "../../components/Button/CustomButton";

const FailedDialog = ({
  isModalOpen,
  closable,
  title,
  detail,
  onCancel,
  onAfterClose,
  onConfirm,
}) => {

  return (
    <>
      <Modal
        centered
        title=""
        open={isModalOpen}
        footer={null}
        closable={closable}
        destroyOnClose={true}
        onCancel={() => {
          onCancel();
        }}
        afterClose={(e) => {
          onAfterClose();
        }}
      >
        <Flex justify="center" align="center" vertical>
          <IconFailedDialog
            style={{
              marginTop: 12,
            }}
          />
          <span
            style={{
              fontWeight: 600,
              fontSize: 22,
              marginTop: 12,
            }}
          >
            {title}
          </span>
          <span
            style={{
              fontWeight: 500,
              fontSize: 16,
              marginTop: 8,
            }}
          >
            {detail}
          </span>
          {/* <Button
            type="primary"
            onClick={() => {
              onCancel();
              onConfirm();
              // navigate("/survey-form-list");
            }}
            style={{
              width:150,
              height:28,
              marginTop: 20,
              // marginRight: 10,
              backgroundColor: "#FF4D4F",
              // color: "#ffffff",
              // border: "2px solid #FF7F07",
            }}
            // type="primary"
          >
            Try Again
          </Button> */}
        </Flex>
      </Modal>
    </>
  );
};

export default FailedDialog;
