import React, { useEffect, useState } from "react";
import { Tabs, Space, DatePicker } from "antd";
import "./Dashboard.css";
import CustomSearch from "../../../components/Search/CustomSearch";
import CustomButton from "../../../components/Button/CustomButton";
import CustomCard from "../../../components/Card/CustomCard";

const { TabPane } = Tabs;

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("1");

  const onChange = (key) => {
    setActiveTab(key);
  };

  return (
    <>
      <div className="dashboard-body">
        <div className="dashboard-container">
          <div className="tab-container">
            <Tabs
              defaultActiveKey="1"
              onChange={onChange}
              tabBarStyle={{ display: "flex", justifyContent: "space-around" }}
            >
              <TabPane tab="Dashboard" key="1" />
              <TabPane tab="Insight Report" key="2" />
            </Tabs>
          </div>
          {/* <div className="content"> */}
          {activeTab === "1" && (
            <div className="dashboard-content">
              <p
                style={{
                  fontSize: 30,
                  fontWeight: 700,
                  color: "#5d5a88",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                Dashboard
              </p>
              <Space>
                <CustomSearch
                  placeholder="Search for..."
                  onSearch={() => console.log("Search Dashboard")}
                  width={569}
                  height={46}
                />
                <CustomButton
                  text="Type 1"
                  type="primary"
                  width="115px"
                  height="46px"
                  borderRadius="46px"
                  color="#FFFF"
                  background="#5D5A88"
                />
                <CustomButton
                  text="Type 2"
                  type="primary"
                  width="115px"
                  height="46px"
                  borderRadius="46px"
                  color="#FFFF"
                  background="#5D5A88"
                />
                <CustomButton
                  text="Type 3"
                  type="primary"
                  width="115px"
                  height="46px"
                  borderRadius="46px"
                  color="#FFFF"
                  background="#5D5A88"
                />
                <CustomButton
                  text="Type 4"
                  type="primary"
                  width="115px"
                  height="46px"
                  borderRadius="46px"
                  color="#FFFF"
                  background="#5D5A88"
                />
              </Space>
              <p
                style={{
                  fontSize: 30,
                  fontWeight: 700,
                  color: "#5d5a88",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                Type 1
              </p>
              <Space>
                <CustomCard
                  title="Dashboard 1"
                  description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
                  onClickButton={() => console.log("Dashboard")}
                />
                <CustomCard
                  title="Dashboard 2"
                  description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
                  onClickButton={() => console.log("Dashboard")}
                />
                <CustomCard
                  title="Dashboard 3"
                  description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
                  onClickButton={() => console.log("Dashboard")}
                />
              </Space>
              <p
                style={{
                  fontSize: 30,
                  fontWeight: 700,
                  color: "#5d5a88",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                Type 2
              </p>
              <Space>
                <CustomCard
                  title="Dashboard 1"
                  description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
                  onClickButton={() => console.log("Dashboard")}
                />
                <CustomCard
                  title="Dashboard 2"
                  description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
                  onClickButton={() => console.log("Dashboard")}
                />
                <CustomCard
                  title="Dashboard 3"
                  description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
                  onClickButton={() => console.log("Dashboard")}
                />
              </Space>
            </div>
          )}
          {activeTab === "2" && (
            <div className="insight-content">
              <p
                style={{
                  fontSize: 30,
                  fontWeight: 700,
                  color: "#5d5a88",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                Insight Report
              </p>
              <Space>
                <CustomSearch
                  placeholder="Search for..."
                  onSearch={() => console.log("Search Dashboard")}
                  width={569}
                  height={46}
                />
                <DatePicker
                  placeholder="Date to"
                  style={{ width: 253, height: 46, borderRadius: 46 }}
                />
                <CustomButton
                  text="Clear data"
                  type="primary"
                  width="161px"
                  height="46px"
                  borderRadius="46px"
                  color="#FFFF"
                  background="#5D5A88"
                />
                <CustomButton
                  text="Search"
                  type="primary"
                  width="161px"
                  height="46px"
                  borderRadius="46px"
                  color="#FFFF"
                  background="#5D5A88"
                />
              </Space>
              <p
                style={{
                  fontSize: 30,
                  fontWeight: 700,
                  color: "#5d5a88",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                Type 1
              </p>
              <Space>
                <CustomCard
                  title="Dashboard 1"
                  description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
                  onClickButton={() => console.log("Dashboard")}
                />
                <CustomCard
                  title="Dashboard 2"
                  description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
                  onClickButton={() => console.log("Dashboard")}
                />
                <CustomCard
                  title="Dashboard 3"
                  description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
                  onClickButton={() => console.log("Dashboard")}
                />
              </Space>
            </div>
          )}
          {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
