import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NoData from "../../../assets/img/NoData.png";
import {
  Table,
  Dropdown,
  Space,
  Button,
  Form,
  Input,
  Flex,
  Drawer,
  Row,
  Col,
  Select,
  message,
  InputNumber,
  Modal,
  Tooltip,
  ConfigProvider,
  Pagination,
  Tag
} from "antd";
import { DeleteOutlined, LeftOutlined,RightOutlined,InfoCircleOutlined, PlusOutlined, EditOutlined, MoreOutlined } from "@ant-design/icons";
import IconEdit from "../../../assets/icon/icon_edit.js";
import { MockService } from "../../../services/MockService.js";
import { useTranslation } from "react-i18next";
import "./Permission.css";
import { RoleService } from "../../../services/RoleService.js";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog.js";
import SuccessDialog from "../../../components/Dialog/SuccessDialog.js";
import FailedDialog from "../../../components/Dialog/FailedDialog.js";
import DeleteDialog from "../../../components/Dialog/DeleteDialog.js";

const PermissionList = () => {
  const { i18n, t } = useTranslation();
  const history = useNavigate();
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const { Option } = Select;
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [disabledSave, setDisabledSave] = useState(true);
  const [currentItem, setCurrentItem] = useState(null);


  const [pageValue, setPageValue] = useState({
    page: 1,
    pageSize: 10,
    // total: 40,
  });
    const pagination = {
      //size: "small",
      current: parseInt(pageValue.page),
      itemSize: "small",
      position: ["bottomCenter"],
      total: parseInt(pageValue.total),
      showQuickJumper: false,
      showSizeChanger: true,
    };

  const pageSize = 10;
  let currentPage = 1;

  const testData = [
    {
      id: 1,
      name: "Editer",
      permissions: [
        { pageName: "Survey Form", isActivated:true },
        { pageName: "Survey Form Transaction" , isActivated:true},
        { pageName: "IVR Transaction" , isActivated:true},
      ],
    },

    {
      id: 2,
      name: "Moderator",
      permissions: [
        { pageName: "Survey Form Transaction" , isActivated:true},
        { pageName: "IVR Transaction" , isActivated:true},
        { pageName: "All Report" , isActivated:true},
      ],
    },
    {
      id: 3,
      name: "Observer",
      permissions: [
        { pageName: "Survey Form", isActivated:true },
        { pageName: "Survey Form Transaction", isActivated:true },
        { pageName: "IVR Transaction", isActivated:true },
        { pageName: "All Report", isActivated:true },
      ],
    },
    {
      id: 4,
      name: "Administrator",
      permissions: [
        {pageName: "User Management ", isActivated:true },
        { pageName: "Permission", isActivated:true },
        { pageName: "Survey Form", isActivated:true },
        { pageName: "Survey Form Transaction", isActivated:true },
        { pageName: "IVR Transaction", isActivated:true },
        { pageName: "All Report", isActivated:true },
      ],

    },
  ];

  const columns = [
    {
      title: "No.",
      render: (text, record, index) => (currentPage - 1) * pageSize + index + 1,
    },
    {
      title: "Role",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Permission",
      dataIndex: "userPermissions",
      render: (text, record) =>
        record.userPermissions.filter(i => i.isActivated).map((item) => (
          // <Row style={{ margin: 0 }}>
          //   <label>• {name.resourceName}</label>
          // </Row>
          <Tag >
          <b>{item.resourceName}</b>
        </Tag>
        )),
    },
    {
      title: "Permission Menu",
      dataIndex: "userMenuPermissions",
      render: (text, record) =>
        record.userMenuPermissions.filter(i => i.isActivated).map((item) => (
          // <Row style={{ margin: 0 }}>
          //   <label>• {name.resourceName}</label>
          // </Row>
          <Tag >
          <b>{item.menuName}</b>
        </Tag>
        )),
    },
    {
      title: "Action",
      dataIndex: "",
      render: (text, record) => (
        <span style={{ whiteSpace: "nowrap" }}>
          <Space size="middle">
            <Button
              icon={<EditOutlined />}
              style={{
                border: "1px solid #0063A7",
                color: "#0063A7",
              }}
              onClick={() => {
                history({
                  pathname: `/permission-detail/${record.id}/menu`,
                });
              }}
            />
            <Button
              icon={<MoreOutlined />}
              style={{
                border: "1px solid #0063A7",
                color: "#0063A7",
              }}
              onClick={() => {
                history({
                  pathname: `/permission-detail/${record.id}/page`,
                });
              }}
            />
            {/* <Button
              icon={<DeleteOutlined />}
              style={{
                border: "1px solid #0063A7",
                color: "#0063A7",
              }}
              onClick={() => {
                setCurrentItem(record);
                setIsModalDeleteOpen(true);
              }}
            /> */}
          </Space>
          {/* <Tooltip placement="bottom" title="View / Edit">
            <IconEdit
              onClick={() => {
                history({
                  pathname: `/role-detail/${record.id}`,

                });
              }}
            />
          </Tooltip> */}
        </span>
      ),
    },
  ];

  const handlePageChange = async (page, pageSize) => {
    
    console.log("handlePageChange >>> page",page)
    console.log("handlePageChange >>> pageSize",pageSize)
    const pageValues = {
      page: page,
      pageSize: pageSize,
      sortBy: pageValue.sortBy,
      sortDirection:pageValue.sortDirection,
      total: pageValue.total,
    };
    setPageValue(pageValues);
    console.log("handlePageChange >>> pageValue",pageValues)
    getRoleList(pageValues);
    //fetchData(pageValues);
  };

  function capitalize(str){
    if(str == undefined){
      return;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleSortBy = async (pagination, filters, sorter) => {
    let sortBy = capitalize(sorter.field);
    if("createdDateDisplay" === sorter.field){
      sortBy = "CreateDate";
    }
    const pageValues = {
      page: pageValue.page,
      pageSize: pageValue.pageSize,
      sortBy: sortBy,
      sortDirection:
        sorter && sorter.order
          ? sorter.order.substring(0, 3).toUpperCase() === "ASC"
            ? "asc"
            : "desc"
          : "asc",
      total: pageValue.total,
    };
    setPageValue(pageValues);
    getRoleList(pageValues);
    //fetchData(pageValues);
  }

  const itemRender = (_, type, originalElement) => {
    const { page, total, pageSize } = pageValue;
    const totalPages = Math.ceil(pageValue?.total / pageSize);
    if (type === "prev") {
      if (page === 1) {
        return (
          <a style={{ pointerEvents: "none" }}>
            <LeftOutlined
              style={{
                color: "#d9d9d9",
              }}
            />
          </a>
        );
      }
      return (
        <a>
          <LeftOutlined
            style={{
              color: "#FF7F07",
            }}
          />
        </a>
      );
    }
    if (type === "next") {
      if (page === totalPages) {
        return (
          <a style={{ pointerEvents: "none" }}>
            <RightOutlined
              style={{
                // fontSize: "",
                color: "#d9d9d9",
              }}
            />
          </a>
        );
      }
      return (
        <a>
          <RightOutlined
            style={{
              // fontSize: "",
              color: "#FF7F07",
            }}
          />
        </a>
      );
    }
    return originalElement;
  };

  const handlePageSizeChange = (current, pageSize) => {
    const pageValues = {
      page: 1,
      pageSize: pageSize,
      sortBy: pageValue.sortBy,
      sortDirection:pageValue.sortDirection,
      total: pageValue.total,
    };
    setPageValue(pageValues);
    //fetchData(pageValues);
    getRoleList(pageValues);
  };

  const getRoleList = async (page) => {
    try {
      setLoading(true);
      let requestObject = {
        page: page.page,
        pageSize: page.pageSize,
        sortBy: page.sortBy,
        sortDirection: page.sortDirection,
      };
      let response = await RoleService.searchPermissionWithParam(requestObject);
      setData(response.items);
      setPageValue({
        page: response.pagination.page,
        pageSize: response.pagination.pageSize,
        total: response.pagination.totalCount,
      });

      console.log("response: ", response);
    } catch (error) {
      console.error();
    }finally{
      setLoading(false);
    }
  };

  useEffect(() => {
    getRoleList(pageValue);
  }, []);

  const handleAdd = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleFormChange = () => {
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    setDisabledSave(hasErrors);
  }

  const onFinish = values => {
    // if(){}
    handleSubmit(values);

    handleCancel();
    console.log("Form values:", values); // ค่าของฟอร์มจะถูกพิมพ์ออกมาที่นี่
  };

  const handleSubmit = async (value) => {
    let request = {name: value.name, description: value.description}
      console.log("Request:", request); // ค่าของฟอร์มจะถูกพิมพ์ออกมาที่นี่
  
    try {
      if(currentItem === null){
        const response = await RoleService.create(request);
      }else{
        const response = await RoleService.update(request, currentItem.name);
      }
      
  
      setIsModalSuccessOpen(true);
      // setIsChanged(false);
      setTimeout(() => {
        const pageValues = {
          page: 1,
          pageSize: pageSize,
          sortBy: pageValue.sortBy,
          sortDirection:pageValue.sortDirection,
          total: pageValue.total,
        };
        getRoleList(pageValues);
        setIsModalSuccessOpen(false);
      }, 2000);
    } catch (error) {
      // console.error();
      setIsModalFailedOpen(true);
    }
  };

  return (
    <>
      <div className="main-panel">
      {/* <ConfirmDialog
          isModalOpen={isModalOpen}
          closable={false}
          title="Confirm Save"
          cancelText="Cancel"
          confirmText="Confirm"
          onCancel={() => {
            setIsModalOpen(false);
          }}
          onConfirm={() => {
            // handleSubmit();
          }}
          description="Do you want to Save data ?"
        /> */}

        <SuccessDialog
          isModalOpen={isModalSuccessOpen}
          closable={false}
          title="Success"
          detail="Success save data."
          onCancel={() => {
            setIsModalSuccessOpen(false);
          }}
          onAfterClose={() => {
            // alert();
          }}
        />

        <FailedDialog
          isModalOpen={isModalFailedOpen}
          closable={false}
          title="Failed"
          detail="Something went wrong. Please try again."
          onCancel={() => {
            setIsModalFailedOpen(false);
          }}
          onAfterClose={() => {
            // alert("Dialog dissmiss");
          }}
          onConfirm={() => {
            // handleSubmit();
            setIsModalFailedOpen(false);
          }}
        />
         <DeleteDialog
          isModalOpen={isModalDeleteOpen}
          closable={false}
          title="Confirm Delete"
          cancelText="Cancel"
          confirmText="Delete"
          onCancel={() => {
            setIsModalDeleteOpen(false);
          }}
          onConfirm={() => {
            setData((l) => l.filter((item) => item.id !== currentItem.id));
          }}
          description="Are you sure to delete ?"
        />
      <Modal
          title={
            <span
              style={{ color: "#0063A7", fontSize: "18px", fontWeight: "bold" }}
            >
              {currentItem ? "Edit" : "Add"} Role
            </span>
          }
          open={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          style={{ top: 20 }} // ปรับตำแหน่ง Modal
          bodyStyle={{ padding: "3px" }} // ปรับพื้นที่ภายในของ Modal
          width={1000} // ปรับขนาดความกว้างของ Modal
        >
          <hr />
          <br />
          <Form
            form={form} // เชื่อมต่อกับฟอร์ม
            onFinish={onFinish} // จัดการการส่งฟอร์ม
            // onFinishFailed={onFinishFailed}
            layout="vertical" // การจัดเลย์เอาต์ของฟอร์ม
            onFieldsChange={handleFormChange}
          >

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  className="custom-form-item"
                  label={
                    <span>
                      Name&nbsp; <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="name"
                  rules={[{ required: true, message: "Please input name !" }]}
                  required={false}
                >
                  <Input placeholder="Name" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Description&nbsp;{" "}
                      <span style={{ color: "red" }}>*</span>
                      <Tooltip title="This is a required field">
                        <InfoCircleOutlined
                          style={{ color: "#0063A7", marginLeft: 8 }}
                        />
                      </Tooltip>
                    </span>
                  }
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Please input Description!",
                    },
                  ]}
                  required={false}
                >
                  <Input placeholder="detail of role" />
                </Form.Item>
              </Col>
            </Row>

            {/* <Row gutter={16}>
              <Col span={12}>
                <Form.Item
                  className="custom-form-item"
                  label={
                    <span>
                      Authority&nbsp; <span style={{ color: "red" }}>*</span>
                    </span>
                  }
                  name="authority"
                  rules={[
                    { required: true, message: "Please input Authority !" },
                    {
                      type: "url",
                      warningOnly: false,
                    },
                  ]}
                  required={false}
                >
                  <Input placeholder="http://xxx:0000.com" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span>
                      Audience&nbsp; <span style={{ color: "red" }}>*</span>
                      <Tooltip title="This is a required field">
                        <InfoCircleOutlined
                          style={{ color: "#0063A7", marginLeft: 8 }}
                        />
                      </Tooltip>
                    </span>
                  }
                  name="audience"
                  rules={[
                    { required: true, message: "Please input Audience!" },
                  ]}
                  required={false}
                >
                  <Input placeholder="Audience" />
                </Form.Item>
              </Col>
            </Row> */}

            <div style={{ textAlign: "right" }}>
              <Button onClick={handleCancel} style={{ marginRight: "8px" }}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" disabled={disabledSave}>
                Submit
              </Button>
            </div>
          </Form>
        </Modal>

        <div
          style={{
            marginBottom: 16,
            fontSize: 12,
            color: "#0C0C0C",
          }}
        >
          <label style={{ fontSize: 24, fontWeight: 700 }}>{t("permission-management")}</label>
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            padding: 15,
            marginBottom: 15,
            borderRadius: 10,
          }}
        >
          <ConfigProvider
            theme={{
              token: {
                // colorPrimary: "#ffffff",
              },
              components: {
                Pagination: {
                  // itemActiveBg: "#FF7F07",
                },
                Input: {
                  // activeBorderColor: "#1677ff",
                },
              },
            }}
          >
            <div className="button-group">
              <div className="text-container">
              <label style={{ fontSize: 24, fontWeight: 700 }}>Permission list</label>
              </div>

              {/* <Button
                className="add-button"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setCurrentItem(null);
                  setDisabledSave(true);
                  // form.setFieldsValue({
                  //   key: "",
                  //   metadataAddress: "",
                  //   authority: "",
                  //   audience: "",
                  // });

                  form.resetFields();
                  handleAdd();
                }}
              >
                Add Role
              </Button> */}
            </div>
            {/* <Table
              style={{
                margin: "8px 0",
              }}
              columns={columns}
              dataSource={data}
              pagination={{
                position: ["bottomCenter"],
              }}
            /> */}
           
                <div>
                  <Table
                    style={{
                      margin: "8px 0",
                    }}
                    columns={columns}
                    rowKey={(record) => record.id}
                    dataSource={data}
                    //pagination={pagination}
                    pagination={false}
                    loading={loading}
                    onChange={(pagination, filters, sorter) =>
                      handleSortBy(
                        pagination,
                        filters,
                        sorter,
                        pagination.current
                      )
                    }
                    scroll={{
                      x: 1024,
                    }}
                  />
                  <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginTop: 16 }}
                  >
                    <Col>
                      <span
                        style={{
                          color: "#667085",
                        }}
                      >
                        <b>Total : {pagination?.total} items</b>
                      </span>
                    </Col>
                    <Col>
                      <Pagination
                        style={{ pointerEvents: loading ? "none" : "auto" }}
                        current={pageValue?.page}
                        pageSize={pageValue?.pageSize}
                        total={pagination?.total}
                        onChange={handlePageChange}
                        itemRender={itemRender}
                      />
                    </Col>
                    <Col>
                      <Select
                        size="middle"
                        value={pageValue.pageSize}
                        onChange={(pageSize) =>
                          handlePageSizeChange(pagination?.current, pageSize)
                        }
                        style={{
                          width: "130px",
                        }}
                      >
                        <Option value={10}>10 / page</Option>
                        <Option value={25}>25 / page</Option>
                        <Option value={50}>50 / page</Option>
                        <Option value={100}>100 / page</Option>
                      </Select>
                    </Col>
                  </Row>
                </div>
              
             
          </ConfigProvider>
        </div>
      </div>
    </>
  );
}

export default PermissionList;
