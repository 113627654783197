import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.624512 25C0.624512 11.5381 11.5376 0.625 24.9995 0.625C38.4615 0.625 49.3745 11.5381 49.3745 25C49.3745 38.4619 38.4615 49.375 24.9995 49.375C11.5376 49.375 0.624512 38.4619 0.624512 25ZM24.9995 15.625C26.035 15.625 26.8745 16.4645 26.8745 17.5V26.875C26.8745 27.9105 26.035 28.75 24.9995 28.75C23.964 28.75 23.1245 27.9105 23.1245 26.875V17.5C23.1245 16.4645 23.964 15.625 24.9995 15.625ZM24.9995 36.25C26.035 36.25 26.8745 35.4105 26.8745 34.375C26.8745 33.3395 26.035 32.5 24.9995 32.5C23.964 32.5 23.1245 33.3395 23.1245 34.375C23.1245 35.4105 23.964 36.25 24.9995 36.25Z"
      fill="#FFC044"
    />
  </svg>
);

const Icon_warning_dialog = (props) => <Icon component={icon} {...props} />;

export default Icon_warning_dialog;
