import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { ArrowLeftOutlined, IdcardTwoTone } from "@ant-design/icons";
import {
  Table,
  Dropdown,
  Space,
  Button,
  Form,
  Input,
  Flex,
  Drawer,
  Row,
  Col,
  Select,
  message,
  InputNumber,
  Modal,
  Switch,
  notification,
  Tooltip,
} from "antd";
import { MoreOutlined, PlusOutlined } from "@ant-design/icons";
import { RoleService } from "../../../services/RoleService.js";
import SuccessDialog from "../../../components/Dialog/SuccessDialog";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import DeleteDialog from "../../../components/Dialog/DeleteDialog";
import FailedDialog from "../../../components/Dialog/FailedDialog";

const PermissionDetail = () => {
    const navigate = useNavigate();
    const { Option } = Select;
    const history = useLocation();
    const { id,mode } = useParams();
    // let permissionId = history.pathname.replace("/permission-detail/", "");
    const [form] = Form.useForm();
    const [data, setData] = useState();
    const [loading, setLoading] = useState(false);
    const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
    const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
    let columns = [];

      if(mode === "menu"){
        columns = [
          {
            title: "Permission",
            dataIndex: "userMenuPermissions",
            render: (text, record) =>(
              
                // <Row style={{ margin: 0 }}>
                //   <label>• {name.resourceName}</label>
                // </Row>
                <label >
                {record.menuName}
              </label>
              )
          },
          {
            title: "Visibilities",
            dataIndex: "isActivated",
            render: (e, record) => (
              <>
               <Tooltip placement="bottom" title={e ? "Active" : "Inactive"}>
                <Switch
                  style={{ backgroundColor: e === true ? "#1890FF" : "#d9d9d9" }}
                  // checkedChildren="Open"
                  // unCheckedChildren="Close"
                  // checked={e === "0" ? true : false}
                  checked={record.isActivated}
                  onChange={(value) => {
                    handleSwitchChange(value, record.id);
                  }}
                />
                </Tooltip>
              </>
            ),
          },
        ];
      }else{
        columns = [
          {
            title: "Permission",
            dataIndex: "userPermissions",
            render: (text, record) =>
              (
                // <Row style={{ margin: 0 }}>
                //   <label>• {name.resourceName}</label>
                // </Row>
                <label >
                {record.resourceName}
              </label>
              )
          },
          {
            title: "Visibilities",
            dataIndex: "isActivated",
            render: (e, record) => (
              <>
               <Tooltip placement="bottom" title={e ? "Active" : "Inactive"}>
                <Switch
                  style={{ backgroundColor: e === true ? "#1890FF" : "#d9d9d9" }}
                  // checkedChildren="Open"
                  // unCheckedChildren="Close"
                  // checked={e === "0" ? true : false}
                  checked={record.isActivated}
                  onChange={(value) => {
                    handleSwitchChange(value, record.id);
                  }}
                />
                </Tooltip>
              </>
            ),
          },
        ];
      }

      const testData = [
        { pageName: "Survey Form", isActivated:true },
        { pageName: "Survey Form Transaction" , isActivated:true},
        { pageName: "IVR Transaction" , isActivated:true},
      ]

      const handleSwitchChange = (checked, id) => {
        // setData(checked);
        // if(mode === "menu"){
        //   let permission = data.find((element) => element.id === id);
        //   console.log("checked", checked);
        // }else{
        // }

        var elementPos = data.map(function(x) {return x.id; }).indexOf(id);
        let newArr = [...data]; // copying the old datas array
        // a deep copy is not needed as we are overriding the whole object below, and not setting a property of it. this does not mutate the state.
        newArr[elementPos].isActivated = checked; // replace e.target.value with whatever you want to change it to

        setData(newArr);
        console.log("checked", checked);
      }

      const updatePage = async () => {
        try {
          setLoading(true);
          let request = {
            "id": id,
            "userPermissions": data
          };
          let response = await RoleService.updatePage(request, id);
          // setData(response.items);
          if(response === 204){
            setIsModalSuccessOpen(true);
          }else{
            setIsModalFailedOpen(true);
          }
          console.log("response: ", response);
        } catch (error) {
          console.error();
        }finally{
          setLoading(false);
        }
      };


      const updateMenu = async () => {
        try {
          setLoading(true);
          let request = {
            "id": id,
            "userMenuPermissions": data
          };
          let response = await RoleService.updateMenu(request, id);
          if(response === 204){
            setIsModalSuccessOpen(true);
          }else{
            setIsModalFailedOpen(true);
          }
          // setData(response.items);
          console.log("response: ", response);
        } catch (error) {
          console.error();
        }finally{
          setLoading(false);
        }
      };

      const getRoleDetail = async (id) => {
        try {
          setLoading(true);
          let response = await RoleService.getPermissionDetail(id);
          // setData(response.items);
          if(mode === "menu"){
            setData(response.userMenuPermissions)
          }else{
            setData(response.userPermissions)
          }
          console.log("response: ", response);
        } catch (error) {
          console.error();
        }finally{
          setLoading(false);
        }
      };
    
      useEffect(() => {
        getRoleDetail(id);
      }, []);

      return (
        <>
         <SuccessDialog
            isModalOpen={isModalSuccessOpen}
            closable={false}
            title="Success"
            detail="Successfully saved data."
            onCancel={() => {
              setIsModalSuccessOpen(false);
            }}
            onAfterClose={() => {}}
          />

          <ConfirmDialog
            isModalOpen={isModalOpen}
            closable={false}
            title="Confirm Save"
            cancelText="Cancel"
            confirmText="Confirm"
            onCancel={() => {
              setIsModalOpen(false);
            }}
            onConfirm={() => {
              if(mode === "menu"){
                updateMenu();
              }else{
                updatePage();
              }
            }}
            description="Do you want to Save data ?"
          />

          {/* <DeleteDialog
            isModalOpen={isModalDeleteOpen}
            closable={false}
            title="Confirm Delete"
            cancelText="Cancel"
            confirmText="Delete"
            onCancel={() => {
              setIsModalDeleteOpen(false);
            }}
            onConfirm={() => {
              sessionStorage.setItem("isChanged", true);
              if (currentRecord) {
                handleDelete(currentRecord, formDataRolelimit, formDataRole);
              }
              setIsModalDeleteOpen(false); // Close the modal after confirming
            }}
            description="Are you sure to delete?"
          /> */}

          <FailedDialog
            isModalOpen={isModalFailedOpen}
            closable={false}
            title="Failed"
            detail="Something went wrong. Please try again."
            onCancel={() => {
              setIsModalFailedOpen(false);
            }}
            onAfterClose={() => {
              // alert("Dialog dissmiss");
            }}
            onConfirm={() => {
              setIsModalFailedOpen(false);
            }}
          />

          <div
            className="main-layout"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Link to="/permission-list" style={{ textDecoration: "none" }}>
              <label
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  marginBottom: "20px",
                  display: "block",
                  color: "#000000",
                }}
                onMouseEnter={(e) => (e.target.style.color = "#000000")}
                onMouseLeave={(e) => (e.target.style.color = "#000000")}
              >
                <ArrowLeftOutlined style={{ marginRight: "8px" }} /> Permission Detail
              </label>
            </Link>
            <Flex justify="flex-end" style={{ marginTop: 0 }}>
              <Button
                // className="mt-2 mr-3 flex items-center px-12 border-0"
                //loading={loadingExcel}
                // icon={<FileExcelOutlined />}
                style={{ marginRight:4}}
                size="large"
                className="mr-4"
                type="default"
                shape="default"
                onClick={() => {
                  navigate("/permission-list");
                }}
              >
                Cancel
              </Button>

              <Button
                size="large"
                type="primary"
                shape="default"
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                Save
              </Button>
            </Flex>
          </div>
          <div className="content">

            <div className="content-item">
              <Row>
                <Col sm={14} md={14} lg={14}>
                  <div
                    style={{
                      marginBottom: 16,
                      fontSize: 12,
                      color: "#0C0C0C",
                    }}
                  >
                    <label style={{ fontSize: 20, fontWeight: 600 }}>
                      {/* Permission - {permissionDetail && permissionDetail.name} */}
                    </label>
                  </div>
                  <div>
                    {/* {testV1.map((d) => (
                <div >
                  {d.task}000000{d.id}
                 
                </div>
              ))} */}
                  </div>
                </Col>
              </Row>
            </div>

            <Form form={form} layout="vertical">
              <hr />
              <div className="contact ml-3">
                <Row align="middle" gutter={24}>
                  <Table
                    style={{
                      margin: "8px 0",
                    }}
                    columns={columns}
                    //rowKey={(record) => record.id}
                    dataSource={data}
                    pagination={false}
                    //loading={loading}
                    // onChange={handleTableChange}
                    scroll={{
                      x: 500,
                    }}
                  />
                </Row>
              </div>
            </Form>
          </div>
        </>
      );
}

export default PermissionDetail;