import React from "react";
import { Layout } from "antd";
import "./Footer.css";
import packageJson from "../../../package.json"

const { Footer } = Layout;

const CustomFooter = ({}) => {
  return (
    <>
      <Footer className="footer-container">Footer {packageJson.version}</Footer>
    </>
  );
};

export default CustomFooter;
