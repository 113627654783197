import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const RoleService = {
    get: async function (id, cancel = false) {
        const response = await api.request({
          url: `/Keycloak-users/${id}`,
          method: "GET",
          // retrieving the signal value by using the property name
          signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        });
    
        return response.data;
    },

    search: async function (cancel = false) {
      
        const response = await api.request({
          url: `/Keycloak-realmroles`+  "?Pagination.PageSize=100000" ,
          method: "GET",
          // params: params,
          signal: cancel ? cancelApiObject[this.search.name].handleRequestCancellation().signal : undefined,
        });
    
        return response.data;
    },

    searchWithParam: async function (requestObject, cancel = false) {
      let params = 
          "?Pagination.Page=" +
          requestObject.page +
          "&Pagination.PageSize=" +
          requestObject.pageSize +
          "&Pagination.SortBy=" +
          requestObject.sortBy +
          "&Pagination.SortDestination=" +
          requestObject.sortDirection
      const response = await api.request({
        url: `/Keycloak-realmroles`+
        params,
        method: "GET",
        // params: params,
        signal: cancel ? cancelApiObject[this.searchWithParam.name].handleRequestCancellation().signal : undefined,
      });
  
      return response.data;
  },

    create: async function (data, cancel = false) {
      const response = await api.request({
          url: `/Keycloak-realmroles`,
          method: "POST",
          data: data,
          signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
        return response.data;
    },

    update: async function (data, name, cancel = false) {
      const response = await api.request({
          url: `/Keycloak-realmroles/${name}`,
          method: "PUT",
          data, data,
          signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
        });
        return response.data;
    },

    delete: async function (id, cancel = false) {
      const response = await api.request({
          url: `/Keycloak-users/${id}`,
          method: "DELETE",
          signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
        });
        return response.data;
    },
    assignRole: async function (params, id, cancel = false) {
      const response = await api.request({
          url: `/Keycloak-realmroles-mapper/${id}`,
          method: "POST",
          data: params,
          signal: cancel ? cancelApiObject[this.assignRole.name].handleRequestCancellation().signal : undefined,
        });
        return response.data;
    },
    searchPermissionWithParam: async function (requestObject, cancel = false) {
      let params = 
          "?Pagination.Page=" +
          requestObject.page +
          "&Pagination.PageSize=" +
          requestObject.pageSize +
          "&Pagination.SortBy=" +
          requestObject.sortBy +
          "&Pagination.SortDestination=" +
          requestObject.sortDirection
      const response = await api.request({
        url: `/user-roles`+
        params,
        method: "GET",
        // params: params,
        signal: cancel ? cancelApiObject[this.searchWithParam.name].handleRequestCancellation().signal : undefined,
      });
  
      return response.data;
  },
  getPermissionDetail: async function (id, cancel = false) {
    const response = await api.request({
      url: `/user-roles/${id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    });

    return response.data;
},
updatePage: async function (data, id, cancel = false) {
  const response = await api.request({
      url: `/user-roles/${id}`,
      method: "PUT",
      data, data,
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    });
    return response.status;
},
updateMenu: async function (data, id, cancel = false) {
  const response = await api.request({
      url: `/user-roles/${id}/menu-permission`,
      method: "PUT",
      data, data,
      signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
    });
    return response.status;
},
}

const cancelApiObject = defineCancelApiObject(RoleService);