import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./helpers/PrivateRoute";
import Callback from "./pages/OAuth/Callback";
import NotFound from "./pages/NotFound/NotFound";

// Main
import Layout from "./pages/Main/Layout/Layout";
import Home from "./pages/Main/Home/Home";
import About from "./pages/Main/About/About";
import Dashboard from "./pages/Main/Dashboard/Dashboard";
import Contact from "./pages/Main/Contact/Contact";
import MyProfile from "./pages/Main/MyProfile/MyProfile";
import RoleList from "./pages/Main/Role/RoleList";
import RoleDetail from "./pages/Main/Role/RoleDetail";
import UserList from "./pages/Main/User/UserList";
import UserDetail from "./pages/Main/User/UserDetail";
import PermissionList from "./pages/Main/Permission/PermissionList";

// Ocelot-Config
import GlobalConfigure from "./pages/OcelotConfigure/GlobalConfigure/GlobalConfigure";
import AuthenticationProvidersList from "./pages/OcelotConfigure/AuthenticationProviderOcelot/AuthenticationProvidersList";
import RoutesOcelot from "./pages/OcelotConfigure/RoutesOcelot/RoutesOcelot";
import SwaggerDoc from "./pages/OcelotConfigure/SwaggerDoc/SwaggerDoc";

import Template from "./pages/Template/Template";
import PermissionDetail from "./pages/Main/Permission/PermissionDetail";

function App() {
  sessionStorage.removeItem("isChanged");
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/oauth/callback" element={<Callback />} />
        <Route path="*" element={<NotFound />} />

        <Route path="/template" element={<Template />} />

        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route
            path="dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="my-profile"
            element={
              <PrivateRoute>
                <MyProfile />
              </PrivateRoute>
            }
          />
          <Route
            path="role-list"
            element={
              <PrivateRoute allowedRoles={["portal_admin"]}>
                <RoleList />
              </PrivateRoute>
            }
          />
          <Route
            path="role-detail/:id"
            element={
              <PrivateRoute allowedRoles={["portal_admin"]}>
                <RoleDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="permission-list"
            element={
              <PrivateRoute allowedRoles={["portal_admin"]}>
                <PermissionList />
              </PrivateRoute>
            }
          />
            <Route
            path="permission-detail/:id/:mode"
            element={
              <PrivateRoute allowedRoles={["portal_admin"]}>
                <PermissionDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="user-list"
            element={
              <PrivateRoute allowedRoles={["portal_admin"]}>
                <UserList />
              </PrivateRoute>
            }
          />
          <Route
            path="user-detail"
            element={
              <PrivateRoute allowedRoles={["portal_admin"]}>
                <UserDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="user-detail/:id"
            element={
              <PrivateRoute allowedRoles={["portal_admin"]}>
                <UserDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="api-management/global-configuration"
            element={
              <PrivateRoute allowedRoles={["portal_admin"]}>
                <GlobalConfigure />
              </PrivateRoute>
            }
          />
          <Route
            path="api-management/authentication-providers"
            element={
              <PrivateRoute allowedRoles={["portal_admin"]}>
                <AuthenticationProvidersList />
              </PrivateRoute>
            }
          />
          <Route
            path="api-management/routes"
            element={
              <PrivateRoute allowedRoles={["portal_admin"]}>
                <RoutesOcelot />
              </PrivateRoute>
            }
          />
          <Route
            path="api-management/swagger-docs"
            element={
              <PrivateRoute allowedRoles={["portal_admin"]}>
                <SwaggerDoc />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
