import Icon from "@ant-design/icons";

const icon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 2.75C0 1.50736 1.00736 0.5 2.25 0.5H7.75C8.99264 0.5 10 1.50736 10 2.75V4.75C10 5.16421 9.66421 5.5 9.25 5.5C8.83579 5.5 8.5 5.16421 8.5 4.75V2.75C8.5 2.33579 8.16421 2 7.75 2H2.25C1.83579 2 1.5 2.33579 1.5 2.75V14.25C1.5 14.6642 1.83579 15 2.25 15H7.75C8.16421 15 8.5 14.6642 8.5 14.25V12.25C8.5 11.8358 8.83579 11.5 9.25 11.5C9.66421 11.5 10 11.8358 10 12.25V14.25C10 15.4926 8.99264 16.5 7.75 16.5H2.25C1.00736 16.5 0 15.4926 0 14.25V2.75Z"
      fill="#475467"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 8.5C3 8.08579 3.33579 7.75 3.75 7.75H13.2955L12.2483 6.80747C11.9404 6.53038 11.9154 6.05616 12.1925 5.74828C12.4696 5.44039 12.9438 5.41543 13.2517 5.69253L15.7517 7.94253C15.9098 8.08476 16 8.28738 16 8.5C16 8.71261 15.9098 8.91524 15.7517 9.05747L13.2517 11.3075C12.9438 11.5846 12.4696 11.5596 12.1925 11.2517C11.9154 10.9438 11.9404 10.4696 12.2483 10.1925L13.2955 9.25H3.75C3.33579 9.25 3 8.91421 3 8.5Z"
      fill="#475467"
    />
  </svg>
);

const icon_logout_outlined = (props) => <Icon component={icon} {...props} />;

export default icon_logout_outlined;
