import React from "react";
import { Button, Modal, Flex } from "antd";
import IconSuccessDialog from "../../assets/icon/icon_success_dialog";

const SuccessDialog = ({
  isModalOpen,
  closable,
  title,
  detail,
  onCancel,
  onAfterClose,
}) => {

  return (
    <>
      <Modal
        title=""
        open={isModalOpen}
        footer={null}
        closable={closable}
        destroyOnClose={true}
        onCancel={onCancel}
        afterClose={(e) => {
          onAfterClose();
        }}
        style={{ top: 20 }}
      >
        <Flex vertical={false}>
          <IconSuccessDialog style={{
                marginRight: 10,
              }}/>
          <Flex justify="center"  vertical>
            <span
              style={{
                fontWeight: 600,
                fontSize: 22,
                marginTop: 12,
              }}
            >
              {title}
            </span>
            <span
              style={{
                fontWeight: 500,
                fontSize: 16,
                marginTop: 8,
              }}
            >
              {detail}
            </span>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default SuccessDialog;
