import React from "react";
import { Button } from "antd";
import "./CustomButton.css";

const CustomButton = ({
  text,
  type,
  onClick,
  danger,
  ghost,
  icon,
  iconPosition,
  htmlType,
  loading,
  disabled = false,
  shape,
  width,
  height,
  borderRadius,
  border,
  margin,
  padding,
  background,
  color,
  fontSize,
  fontWeight,
}) => {
  return (
    <>
      <Button
        className="custom-button"
        type={type}
        onClick={onClick}
        danger={danger}
        ghost={ghost}
        icon={icon}
        iconPosition={iconPosition}
        loading={loading}
        htmlType={htmlType}
        disabled={disabled}
        shape={shape}
        style={{
          width,
          height,
          borderRadius,
          border,
          margin,
          padding,
          background,
          color,
          fontSize,
          fontWeight,
        }}
      >
        {text}
      </Button>
    </>
  );
};

export default CustomButton;
