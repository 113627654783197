import { api } from "./configs/axiosConfigs"
import { defineCancelApiObject } from "./configs/axiosUtils"

export const KeycloakRealmRolesService = {
    get: async function (name, cancel = false) {
        const response = await api.request({
          url: `/keycloak-realmroles/${name}`,
          method: "GET",
          // retrieving the signal value by using the property name
          signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
        });
    
        return response.data;
    },

    search: async function (params, cancel = false) {
        const response = await api.request({
          url: `/keycloak-realmroles`,
          method: "GET",
          params: params,
          signal: cancel ? cancelApiObject[this.search.name].handleRequestCancellation().signal : undefined,
        });
    
        return response.data;
    },

    create: async function (data, cancel = false) {
        await api.request({
          url: `/keycloak-realmroles`,
          method: "POST",
          data: data,
          signal: cancel ? cancelApiObject[this.create.name].handleRequestCancellation().signal : undefined,
        });
    },

    delete: async function (name, cancel = false) {
        await api.request({
          url: `/keycloak-realmroles/${name}`,
          method: "DELETE",
          signal: cancel ? cancelApiObject[this.delete.name].handleRequestCancellation().signal : undefined,
        });
    },

    update: async function (oldName, data, cancel = false) {
        await api.request({
          url: `/keycloak-realmroles/${oldName}`,
          method: "PUT",
          data, data,
          signal: cancel ? cancelApiObject[this.update.name].handleRequestCancellation().signal : undefined,
        });
    },
}

const cancelApiObject = defineCancelApiObject(KeycloakRealmRolesService);