import React, { useState, useEffect } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Flex, Input, Form, Space } from "antd";
import "./Template.css";
import { useTranslation } from "react-i18next";

// import Service
import { KeycloakRealmRolesService } from "../../services/KeycloakRealmRolesService";

// import components
import CustomButton from "../../components/Button/CustomButton";
import EditAction from "../../components/Button/EditAction";
import DeleteAction from "../../components/Button/DeleteAction";
import FormItem from "../../components/FormItem/FormItem";
import CustomSearch from "../../components/Search/CustomSearch";
import CustomSelect from "../../components/Select/CustomSelect";
import CustomCard from "../../components/Card/CustomCard";
import CustomIFrame from "../../components/IFrame/CustomIFrame";
import CustomTable from "../../components/Table/CustomTable";
import ConfirmDialog from "../../components/Dialog/ConfirmDialog";
import SuccessDialog from "../../components/Dialog/SuccessDialog";
import FailedDialog from "../../components/Dialog/FailedDialog";
import DeleteDialog from "../../components/Dialog/DeleteDialog";
import DiscardDialog from "../../components/Dialog/DiscardDialog";
import WarningDialog from "../../components/Dialog/WarningDialog";

const { TextArea } = Input;

const Template = () => {
  const { i18n, t } = useTranslation();
  // Dialog
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
  const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
  const [isModalDeleteOpen, setIsModalDeleteOpen] = useState(false);
  const [isModalDiscardOpen, setIsModalDiscardOpen] = useState(false);
  const [isModalWarningOpen, setIsModalWarningOpen] = useState(false);

  // Selcet
  const [selectedValue, setSelectedValue] = useState(null);

  const handleSelectChange = (value) => {
    setSelectedValue(value);
  };

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];

  // Search
  const [keywords, setKeywords] = useState("");

  const handleSearch = (value) => {
    setKeywords(value);
  };

  // Table
  const getParams = (params) => ({
    keywords: keywords,
    "Pagination.Page": params.pagination?.current,
    "Pagination.PageSize": params.pagination?.pageSize,
  });

  const fetchData = async (params) => {
    let response = await KeycloakRealmRolesService.search(params);
    return response;
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
    },
    {
      title: "Action",
      render: (_, record) => (
        <>
          <CustomButton
            text="action"
            onClick={() => {
              alert(JSON.stringify(record));
            }}
          />
        </>
      ),
    },
  ];

  // Form
  const [form] = Form.useForm();
  const [formData, setFormData] = useState({
    name: "kiw",
    lastname: "phak",
    message: "",
    phone: "",
    select: "",
    checked: false,
    textarea: "",
  });

  const onFinish = async (values) => {
    console.log("Form values:", values);
  };

  const validator = async (_, value) => {
    if (value && value.length < 5) {
      return Promise.reject("The value should be at least 5 characters long.");
    }
    return Promise.resolve();
  };

  return (
    <>
      <div className="template-container">
        <h2>Template</h2>

        <Flex gap="small" align="flex-start" vertical>
          <h3>Button</h3>
          <Flex gap="small">
            <CustomButton text="Primary" type="primary" />
            <CustomButton text="Default" />
            <CustomButton text="Ghost" type="primary" ghost={true} />
            <CustomButton
              type="primary"
              icon={<SearchOutlined />}
              shape="circle"
            />
            <CustomButton text="Danger" danger={true} />
            <CustomButton text="Danger" type="primary" danger={true} />
            <CustomButton text="text" type="text" />
            <CustomButton text="dashed" type="dashed" />
            <CustomButton text="link" type="link" />
          </Flex>
          <Flex gap="small">
            <CustomButton
              text="Click Me"
              type="primary"
              onClick={() => alert("Button clicked!")}
              width={130}
              height={40}
              borderRadius={30}
            />
            <CustomButton
              text="Search"
              type="primary"
              icon={<SearchOutlined />}
              color="#000"
              padding="0px 30px"
            />
            <CustomButton
              text="Search"
              type="primary"
              icon={<SearchOutlined />}
              iconPosition="end"
              padding="0px 30px"
            />
            <CustomButton text="background" color="#fff" background="#999900" />
            <CustomButton text="Loading" type="primary" loading={true} />
          </Flex>
          <Flex gap="small">
            <CustomButton text="fontSize" fontSize="18px" />
            <CustomButton text="fontWeight" fontSize="18px" fontWeight="bold" />
            <CustomButton
              text="custom"
              color="#fff"
              background="linear-gradient(135deg, #6253e1, #04befe)"
            />
          </Flex>

          <h3>Button Action</h3>
          <Flex gap="small">
            <EditAction onClick={() => alert("Edit Action")} />
            <DeleteAction onClick={() => setIsModalDeleteOpen(true)} />
          </Flex>

          <h3>Input (Form Item)</h3>
          <Form
            hideRequiredMark
            layout="vertical"
            form={form}
            initialValues={formData}
            onFinish={onFinish}
          >
            <FormItem
              name="name"
              label="Name"
              required={true}
              requiredMessage="please Input Name."
              tooltipMessage="This is a required field"
            >
              <Input
                type="text"
                placeholder="Input name."
                style={{ width: "200px" }}
              />
            </FormItem>
            <FormItem name="lastname" label="Last Name">
              <Input
                type="text"
                placeholder="Input last name."
                style={{ width: "300px", borderRadius: "20px" }}
              />
            </FormItem>
            <FormItem
              name="message"
              label="Message"
              tooltipMessage="Message > 5 characters"
              validator={validator}
            >
              <Input placeholder="Input Message." />
            </FormItem>
            <FormItem
              name="phone"
              label="Phone Number"
              required={true}
              requiredMessage="please Input Phone Number."
              pattern={/^\d{10}$/}
              patternMessage="Phone number must be exactly 10 digits long."
            >
              <Input placeholder="Input Phone Number." allowClear />
            </FormItem>
            <FormItem
              name="select"
              label="Option"
              required={true}
              requiredMessage="please select option."
              tooltipMessage="select option."
            >
              <CustomSelect
                placeholder="select option"
                options={options}
                value={selectedValue}
                onChange={handleSelectChange}
              />
            </FormItem>
            <FormItem
              name="textarea"
              label="Text Area"
              tooltipMessage="Text Area"
            >
              <TextArea rows={4} placeholder="Text" maxLength={6} />
            </FormItem>
            <CustomButton
              text="Submit"
              type="primary"
              htmlType="submit"
              // onClick={() => form.submit()}
            />
          </Form>

          <h3>Select</h3>
          <Flex gap="small">
            <CustomSelect
              options={options}
              value={selectedValue}
              onChange={handleSelectChange}
              width={300}
              height={40}
              borderRadius={30}
            />
          </Flex>

          <h3>Search</h3>
          <Flex gap="small">
            <CustomSearch
              placeholder="Search for..."
              onSearch={handleSearch}
              keywords={keywords}
              setKeywords={setKeywords}
              width={500}
              height={40}
            />
          </Flex>

          <h3>Table</h3>
          <Flex gap="small">
            <CustomTable
              columns={columns}
              fetchData={fetchData}
              getParams={getParams}
              keywords={keywords}
            />
          </Flex>

          <h3>Card</h3>
          <Flex gap="small">
            <CustomCard
              title="Dashboard 1"
              description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
              onClickButton={() => alert("show dashboard detail!")}
            />
            <CustomCard
              src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
              title="Dashboard 2"
              description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
              onClickButton={() => alert("show dashboard detail!")}
            />
          </Flex>

          <h3>IFrame</h3>
          <Flex gap="small">
            <CustomIFrame
              src=""
              title="Dashboard 1"
              description="Lorem ipsum dolor sit amet consectet adipiscing eli consectetur aliquet id enim neque turpis urna hen."
            />
          </Flex>

          <h3>Confirm Dialog</h3>
          <Flex gap="small">
            <CustomButton
              text="Show Dialog"
              type="primary"
              onClick={() => setIsModalOpen(true)}
              width={130}
              height={40}
            />
            <ConfirmDialog
              isModalOpen={isModalOpen}
              closable={false}
              title="Confirm Save"
              cancelText="Cancel"
              confirmText="Confirm"
              onCancel={() => {
                setIsModalOpen(false);
              }}
              onConfirm={() => {
                alert("OK");
              }}
              description="Do you want to Save data ?"
            />
          </Flex>

          <h3>Warning Dialog</h3>
          <Flex gap="small">
            <CustomButton
              text="Show Dialog"
              type="primary"
              onClick={() => setIsModalWarningOpen(true)}
              width={130}
              height={40}
            />
            <WarningDialog
              isModalOpen={isModalWarningOpen}
              closable={false}
              title="Are you sure?"
              cancelText="Cancel"
              confirmText="Ok"
              onCancel={() => {
                setIsModalWarningOpen(false);
              }}
              onConfirm={() => {
                alert("OK");
              }}
              description="Warning"
            />
          </Flex>

          <h3>Success Dialog</h3>
          <Flex gap="small">
            <CustomButton
              text="Show Dialog"
              type="primary"
              onClick={() => setIsModalSuccessOpen(true)}
              width={130}
              height={40}
            />
            <SuccessDialog
              isModalOpen={isModalSuccessOpen}
              closable={false}
              title="Title"
              detail="Success save data"
              onCancel={() => {
                setIsModalSuccessOpen(false);
              }}
              onAfterClose={() => {
                alert("Dialog dissmiss");
              }}
            />
          </Flex>

          <h3>Failed Dialog</h3>
          <Flex gap="small">
            <CustomButton
              text="Show Dialog"
              type="primary"
              onClick={() => setIsModalFailedOpen(true)}
              width={130}
              height={40}
            />
            <FailedDialog
              isModalOpen={isModalFailedOpen}
              closable={false}
              title="Failed"
              detail="Something went wrong. Please try again."
              onCancel={() => {
                setIsModalFailedOpen(false);
              }}
              onAfterClose={() => {
                alert("Dialog dissmiss");
              }}
              onConfirm={() => {
                alert("Try again");
              }}
            />
          </Flex>

          <h3>Delete Dialog</h3>
          <Flex gap="small">
            <CustomButton
              text="Show Dialog"
              type="primary"
              onClick={() => setIsModalDeleteOpen(true)}
              width={130}
              height={40}
            />
            <DeleteDialog
              isModalOpen={isModalDeleteOpen}
              closable={false}
              title="Confirm Delete"
              cancelText="Cancel"
              confirmText="Delete"
              onCancel={() => {
                setIsModalDeleteOpen(false);
              }}
              onConfirm={() => {
                alert("OK");
              }}
              description="Are you sure to delete ?"
            />
          </Flex>

          <h3>Discard Dialog</h3>
          <Flex gap="small">
            <CustomButton
              text="Show Dialog"
              type="primary"
              onClick={() => setIsModalDiscardOpen(true)}
              width={130}
              height={40}
            />
            <DiscardDialog
              isModalOpen={isModalDiscardOpen}
              closable={false}
              title="Discard changes"
              cancelText="Cancel"
              confirmText="Confirm"
              onCancel={() => {
                setIsModalDiscardOpen(false);
              }}
              onConfirm={() => {
                alert("OK");
              }}
              description="Changes will not be saved. Do you want to proceed ?"
            />
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default Template;
