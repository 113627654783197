import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Layout, Flex, Space, Dropdown, Badge, Divider, Menu } from "antd";
import {
  DownOutlined,
  AppstoreAddOutlined,
  SolutionOutlined,
  SafetyOutlined,
  ProductOutlined,
  FileDoneOutlined,
} from "@ant-design/icons";
import Icon_user_filled from "../../assets/icon/icon_user_filled";
import Icon_user_outlined from "../../assets/icon/icon_user_outlined";
import Icon_Lock_outlined from "../../assets/icon/icon_Lock_outlined";
import Icon_logout_outlined from "../../assets/icon/icon_logout_outlined";
import TCEB_Logo from "../../assets/img/TCEB_Logo.png";
import "./Nav.css";
import { useTranslation } from "react-i18next";
import { UserService } from "../../services/UserService";
import {
  handleLogin,
  handleLogout,
  handleRegister,
} from "../../keycloak/KeycloakAuth";
import CustomButton from "../Button/CustomButton";

const { Header } = Layout;
const { SubMenu } = Menu;

const Nav = ({}) => {
  const navigate = useNavigate();
  const { i18n, t } = useTranslation();
  const [userMe, setUserMe] = useState();

  useEffect(() => {
    if (!userMe && !!sessionStorage.getItem("accessToken")) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    try {
      let response = await UserService.getMe();
      setUserMe(response);
      sessionStorage.setItem("userMe", JSON.stringify(response));
    } catch (error) {
      // console.error("error", error);
    }
  };

  const hasPermission = (menuName) => {
    let userData = JSON.parse(sessionStorage.getItem("userMe"));
    return userData?.userMenuPermissions?.some(
      (permission) => permission.menuName === menuName
    );
  };

  return (
    <>
      <Header className="main-header">
        <Flex justify="space-between">
          <div className="main-header-logo">
            <img src={TCEB_Logo} width={50} />
          </div>
          <div>
            <Space size="middle">
              <Link to="/">
                <span>{t("home")}</span>
              </Link>
              <Link
              // to="/about"
              >
                <span>{t("about")}</span>
              </Link>
              {/* <Link to="/contact">
                <span>{t("contact")}</span>
              </Link> */}
              <Link to="/dashboard">
                <span>{t("dashboard")}</span>
              </Link>
              <Link
              // to=""
              >
                <span>{t("api")}</span>
              </Link>
              <Link
              // to=""
              >
                <span>{t("sso")}</span>
              </Link>

              {!sessionStorage.getItem("accessToken") && (
                <>
                  <CustomButton
                    text={t("login")}
                    onClick={handleLogin}
                    width={100}
                    height={32}
                  />
                  <CustomButton
                    text={t("register")}
                    type="primary"
                    onClick={handleRegister}
                    width={100}
                    height={32}
                  />
                </>
              )}

              {!!sessionStorage.getItem("accessToken") && (
                <div className="custom-dropdown">
                  <Dropdown
                    placement="bottomRight"
                    trigger={["click"]}
                    dropdownRender={(menu) => (
                      <Menu
                        className="dropdown-menu"
                        theme="light"
                        mode="vertical"
                        style={{
                          width: 262,
                          minHeight: 100,
                          padding: "23px 12px 23px 12px",
                          marginTop: "10px",
                          marginRight: "-10px",
                        }}
                        triggerSubMenuAction="click"
                      >
                        <p
                          style={{
                            color: "#000",
                            fontSize: "16px",
                            fontWeight: "bold",
                            margin: 0,
                          }}
                        >
                          {userMe?.email}
                        </p>
                        <Divider
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        />
                        {hasPermission("admin_system") && (
                          <SubMenu
                            key="sub1"
                            title={
                              <Space>
                                <Icon_user_filled />
                                {t("admin-system")}
                                <Badge count={6} color="#667085" />
                              </Space>
                            }
                          >
                            {/* <div className="submenu-container"> */}
                            {hasPermission("user_management") && (
                              <Menu.Item key="sub1-1">
                                <a
                                  onClick={() => navigate("/user-list")}
                                  style={{ fontSize: "14px" }}
                                >
                                  <Space>
                                    <Icon_user_filled />
                                    {t("user-management")}
                                  </Space>
                                </a>
                              </Menu.Item>
                            )}

                            {hasPermission("role_management") && (
                              <Menu.Item key="sub1-2">
                                <a
                                  onClick={() => navigate("/role-list")}
                                  style={{ fontSize: "14px" }}
                                >
                                  <Space>
                                    <SolutionOutlined />
                                    {t("role-management")}
                                  </Space>
                                </a>
                              </Menu.Item>
                            )}

                            {hasPermission("permission_management") && (
                              <Menu.Item key="sub1-3">
                                <a
                                  onClick={() => navigate("/permission-list")}
                                  style={{ fontSize: "14px" }}
                                >
                                  <Space>
                                    <SafetyOutlined />
                                    {t("permission-management")}
                                  </Space>
                                </a>
                              </Menu.Item>
                            )}

                            {hasPermission("dashboard_management") && (
                              <Menu.Item key="sub1-4">
                                <a
                                  onClick={() => console.log()}
                                  style={{ fontSize: "14px" }}
                                >
                                  <Space>
                                    <ProductOutlined />
                                    {t("dashboard-management")}
                                  </Space>
                                </a>
                              </Menu.Item>
                            )}

                            {hasPermission("insight_management") && (
                              <Menu.Item key="sub1-5">
                                <a
                                  onClick={() => console.log()}
                                  style={{ fontSize: "14px" }}
                                >
                                  <Space>
                                    <FileDoneOutlined />
                                    {t("insight-management")}
                                  </Space>
                                </a>
                              </Menu.Item>
                            )}

                            {hasPermission("api_management") && (
                              <Menu.Item key="sub1-6">
                                <a
                                  onClick={() =>
                                    navigate(
                                      "/api-management/global-configuration"
                                    )
                                  }
                                  style={{ fontSize: "14px" }}
                                >
                                  <Space>
                                    <AppstoreAddOutlined />
                                    {t("api-management")}
                                  </Space>
                                </a>
                              </Menu.Item>
                            )}
                            {/* </div> */}
                          </SubMenu>
                        )}
                        <Menu.Item key="2">
                          <a
                            // onClick={() => navigate("/my-profile")}
                            style={{ fontSize: "14px" }}
                          >
                            <Space>
                              <Icon_user_outlined />
                              {t("my-profile")}
                            </Space>
                          </a>
                        </Menu.Item>
                        <Menu.Item key="3">
                          <a
                            onClick={() => console.log()}
                            style={{ fontSize: "14px" }}
                          >
                            <Space>
                              <Icon_Lock_outlined />
                              {t("change-password")}
                            </Space>
                          </a>
                        </Menu.Item>
                        <Divider
                          style={{ marginTop: "10px", marginBottom: "10px" }}
                        />
                        <Menu.Item key="4">
                          <a
                            onClick={() => handleLogout()}
                            style={{ fontSize: "14px" }}
                          >
                            <Space>
                              <Icon_logout_outlined />
                              {t("logout")}
                            </Space>
                          </a>
                        </Menu.Item>
                      </Menu>
                    )}
                  >
                    <Space>
                      <Icon_user_filled />
                      <p>{userMe?.firstName}</p>
                      <DownOutlined />
                    </Space>
                  </Dropdown>
                </div>
              )}
            </Space>
          </div>
        </Flex>
      </Header>
    </>
  );
};

export default Nav;
