import React, { useEffect, useState, useCallback, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined, UnlockOutlined , MailOutlined  } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { notification , Modal} from "antd";
// import Swal from "sweetalert2";
import { Button, Form, Input, Row, Col, Select, Switch, ConfigProvider , Tooltip } from "antd";
import { UserService } from "../../../services/UserService";
import { RoleService } from "../../../services/RoleService";
import ConfirmDialog from "../../../components/Dialog/ConfirmDialog";
import SuccessDialog from "../../../components/Dialog/SuccessDialog";
import FailedDialog from "../../../components/Dialog/FailedDialog";


const UserDetail = () => {
    const navigate = useNavigate();
    const { Option } = Select;
    const history = useLocation();
    const { id } = useParams();
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalSuccessOpen, setIsModalSuccessOpen] = useState(false);
    const [isModalFailedOpen, setIsModalFailedOpen] = useState(false);
    const [roleOption, setRoleOption] = useStateCallback();

    let testData = {
      id: "06d678fe-60aa-49ac-98a6-3887bffb5dc4",
      firstName: "Test7may",
      lastName: "Test7may",
      fullName: "Test7may Test7may",
      email: "Test7may@yopmail.com",
      tel: "",
      isLocked: false,
      isActivated: true,
      roleId: "3",
      roleName: "Editer",
      permissions: [
        {
          id: "09763294-438f-44f5-97ce-208467ddc5b7",
          roleId: "3",
          roleName: "Editer",
          pageId: "6",
          pageName: "User Management",
          isActivated: false,
        },
      ],
    };
    // if(id === undefined){
    //   testData = null;
    // }
    const [userDetail, setUserDetail] = useState(null);
    const [defaultRole, setDefaultRole] = useState([]);
    const [statusChange, setStatusChange] = useState(true);
    const [isNewUser, setIsNewUser] = useState(true);
    const [unsaveChange, setUnsavedChanges] = useState(false);
    const [formValues, setFormValues] = useState(null);
    const currentUser = localStorage.getItem("userId");
    const [selectTag, setSelectTag] = useState("");
    const [selectedRole, setSelectedRole] = useState();
    let roleAllOption = [];

    // if(id !== undefined){
    //   form.setFieldsValue({
    //     firstName: userDetail.firstName,
    //     lastName: userDetail.lastName,
    //     email: userDetail.email,
    //     tel: userDetail.tel,
    //     roleId: userDetail.roleId,
    //     isActivated: userDetail.isActivated,
    //   });
    // }

    function useStateCallback(initialState) {
      const [state, setState] = useState(initialState);
      const cbRef = useRef(null); // init mutable ref container for callbacks
    
      const setStateCallback = useCallback((state, cb) => {
        cbRef.current = cb; // store current, passed callback in ref
        setState(state);
      }, []); // keep object reference stable, exactly like `useState`
    
      useEffect(() => {
        // cb.current is `null` on initial render, 
        // so we only invoke callback on state *updates*
        if (cbRef.current) {
          cbRef.current(state);
          cbRef.current = null; // reset callback after execution
        }
      }, [state]);
    
      return [state, setStateCallback];
    }

    useEffect(() => {

      getAllRole();
      // if(id !== undefined){
      //   getUserDetail(id);
      // }
  }, []);

  const getAllRole = async () => {
    try {
      let response = await RoleService.search();
      let option = [];
      response.items.map((item) => {
        option.push({label:item.name, value:item.id});
      })
      roleAllOption = option;
      setRoleOption(option, function(data) {
        if(id !== undefined){
          getUserDetail(id, data);
        }
        
      });
      
      // form.setFieldsValue({
      //   roleId: getRole(userDetail.roles),
      // });

      console.log("response: ", response);
    } catch (error) {
      console.error("error", error);
    }
};

    const getUserDetail = async (id, roleOption) => {
      try {
        let response = await UserService.get(id);
        setUserDetail(response);
        setDefaultRole(getRole(response.roles))
        // setSelectedRole(getRole(response.roles));
        onRoleChange(getRole(response.roles));
        setSelectTag(
          <Form.Item
          name="roleId"
          rules={[
            {
              required: true,
              message: "Please select a role",
            },
          ]}

        initialValue={getRole(response.roles)}
        >
         <Select
        className="mt-2"
        placeholder="Role"
        showSearch
        maxTagCount="responsive"
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        mode="multiple"
        options={roleOption}
        onChange={onRoleChange}
      >
      </Select>
        </Form.Item>
        );
       
        // setDefaultRole(["3cfeb53b-e524-4dfc-bc20-3ccaae3c105a"]);
        form.setFieldsValue({
          firstName: response.firstName,
          lastName: response.lastName,
          email: response.email,
          tel: response.tel,
          // roleId: response.roleId,
          isActivated: response.isActivated,
        });

        console.log("response: ", response);
      } catch (error) {
        console.error("error", error);
      }
  };

  const onFinish = (values) => {
    setFormValues(values);
    setIsModalOpen(true);
  };

  const onSubmit = async () => {
    const values = formValues;
    const temp = {
        ...values,
        id: userDetail !== null ? userDetail.id : undefined,
        firstName: values.firstName || "",
        lastName: values.lastName || "",
        email: values.email || "",
        username: values.email || "",
        enabled: true,
        totp: false,
        emailVerified: false,
        attributes: {
          checkbox: [
            "ยินยอมแชร์ข้อมูล"
        ],
        }
        // roleId: values.roleId || "3",
        // status: statusChange,
    };

    setIsModalOpen(false);

    try {
        // console.log("isNewUser", isNewUser);

        if (id === undefined) {
            const res = await UserService.create(temp);
            if (res) {
              console.log("true")
              setIsModalSuccessOpen(true); 
            } else {
              setIsModalFailedOpen(true);
            }
        } else {
            delete temp.attributes;
            const res = await UserService.update(id, temp);
            if (res) {
              console.log("true")
              setIsModalSuccessOpen(true); 
            } else {
              setIsModalFailedOpen(true);
            }
        }
    } catch (err) {
      setIsModalFailedOpen(true);
    }
};

function getRole (findItem){
  let roleIdList = [];
  findItem.map((item, i) => {
    roleIdList.push(item.id);
  })
  console.log("role list ", roleIdList);
  return roleIdList;
}

const onRoleChange = (selected) => {
  console.log("Selected Role : ",selected);
  let updatedList = selected.map(item => 
      {
        let currentElement = roleAllOption.find((element) => element.value === item);
        if (item == currentElement.value){
          return {id:item, name: currentElement.label}; //gets everything that was already in item, and updates "done"
        }
        return item; // else return unmodified item 
      });
      // setQuestionList(updatedList, function(data){
      //     console.log("Input data ",data);
      // });
      setSelectedRole(updatedList);
};

const updateRole = async () => {
  setIsModalOpen(false);

  try {
      // console.log("isNewUser", isNewUser);
      const res = await RoleService.assignRole(selectedRole, id);
        if (res) {
          console.log("true")
          onSubmit();
          // setIsModalSuccessOpen(true); 
        } else {
          setIsModalFailedOpen(true);
        }
  } catch (err) {
    setIsModalFailedOpen(true);
  }
};


    return (
      <div className="main-panel">
        <ConfirmDialog
          isModalOpen={isModalOpen}
          closable={false}
          title="Confirm Save"
          cancelText="Cancel"
          confirmText="Confirm"
          onCancel={() => {
            setIsModalOpen(false);
          }}
          onConfirm={() => {
            // onSubmit();
            if(id === undefined){
              onSubmit();
            }else{
              updateRole();
            }
          }}
          description="Do you want to Save data ?"
        />

        <SuccessDialog
          isModalOpen={isModalSuccessOpen}
          closable={false}
          title="Successfully"
          detail="Success save data"
          onCancel={() => {
            setIsModalSuccessOpen(false);
          }}
          onAfterClose={() => {
            // alert("Dialog dissmiss");
            navigate(
              {
                pathname: `/user-list`,
              },
              { state: { updateDate: new Date() } }
            );
          }}
        />

        <FailedDialog
          isModalOpen={isModalFailedOpen}
          closable={false}
          title="Failed"
          detail="Something went wrong. Please try again."
          onCancel={() => {
            setIsModalFailedOpen(false);
          }}
          onAfterClose={() => {
            // alert("Dialog dissmiss");
          }}
          onConfirm={() => {
            setIsModalFailedOpen(false);
            onSubmit();
          }}
        />

        {/* onFinish={onFinish} */}
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <div
            className="mt-5"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* <ConfigProvider wave={{ disabled: true }}>
                <Button
                  type="button"
                  class="ant-btn css-var-R15alupimba ant-btn-link"
                  style={{ textDecoration: "none", outline: "none" }}
                  wave={{ disabled: true }}
                  onClick={() => {
                    // DiscardChange();
                  }}
                >
                  <label
                    style={{
                      fontSize: 20,
                      fontWeight: 600,
                      marginBottom: "20px",
                      display: "block",
                      color: "#000000",
                    }}
                    onMouseEnter={(e) => (e.target.style.color = "#000000")}
                    onMouseLeave={(e) => (e.target.style.color = "#000000")}
                  >
                    <ArrowLeftOutlined
                      style={{ marginRight: "8px" }}
                      to={"/user-list"}
                    />{" "}
                    {userDetail.id ? "Edit" : "Add"} user
                  </label>
                </Button>
              </ConfigProvider> */}
            <Link to="/user-list" style={{ textDecoration: "none" }}>
              <label
                style={{
                  fontSize: 20,
                  fontWeight: 600,
                  marginBottom: "20px",
                  display: "block",
                  color: "#000000",
                }}
                onMouseEnter={(e) => (e.target.style.color = "#000000")}
                onMouseLeave={(e) => (e.target.style.color = "#000000")}
              >
                <ArrowLeftOutlined style={{ marginRight: "8px" }} />{" "}
                {userDetail !== null ? "Edit" : "Add"} user
              </label>
            </Link>
            <div style={{ display: "flex", alignItems: "center" }}>
              {/* {userDetail !== null && (
                <Button
                  size="large"
                  type="default"
                  shape="default"
                  icon={<UnlockOutlined />}
                  onClick={() => {
                    //   setIsModalChangePasswordOpen(true);
                  }}
                  style={{ marginRight: 16 }}
                >
                  Reset password
                </Button>
              )} */}
              <Button
                type="default"
                shape="default"
                onClick={() => {
                  // DiscardChange();
                }}
                style={{ marginRight: 16 }}
              >
                Cancel
              </Button>
              <Button type="primary" shape="default" htmlType="submit">
                Save
              </Button>
            </div>
          </div>

          <div className="body-panel">
            <div className="contact">
              <Row align="middle" gutter={24}>
                <Col className="gutter-row" span={8}>
                  <div style={{ marginBottom: 5 }}>
                    <label style={{ fontWeight: 500 }}>
                      Name<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <Form.Item
                    name="firstName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your name",
                      },
                    ]}
                  >
                    <Input
                      disabled={true}
                      placeholder="Name.."
                      id="firstname"
                      allowClear
                      onChange={() => setUnsavedChanges(true)}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={8}>
                  <div style={{ marginBottom: 5 }}>
                    <label style={{ fontWeight: 500 }}>
                      Surname<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <Form.Item
                    name="lastName"
                    rules={[
                      {
                        required: true,
                        message: "Please enter your surname",
                      },
                    ]}
                  >
                    <Input
                      disabled={true}
                      placeholder="Surname.."
                      id="lastname"
                      allowClear
                      onChange={() => setUnsavedChanges(true)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row align="middle" gutter={24}>
                <Col className="gutter-row" span={8}>
                  <div style={{ marginBottom: 5 }}>
                    <label style={{ fontWeight: 500 }}>
                      Email<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please enter a valid email address!",
                      },
                      {
                        required: true,
                        message: "Please enter your email",
                      },
                    ]}
                  >
                    <Input
                      disabled={true}
                      prefix={<MailOutlined />}
                      placeholder="abc@gmail.com"
                      id="email"
                      allowClear
                      // disabled={!isNewUser}
                      onChange={() => setUnsavedChanges(true)}
                    />
                  </Form.Item>
                </Col>
                {id ? <Col className="gutter-row" span={8}>
                  <div style={{ marginBottom: 5 }}>
                    <label style={{ fontWeight: 500 }}>
                      Role<span style={{ color: "red" }}> *</span>
                    </label>
                  </div>

                  {selectTag}
                  {/* <Form.Item
                    name="roleId"
                    rules={[
                      {
                        required: false,
                        message: "Please select a role",
                      },
                    ]}
                  >
                   
                  </Form.Item> */}
                </Col> : ""}
                
              </Row>

              <Row align="middle" gutter={24}>
                <Col className="gutter-row" span={8}>
                  <Form.Item
                    label="Status"
                    name="status"
                    colon={false}
                    style={{ display: isNewUser ? "none" : "block" }}
                  >
                    <Row>
                      <Tooltip
                        placement="bottom"
                        title={statusChange ? "Active" : "Inactive"}
                      >
                        <Switch
                          style={{
                            backgroundColor:
                              statusChange === true ? "#1890FF" : "#d9d9d9",
                            marginBottom: 10,
                          }}
                          checked={statusChange}
                          onChange={(value) => {
                            setStatusChange(value);
                          }}
                          // disabled={!userDetail.id || userDetail.id == currentUser}
                        />
                      </Tooltip>
                    </Row>
                    <Row>
                      <label style={{ color: "#475467" }}>
                        Status “Active”
                      </label>
                    </Row>
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </div>
    );

}



export default UserDetail;