import React, { useState, useEffect } from "react";
import { Table } from "antd";
import "./CustomTable.css";
import CustomPagination from "../Pagination/CustomPagination";

const CustomTable = ({ columns, fetchData, getParams, keywords }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  // const [disableBtn, setDisableBtn] = useState(true);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });

  useEffect(() => {
    fetchDataWrapper();
  }, [JSON.stringify(tableParams)]);

  useEffect(() => {
    fetchDataWrapper();
  }, [keywords]);

  // useEffect(() => {
  //   const check = selectedRowKeys.length === 0;
  //   setDisableBtn(check);
  // }, [selectedRowKeys]);

  // const getParams = (params) => ({
  //   "keywords": keywords,
  //   PageSize: params.pagination?.pageSize,
  //   PageNumber: params.pagination?.current,
  // });

  const fetchDataWrapper = async () => {
    try {
      setLoading(true);
      let params = getParams(tableParams);
      let response = await fetchData(params);
      setData(response.items);
      setLoading(false);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response.pagination?.totalCount,
        },
      });
    } catch (error) {
      setLoading(false);
    }
  };

  // const onSelectChange = (newSelectedRowKeys) => {
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: onSelectChange,
  // };

  const handlePageChange = (page, pageSize) => {
    setTableParams({
      pagination: {
        current: page,
        pageSize,
        total: tableParams.pagination?.total,
      },
    });

    if (pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handlePageSizeChange = (current, pageSize) => {
    setTableParams({
      pagination: {
        current: 1,
        pageSize,
      },
    });
  };

  return (
    <>
      <div className="custom-table">
        <Table
          style={{ margin: "8px 0" }}
          // rowSelection={{
          //   type: "checkbox",
          //   preserveSelectedRowKeys: true,
          //   ...rowSelection,
          // }}
          columns={columns}
          rowKey={(record) => record.id}
          dataSource={data}
          pagination={false}
          onChange={handlePageChange}
          loading={loading}
          // scroll={{ x: 1000 }}
        />

        {data.length !== 0 && (
          <CustomPagination
            current={tableParams.pagination?.current}
            pageSize={tableParams.pagination?.pageSize}
            total={tableParams.pagination?.total}
            loading={loading}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            // pageSizeOptions={[10, 25, 50, 100]}
          />
        )}
      </div>
    </>
  );
};

export default CustomTable;
